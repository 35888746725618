/**
 *  Accordion.tsx
 *  Disability Insurance Services
 *
 *  Created by Nathan Albrecht on Mon May 13 14:40:06 2019
 */
import React from "react";
import { Button } from "reactstrap";
import * as S from "../../constants/StringConstants";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";

import { Icon, Dialog, DialogContent, DialogTitle, DialogContentText } from "@material-ui/core";

import { Form as FinalForm } from "react-final-form";
import "./FormModal.scss";
import { INote } from "../../store/models/shared.models";

export interface IFormModalState {
  open: boolean;
}

export interface IFormModalProps {
  onSubmit: (values: any) => void;
  launcher: React.ReactNode;
  title?: string;
  saveLabel?: string;
  cancelLabel?: string;
  onCancel?: () => void;
  values?: INote;
}

export default class FormModal extends React.Component<IFormModalProps, IFormModalState> {
  private form: HTMLFormElement;

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  static defaultProps = {
    saveLabel: S.FM_DEFAULT_SAVE,
    cancelLabel: S.FM_DEFAULT_CANCEL,
    title: "",
    values: {
      id: 0
    }
  };

  submitForm(values: INote) {
    this.props.onSubmit(values);
    this.handleClose();
  }

  handleCancel() {
    this.handleClose();

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  render() {
    return (
      <div>
        <div onClick={this.handleOpen}>{this.props.launcher}</div>
        <Dialog
          open={this.state.open}
          PaperProps={{
            style: {
              width: "64rem",
              height: "auto",
              padding: "1rem 1rem"
            }
          }}>
          <DialogContentText align="right">
            <Icon style={{ cursor: "pointer" }} onClick={this.handleCancel}>
              clear
            </Icon>
          </DialogContentText>
          <DialogContent>
            <FinalForm
              onSubmit={this.submitForm}
              initialValues={this.props.values}
              render={({ handleSubmit, valid }) => (
                <form onSubmit={handleSubmit}>
                  <div>{this.props.children}</div>
                  <div className="form-modal__button-wrap">
                    <button type="button" onClick={this.handleCancel} className="form-modal__cancel-button">
                      {this.props.cancelLabel}
                    </button>
                    <button type="submit" disabled={!valid} className="form-modal__save-button">
                      {this.props.saveLabel}
                    </button>
                  </div>
                </form>
              )}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
