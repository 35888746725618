import * as React from "react";
import { connect, memoize } from "react-redux";
import { RouteComponentProps, Link } from "react-router-dom";
import { Form as FinalForm, FormRenderProps } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import { Container, Row, Col, Spinner } from "reactstrap";
import { formatUSD } from '../../../../../util/utils.currency';
import { set, get } from "lodash";
import axios from "axios";
import { Prompt } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import Toast from "../../../../../components/Toast/Toast";
import { IRootState } from "../../../../../store/reducers";
import { fetchBrokerDetails } from "../../../../../store/reducers/proposalOptions";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";
import TextField from "../../../../../components/Form/TextField/TextField";
import UploadDocumentModal from "../../../../../components/UploadDocumentModal/UploadDocumentModal";
import NumberField from "../../../../../components/Form/NumberFormatField/NumberFormatField";

import {
  defaultOptionValue,
  addDefaultOption,
  isEmptyOrDefault,
  undefinedIfDefault
} from "../../../../../util/utils.defaultValues";

import proposalCensus, {
  toggleUploadModal,
  uploadDocument,
  getUploadedDocument,
  hideUploadToastError
} from "../../../../../store/reducers/proposalCensus";

import { IProposal } from "../../../../../store/models/proposal.model";
import { saveSymbol, stepSymbol, stepIndexSymbol } from "../Regular/ProposalBuilder";

import { 
  saveProposal, 
  submitProposal
} from "../../../../../store/reducers/proposalOptions";

import * as S from "../../../../../constants/StringConstants";
import "./CensusML.scss";
import { iso8601ToCustomFormat } from '../../../../../util/utils.dates';

export interface ICensusMLProps
  extends RouteComponentProps,
    StateProps,
    DispatchProps {
  proposalID: any;
  proposal: IProposal;
  next: (values: any) => void;
  previous: (values: any) => void;
  generatePDF: (values: any) => void;
  step: (values: any, pageIndex: number) => void;
}

class CensusML extends React.Component<ICensusMLProps> {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    const { proposal } = this.props;

    this.props.getUploadedDocument(`${proposal.proposalID}`);
  }

  handleSubmit = values => {
    const { next, previous, generatePDF, step } = this.props;
    const { formAction, ...rest } = values;

    if (window[saveSymbol]) {
      generatePDF(rest);
      window[saveSymbol] = false;
    } else if (window[stepSymbol]) {
      step(rest, window[stepIndexSymbol]);
      window[stepSymbol] = false;
    } else {
      if (formAction === "next") {
        next(rest);
      } else {
        previous(rest);
      }
    }
  };

  handleValidate = values => {
    const errors = {};
    const {
      proposal,
      proposalCensus
    } = this.props;
    const uploadedCensus = proposalCensus.map[proposal.proposalID];

    const censusDiscountRateId = get(values, "censusDiscountRateId");
    if (!censusDiscountRateId || censusDiscountRateId < 1) {
      set(errors, "censusDiscountRateId", S.FORM_FIELD_REQUIRED);
    }

    const censusBenefitCapId = get(values, "censusBenefitCapId");
    if (!censusBenefitCapId || censusBenefitCapId < 1) {
      set(errors, "censusBenefitCapId", S.FORM_FIELD_REQUIRED);
    }
    if (censusBenefitCapId && censusBenefitCapId === 3) {
      const censusBenefitCapOther = get(values, "censusBenefitCapOther");
      if(!censusBenefitCapOther) {
        set(errors, "censusBenefitCapOther", S.FORM_FIELD_REQUIRED);
      }
    }

    const censusPercentCapId = get(values, "censusPercentCapId");
    if (!censusPercentCapId || censusPercentCapId < 1) {
      set(errors, "censusPercentCapId", S.FORM_FIELD_REQUIRED);
    }
    if (censusPercentCapId && censusPercentCapId === 3) {
      const censusPercentCapOther = get(values, "censusPercentCapOther");
      if(!censusPercentCapOther) {
        set(errors, "censusPercentCapOther", S.FORM_FIELD_REQUIRED);
      }
      else if(censusPercentCapOther < 0 || censusPercentCapOther > 100){
        set(errors, "censusPercentCapOther", S.PERCENT_VALUE_INVALID);
      }
    }

    const carrierGroupTypeId = get(values, "carrierGroupTypeId");
    if (!carrierGroupTypeId || carrierGroupTypeId < 1) {
      set(errors, "carrierGroupTypeId", S.FORM_FIELD_REQUIRED);
    }
    if (carrierGroupTypeId && carrierGroupTypeId === 14) {
      const carrierGroupTypeOther = get(values, "carrierGroupTypeOther");
      if(!carrierGroupTypeOther && carrierGroupTypeOther !== "") {
        set(errors, "carrierGroupTypeOther", S.FORM_FIELD_REQUIRED);
      }
    }

    return errors;
  };

  isNextPrevented(isSavingNext: boolean){
    if(this.props.proposalID === "new")
      return false;

    return isSavingNext;
  }

  uploadFiles = async (acceptedFiles: File[]) => {
    const { proposal } = this.props;

    try {
      for (const acceptedFile of acceptedFiles) {
        try {
          await this.props.uploadDocument(`${proposal.proposalID}`, acceptedFile);
        } catch (e) {
          // TODO: notify/handle?
        }
      }
    } catch (e) {
      // TODO: notify/handle?
    } finally {
      this.props.toggleUploadModal(false);
    }
  };

  render() {
    const {
      proposalID,
      proposal,
      isUploadModalVisible,
      isSubmitting,
      isSavingNext,
      isSavingPrevious,
      isUploadToastErrorVisible,
      proposalCensus
    } = this.props;

    const { selectBenefitCapList, selectDiscountRateList, selectPercentCapList, selectGroupCarierList } = this.props.censusOptions;

    const benefitCapOptions = addDefaultOption("", selectBenefitCapList);
    const discountRateList = addDefaultOption("", selectDiscountRateList);
    const percentCapList = addDefaultOption("", selectPercentCapList);
    const carrierList = addDefaultOption("", selectGroupCarierList);

    return (
      <div>
        <Toast
          message={S.EQ_CENSUS_ERROR_FILE_UPLOAD}
            onClose={() => {
              this.props.hideUploadToastError();
            }}
            open={isUploadToastErrorVisible}
          />
        <UploadDocumentModal
          open={isUploadModalVisible}
          accept=".csv"
          handleFiles={this.uploadFiles}
          onCancel={() => {
            this.props.toggleUploadModal(false);
          }}
        />
        <FinalForm
          onSubmit={this.handleSubmit}
          validate={this.handleValidate}
          initialValues={proposal}
          mutators={{ ...arrayMutators }}>
          {formProps => {
            const formValues = formProps.values;
            const formErrors = formProps.errors;
            const uploadedCensus = proposalCensus.map[proposal.proposalID];

            var censusBenefitOther = false;
            if (formValues.censusBenefitCapId === 3) { // Other value selected
              censusBenefitOther = true;
            }
            
            var censusPercentOther = false;
            if (formValues.censusPercentCapId === 3) { // Other value selected
              censusPercentOther = true;
            }

            var censusGroupCarierOther = false;
            if (formValues.carrierGroupTypeId === 14) { // Other value selected
              censusGroupCarierOther = true;
            }

            return (
              <form
                className="overview__form-wrap"
                onSubmit={formProps.handleSubmit}
                id={"proposal-builder-form-2"}>
                <Prompt when={formProps.dirty && !formProps.submitting} message={S.MOD_DIALOG_LEAVING} />
                <Container>
                  <Row>
                    <Col>
                      <h1 className="heading3">{S.CENSUS_TITLE}</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <button
                        className="heading5 button__transparent button__transparent--upload"
                        onClick={e => {
                          e.preventDefault();
                          this.props.toggleUploadModal(true);
                        }}>
                        {S.EQ_DOC_ADD_DOCUMENT_BUTTON}
                      </button>
                    </Col>
                  </Row> 
                  <Row>
                    <Col>
                        {uploadedCensus 
                        ? ""
                        : <span className="error">{S.FORM_FILE_UPLOAD_REQUIRED}</span>}
                    </Col>
                  </Row>
                  <Row className="space">
                    <Col>
                      &nbsp;
                    </Col>
                  </Row>
                  {uploadedCensus ?
                  <Row>
                    <Col>
                      <table className="census-uploaded-items">
                        <thead>
                          <tr>
                            <th>{S.CENSUS_COLUNM_NAME}</th>
                            <th>{S.CENSUS_COLUNM_DOB}</th>
                            <th>{S.CENSUS_COLUNM_GENDER}</th>
                            <th>{S.CENSUS_COLUNM_TOBACOUSAGE}</th>
                            <th>{S.CENSUS_COLUNM_STATE}</th>
                            <th>{S.CENSUS_COLUNM_OCCUPATION}</th>
                            <th>{S.CENSUS_COLUNM_INCOME}</th>
                            <th>{S.CENSUS_COLUNM_BONUS}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadedCensus.map(c => {
                            return (
                                <tr key={c.id}>
                                  <td>{c.name}</td>
                                  <td>{iso8601ToCustomFormat(c.dob, "MM/DD/YYYY")}</td>
                                  <td>{c.gender}</td>
                                  <td>{c.tobaccoUsage}</td>
                                  <td>{c.state}</td>
                                  <td>{c.occupation}</td>
                                  <td>{formatUSD(c.income, 0)}</td>
                                  <td>{formatUSD(c.bonus, 0)}</td>
                                </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Col> 
                  </Row>
                  : "" }
                  <Row className="space">
                    <Col>
                      &nbsp;
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h1 className="heading3">{S.CENSUS_OPTIONS}</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SingleSelectField
                        key={"census_discount_rate key"}
                        label={S.EQ_DISCOUNT_RATE_LABEL}
                        name={"censusDiscountRateId"}
                        options={discountRateList}
                        isRequired={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h1 className="heading3">{S.EQ_GLTD_PLAN_DETAILS}</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SingleSelectField
                        key={"census_benefit_cap key"}
                        label={S.EQ_BENEFIT_CAP_LABEL}
                        name={"censusBenefitCapId"}
                        options={benefitCapOptions}
                        isRequired={true}
                      />
                    </Col>
                    <Col>
                    {censusBenefitOther 
                      ?
                      <NumberField
                        name={"censusBenefitCapOther"}
                        key={"census_benefit_cap_other key"}
                        label={S.EQ_BENEFIT_CAP_OTHER_LABEL}
                        thousandSeparator={true}
                        decimalScale={0}
                        prefix={"$"}
                        /> 
                      : "" }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SingleSelectField
                        key={"census_percent_cap key"}
                        label={S.EQ_PERCENT_CAP_LABEL}
                        name={"censusPercentCapId"}
                        options={percentCapList}
                        isRequired={true}
                      />
                    </Col>
                    <Col>
                      {censusPercentOther 
                        ?
                        <NumberField
                          name={"censusPercentCapOther"}
                          key={"census_percent_cap_other key"}
                          label={S.EQ_PERCENT_CAP_OTHER_LABEL}
                          thousandSeparator={true}
                          decimalScale={0}
                          suffix={"%"}
                        />
                        : "" }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SingleSelectField
                        key={"census_group_carier key"}
                        label={S.EQ_GROUP_CARIER_LABEL}
                        name={"carrierGroupTypeId"}
                        options={carrierList}
                        isRequired={true}
                      />
                    </Col>
                    <Col>
                      {censusGroupCarierOther 
                        ? <TextField
                            name={"carrierGroupTypeOther"}
                            key={"census_group_carier_other key"}
                            label={S.EQ_GROUP_CARIER_OTHER_LABEL}
                          />
                        : "" }
                    </Col>
                  </Row>
                </Container>
                <div className="buttons buttons__wrap">
                  <button
                    className="button__orange buttons__display buttons__mr-20"
                    type="submit"
                    disabled={isSubmitting || !uploadedCensus}
                    onClick={e => {
                      formProps.form.change("formAction", "previous");
                    }}>
                    {isSavingPrevious || isSavingNext ? <Spinner color="white" /> : S.PC_PREV_BUTTON}
                  </button>
                  <div className="overview__next-button buttons__display">
                    <button
                      className="button__orange"
                      type="submit"
                      disabled={isSubmitting || !uploadedCensus}
                      onClick={e => {
                        formProps.form.change("formAction", "next");
                      }}>
                      {isSavingPrevious || isSavingNext ? <Spinner color="white" /> : S.PC_NEXT_BUTTON}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </FinalForm>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  overviewOptions: state.proposalOptions.overviewOptions,
  proposalSourceOptions: state.proposalOptions.overviewOptions.selectOriginList,
  proposalHearAboutUsOptions: state.proposalOptions.overviewOptions.selectHearAboutUsList,
  isSubmitting: state.proposalOptions.isSubmitting,
  isSavingNext: state.proposalOptions.isSavingNext,
  isClassificationLoaded: state.proposalOptions.isClassificationLoaded,
  isSavingPrevious: state.proposalOptions.isSavingPrevious,
  censusOptions: state.proposalOptions.censusOptions,
  isUploadToastErrorVisible: state.proposalCensus.isUploadToastErrorVisible,

  isUploadModalVisible: state.proposalCensus.isUploadModalVisible,
  proposalMap: state.proposalOptions.proposalMap,
  proposalCensus: state.proposalCensus
});

const mapDispatchToProps = { 
  fetchBrokerDetails, 
  saveProposal, 
  submitProposal,
  toggleUploadModal,
  uploadDocument,
  getUploadedDocument,
  hideUploadToastError,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const Overview = connect(
  mapStateToProps,
  mapDispatchToProps
)(CensusML);

export default Overview;
