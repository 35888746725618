import React from "react";
import { get, set } from 'lodash'
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Button, Icon } from '@material-ui/core'
import { Form as FinalForm, Field, FormSpy } from 'react-final-form'
import TextField from '../../components/Form/TextField/TextField'
import Toast from "../../components/Toast/Toast";
import formatString from 'format-string-by-pattern'

import * as S from "../../constants/StringConstants";
import * as P from '../../util/utils.validators'
import { IRootState } from "../../store/reducers";
import { HealthCheckState, fetchHealth, sendTestSms, reindex } from "../../store/reducers/healthCheck";

import "./HealthCheck.scss";


export interface IHealthCheckProps extends StateProps, DispatchProps, RouteComponentProps {}

export class HealthCheck extends React.Component<IHealthCheckProps> {
  fileTableRef: any;

  async componentDidMount() {
    this.props.fetchHealth();
  }

  validate = (values: any) => {
    const errors = {}

    const phoneNumber = get(values, 'phoneNumber');
    const pattern = P.PHONE_NUMBER_REGEX;
    if (phoneNumber && !pattern.test(phoneNumber)) {
      errors['phoneNumber'] = S.FORM_FIELD_INVALID;
    }

    return errors
  }

  handleSubmit = async (values: any) => {
    const text = get(values, 'text')
    const phoneNumber = get(values, 'phoneNumber')

    await this.props.sendTestSms(text, phoneNumber);
  }

  handleReindex = async (values: any) => {

    await this.props.reindex();
  }

  render() {
    const {
      healthCheck
    } = this.props;

    const initialValues = { text: '', phoneNumber: '' };
    const phoneMask = { name: 'phone-1', parse: '(999) 999-9999' }

    return (
      
      <div className="table-view__content">
        <Toast
          message={S.H_TWILIO_SMS_SEND}
          onClose={() => {
            //hideErrorToastBroker();
          }}
          open={healthCheck.smsSending}
        />
              <div className='broker-builder'>
                <h1 className='heading1 grey--light'>{S.H_TITLE}</h1>
                
                  <div className='broker-builder__form'>
                    <section className='broker-builder__wrap'>
                      <Container className='broker-builder__container'>
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.H_TWILIO_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_TWILIO_ACCOUNT_SID}</span><br/>
                            {healthCheck && healthCheck.twilio ?
                            <span>{healthCheck.twilio.accountSid}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_TWILIO_AUTH_TOKEN}</span><br/>
                            {healthCheck && healthCheck.twilio ?
                            <span>{healthCheck.twilio.authToken}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_TWILIO_FROM_NUMBER}</span><br/>
                            {healthCheck && healthCheck.twilio ?
                            <span>{healthCheck.twilio.fromNumber}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_TWILIO_SEND}</span><br/>
                            {healthCheck && healthCheck.twilio ?
                            <span>
                              {healthCheck.twilio.send
                              ?<Icon className="green">check</Icon>
                              :<Icon className="red">clear</Icon>}
                            </span>
                            :<Icon>cached</Icon>}
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_TWILIO_LASTSMS_PHONE}</span><br/>
                            {healthCheck && healthCheck.twilio ?
                            <span>{healthCheck.twilio.lastSmsPhone}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_TWILIO_LASTSMS_BODY}</span><br/>
                            {healthCheck && healthCheck.twilio ?
                            <span>{healthCheck.twilio.lastSmsBody}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_TWILIO_LASTSMS_DATE}</span><br/>
                            {healthCheck && healthCheck.twilio ?
                            <span>{healthCheck.twilio.lastSmsDate}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_TWILIO_LASTSMS_SEND}</span><br/>
                            {healthCheck && healthCheck.twilio ?
                            <span>
                            {
                              healthCheck.twilio.lastSmsSucessfull
                              ?<Icon className="green">check</Icon>
                              :<Icon className="red">clear</Icon>
                            }</span>
                            :<Icon>cached</Icon>}
                          </Col>
                        </Row>
                      </Container>
                      <Container className='broker-builder__container'>
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.H_EMAIL_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row className="regularRow">
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_EMAIL_FROM_EMAIL}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.fromEmail}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_EMAIL_FROM_NAME}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.fromName}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_EMAIL_SMTP_DOMAIN}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.primaryDomain}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_EMAIL_PORT}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.primaryPort}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                        </Row>
                        <Row className="regularRow">
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_EMAIL_USERNAME}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.usernameEmail}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_EMAIL_USERPASSWORD}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.usernamePassword}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                        </Row>
                        <Row className="regularRow">
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_EMAIL_CLIENTID}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.clientId}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_EMAIL_SECRET}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.secret}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_EMAIL_TENANTID}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.tenantId}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                        </Row>
                        <Row className="regularRow">
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_EMAIL_TOKEN_URL}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.tokenUri}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_EMAIL_OFFICE_SERVER}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>{healthCheck.email.smtpServer}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_EMAIL_SEND}</span><br/>
                            {healthCheck && healthCheck.email ?
                            <span>
                            {
                              healthCheck.email.send
                              ?<Icon className="green">check</Icon>
                              :<Icon className="red">clear</Icon>
                            }
                            </span>
                            :<Icon>cached</Icon>}
                          </Col>
                        </Row>
                      </Container>
                      <Container className='broker-builder__container'>
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.H_INSIGHTLY_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row className="regularRow">
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_INSIGHTLY_LAMBDAACCESSKEYID}</span><br/>
                            {healthCheck && healthCheck.insightly ?
                            <span>{healthCheck.insightly.lambdaAccessKeyID}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_INSIGHTLY_LAMBDASECRETKEY}</span><br/>
                            {healthCheck && healthCheck.insightly ?
                            <span>{healthCheck.insightly.lambdaSecretKey}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_INSIGHTLY_LAMBDAREGIONENDPOINTNAME}</span><br/>
                            {healthCheck && healthCheck.insightly ?
                            <span>{healthCheck.insightly.lambdaRegionEndpointName}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                        </Row>
                        <Row className="regularRow">
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_INSIGHTLY_LAMBDAWRITERNAME}</span><br/>
                            {healthCheck && healthCheck.insightly ?
                            <span>{healthCheck.insightly.lambdaWriterName}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="6">
                            <span className="health_subtitle">{S.H_INSIGHTLY_QUEUEURL}</span><br/>
                            {healthCheck && healthCheck.insightly ?
                            <span>{healthCheck.insightly.queueUrl}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                        </Row>
                      </Container>
                      <Container className='broker-builder__container'>
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.H_AWS_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row className="regularRow">
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_AWS_ACCESSKEYID}</span><br/>
                            {healthCheck && healthCheck.aws ?
                            <span>{healthCheck.aws.accessKeyID}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="2">
                            <span className="health_subtitle">{S.H_AWS_REGIONENDPOINTNAME}</span><br/>
                            {healthCheck && healthCheck.aws ?
                            <span>{healthCheck.aws.regionEndpointName}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="4">
                            <span className="health_subtitle">{S.H_AWS_SECRETKEY}</span><br/>
                            {healthCheck && healthCheck.aws ?
                            <span>{healthCheck.aws.secretKey}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                          <Col sm="3">
                            <span className="health_subtitle">{S.H_AWS_S3BUCKETNAME}</span><br/>
                            {healthCheck && healthCheck.aws ?
                            <span>{healthCheck.aws.s3BucketName}</span>
                            :<Icon>cached</Icon>}
                          </Col>
                        </Row>
                      </Container>
                   
                   <Container className='broker-builder__container'>
                    <FinalForm
                      validate={this.validate}
                      onSubmit={this.handleSubmit}
                      initialValues={initialValues}
                      render={({ handleSubmit, submitting, invalid, values, form }) => {
            
            return (
              <div >
                <form onSubmit={handleSubmit}>
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.H_SMS_TEST_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='3'>
                            <TextField
                              name='text'
                              key='text-key'
                              label={S.H_SMS_TEST_TEXT_LABEL}
                            />
                          </Col>
                          <Col sm='3'>
                            <TextField
                              name='phoneNumber'
                              key='phoneNumber-key'
                              label={S.H_SMS_TEST_PHONE_LABEL}
                              type='tel'
                              parse={formatString(phoneMask.parse)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className='broker-builder__top-bar'>
                              <div className='buttons'>
                                <ul>
                                  <li>
                                    <button
                                      className='button5 button__orange'
                                      type='submit'
                                      disabled={submitting}>
                                      {submitting ? <Spinner color="white" /> : S.H_SMS_SUBMIT_BUTTON}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                        </Row>
                </form>
              </div>
            )
          }}
        />
                    </Container>


        <Container className='broker-builder__container'>
                    <FinalForm
                      validate={this.validate}
                      onSubmit={this.handleReindex}
                      initialValues={initialValues}
                      render={({ handleSubmit, submitting, invalid, values, form }) => {
            
            return (
              <div >
                <form onSubmit={handleSubmit}>
                        <Row>
                          <Col>
                            <h1 className='heading3'>{S.H_REINDEX_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className='broker-builder__top-bar'>
                              <div className='buttons'>
                                <ul>
                                  <li>
                                    <button
                                      className='button5 button__orange'
                                      type='submit'
                                      disabled={submitting}>
                                      {submitting ? <Spinner color="white" /> : S.H_REINDEX_BUTTON}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                        </Row>
                </form>
              </div>
            )
          }}
        />
                    </Container>

                    </section>
                  </div>
              </div>
      </div>
    )
  }
}


const mapStateToProps = ({ healthCheck }: IRootState) => ({
  healthCheck
});

const mapDispatchToProps = { 
  fetchHealth,
  sendTestSms,
  reindex
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthCheck);
