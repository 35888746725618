/**
 *  AsyncTypeAheadField.tsx
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Mon Apr  18 14:46:37 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */
import React from "react";
import { get } from "lodash";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { FormGroup, Label, DropdownItem } from "reactstrap";
import { ITypeaheadOption } from "./TypeaheadField";
import { render } from "react-dom";

import "./TypeaheadField.scss";

export const typeAheadErrorSymbol = Symbol("TYPEAHEAD_ERROR");

interface IAsyncTypeaheadProps {
  label: string;
  fetch: (query: string) => Promise<ITypeaheadOption[]>;
  onChange?: (selected: any) => void;
}

interface IAsyncTypeaheadFieldRenderProps<El extends HTMLSelectElement>
  extends FieldRenderProps<any, El>,
    IAsyncTypeaheadProps {}

export interface IAsyncTypeaheadFieldState {}

export class AsyncTypeaheadFieldComponent<El extends HTMLSelectElement> extends React.Component<
  IAsyncTypeaheadFieldRenderProps<El>,
  IAsyncTypeaheadFieldState
> {
  state = {
    isLoading: false,
    options: [],
    error: null
  };

  handleSearch = async (queryText: string) => {
    this.setState({ isLoading: true });

    try {
      const options = await this.props.fetch(queryText);
      this.setState({ isLoading: false, options });
    } catch (error) {
      this.setState({ isLoading: false, error });
    }
  };

  render() {
    const {
      input,
      input: { onChange },
      meta,
      label,
      fetch,
      ...typeaheadProps
    } = this.props;
    const { isLoading, options } = this.state;
    const { touched } = meta;
    const error = get(meta, ["error", "errorLabel"], meta.error);
    const isError = touched && error;

    return (
      <FormGroup>
        <div className={isError ? "type-ahead-error" : "type-ahead"}>
          <Label>{label}</Label>
          <AsyncTypeahead
            labelKey={"label"}
            id={input.name}
            isLoading={isLoading}
            onSearch={this.handleSearch}
            isInvalid={meta.visited && meta.invalid}
            isValid={meta.visited && meta.valid}
            options={options}
            {...input}
            {...typeaheadProps}
            onChange={event => {
              if (typeaheadProps.onChange) {
                typeaheadProps.onChange(event);
              }
              return onChange(event);
            }}
          />
          {isError && <span>{error}</span>}
        </div>
      </FormGroup>
    );
  }
}

export interface IAsyncTypeaheadFieldProps<El extends HTMLSelectElement>
  extends FieldProps<any, El>,
    IAsyncTypeaheadProps {}

const AsyncTypeaheadField = (props: IAsyncTypeaheadFieldProps<HTMLSelectElement>) => (
  <Field component={AsyncTypeaheadFieldComponent} {...props} />
);

export default AsyncTypeaheadField;
