import * as React from "react";
import { connect, memoize } from "react-redux";
import { RouteComponentProps, Link } from "react-router-dom";
import { Form as FinalForm, FormRenderProps } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import { Container, Row, Col, Spinner } from "reactstrap";
import { set, get } from "lodash";
import axios from "axios";
import { Prompt } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import Toast from "../../../../../components/Toast/Toast";
import AlertDialog from "../../../../../components/Form/Confirmation/AlertDialog";

import { IRootState } from "../../../../../store/reducers";
import { fetchBrokerDetails } from "../../../../../store/reducers/proposalOptions";
import SingleSelectField from "../../../../../components/Form/SingleSelectField/SingleSelectField";
import TextField from "../../../../../components/Form/TextField/TextField";

import AsyncTypeaheadField, {
  typeAheadErrorSymbol
} from "../../../../../components/Form/TypeaheadField/AsyncTypeaheadField";
import { ITypeaheadOption } from "../../../../../components/Form/TypeaheadField/TypeaheadField";
import { IBrokerOverview } from "../../../../../store/models/brokerOverview.model";
import {
  defaultOptionValue,
  addDefaultOption,
  isEmptyOrDefault,
  undefinedIfDefault
} from "../../../../../util/utils.defaultValues";

import { IProposal } from "../../../../../store/models/proposal.model";
import { saveSymbol, stepSymbol, stepIndexSymbol } from "./ProposalBuilder";

import {
  saveProposal,
  submitProposal,
  triggerProductChange,
  hideProductChangeTriggered }
from "../../../../../store/reducers/proposalOptions";

import * as S from "../../../../../constants/StringConstants";
import * as P from "../../../../../util/utils.validators";
import "./Overview.scss";
import Icon from "@material-ui/core/Icon";
import { FormApi } from "final-form";
import PromptModal from '../../../../../components/ConfirmationModal/PromptModal';

export interface IProposalDetailsOverviewProps extends RouteComponentProps, StateProps, DispatchProps {
  proposalID: any;
  proposal: IProposal;
  next: (values: any) => void;
  generatePDF: (values: any) => void;
  step: (values: any, pageIndex: number) => void;
  onBrokerChange: (notes: string) => void;


}

class ProposalDetailsOverview extends React.Component<IProposalDetailsOverviewProps> {
  initialProductTypeId?: number;

  componentDidMount() {
    this.initialProductTypeId = this.props.proposal.productTypeID;
  }
  /**
   * Fetch and parse broker type ahead options.
   */
  searchBrokers = async (searchText: string): Promise<Array<ITypeaheadOption>> => {
    const response = await axios.get("brokers/overviews", { params: { searchText } });
    const options: ITypeaheadOption[] = response.data.map(broker => {
      return {
        label: broker.brokerName + "     " + broker.primaryEmail,
        value: broker
      };
    });
    return options;
  };

  getBrokerItem(broker) {
    if (!broker || !broker.brokerID) {
      return null;
    }
    return {
      label: `${broker.brokerName}${broker.primaryEmail ? "    " + broker.primaryEmail : ""}`,
      value: broker.brokerID
    };
  }

  handleSubmit = async values => {
    const { next, generatePDF, step } = this.props;

    if (window[saveSymbol]) {
      await generatePDF(values);
      window[saveSymbol] = false;
    } else if (window[stepSymbol]) {
      await step(values, window[stepIndexSymbol]);
      window[stepSymbol] = false;
    } else {
      await next(values);
    }
  };

  handleValidate = values => {
    const errors = {};

    if (!values.specialistID) {
      errors["specialistID"] = S.FORM_FIELD_REQUIRED;
    }

    if (isEmptyOrDefault(values.productTypeID)) {
      errors["productTypeID"] = S.FORM_FIELD_REQUIRED;
    }

    const brokerID = get(values, "broker.brokerID");
    if (!brokerID) {
      errors["broker"] = { errorLabel: S.FORM_FIELD_REQUIRED };
    }

    const firstName = get(values, "clients.0.firstName");
    if (P.isEmpty(firstName)) {
      set(errors, "clients.0.firstName", S.FORM_FIELD_REQUIRED);
    }

    const lastName = get(values, "clients.0.lastName");
    if (P.isEmpty(lastName)) {
      set(errors, "clients.0.lastName", S.FORM_FIELD_REQUIRED);
    }

    const originationTypeID = get(values, "originationTypeID");
    if (!originationTypeID || originationTypeID < 1) {
      set(errors, "originationTypeID", S.FORM_FIELD_REQUIRED);
    }

    const hearAboutUsTypeID = get(values, "hearAboutUsTypeID");
    if (!hearAboutUsTypeID || hearAboutUsTypeID < 1) {
      set(errors, "hearAboutUsTypeID", S.FORM_FIELD_REQUIRED);
    }

    return errors;
  };

  isNextPrevented(isSavingNext: boolean, isClassificationLoaded: boolean, ){
    if(this.props.proposalID === "new")
      return false;

    return isSavingNext || !isClassificationLoaded;
  }

  render() {
    const {
      proposalID,
      proposal,
      onBrokerChange,
      proposalSourceOptions,
      proposalHearAboutUsOptions,
      isSubmitting,
      isSavingNext,
      isSavingPrevious,
      isClassificationLoaded,
      proposalMap
    } = this.props;
    const { selectSpecialistList, selectProductList } = this.props.overviewOptions;
    const isProductChangeTriggered = proposalMap[proposalID].isProductChangeTriggered;

    const productOptions = addDefaultOption(S.PDO_PRODUCT_TYPE_LABEL, selectProductList);

    const sourceOptions = addDefaultOption(S.PDO_PROPOSAL_SOURCE_TITLE, proposalSourceOptions);

    const hearAboutUsOptions = addDefaultOption("", proposalHearAboutUsOptions);

    const isNew = proposalID === "new";

    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={proposal}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, invalid, values, form, dirty }) => {
          const selectedBroker = values.broker;
          const selected = [];
          if (selectedBroker && selectedBroker.brokerID) {
            selected.push(this.getBrokerItem(selectedBroker));
          }

          const selectedBrokerID = get(selectedBroker, "brokerID");
          return (
            <form
              className="overview__form-wrap"
              onSubmit={handleSubmit}
              id={"proposal-builder-form-0"}>
              <Prompt
                  when={dirty && !submitting}
                  message={S.MOD_DIALOG_LEAVING}
                />

              <PromptModal
                open={isProductChangeTriggered}
                title={S.P_OVERVIEW_PROMPT_MODAL_TITLE}
                confirmText={S.P_OVERVIEW_PROMPT_MODAL_CONFIRM_BUTTON}
                rejectText={S.P_OVERVIEW_PROMPT_MODAL_REJECT_BUTTON}
                onReject={() => {
                  form.change("productTypeID", this.initialProductTypeId);
                  this.props.hideProductChangeTriggered(proposal.proposalID);
                }}
                onConfirm={() => {
                  this.props.hideProductChangeTriggered(proposal.proposalID);
                }}>
                <div>
                  <div>{S.PB_PRODUCT_TYPE_CHANGED_TOAST}</div>
                </div>
              </PromptModal>
              <Container>
                <Row>
                  <Col>
                    <h1 className="heading3">{S.PDO_SPECIALIST_TITLE}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <SingleSelectField
                      key={"specialist-name"}
                      name={"specialistID"}
                      label={S.PDO_SPECIALIST_NAME_LABEL}
                      options={selectSpecialistList}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1 className="heading3">{S.PDO_BROKER_TITLE}</h1>
                  </Col>
                </Row>
                <Row className="overview__broker-details">
                  <Col>
                    <AsyncTypeaheadField
                      className="overview__broker-details-input"
                      key={"broker-name"}
                      name={"broker"}
                      label={S.PDO_BROKER_NAME_LABEL}
                      fetch={this.searchBrokers}
                      selected={selected}
                      onChange={(selected: IBrokerOverview[]) => {
                        if (selected && selected.length) {
                          onBrokerChange(get(selected, "[0].value.notes"));
                        }
                      }}
                      parse={options => {
                        if (options.length > 0) {
                          return options[0].value;
                        }
                      }}
                    />
                  </Col>
                  <Col className="overview__broker-button">
                    <button
                      disabled={selectedBrokerID == null}
                      className="button__white"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `${window.location.origin}/brokers/${selectedBrokerID}`,
                          "_blank"
                        );
                      }}>
                      {S.PDO_BROKER_DETAILS_BUTTON}
                    </button>
                  </Col>
                </Row>

                {/** *********************
                 * Hide the following two ROWS
                 * show rows when broker is selected
                 ***************************/}

                {selectedBrokerID != null && (
                  <Row>
                    <Col>
                      <div className="overview__proposal-info">
                        <Container className="bootsrap-container-override">
                          <Row>
                            <Col xs="12" sm="6">
                              <TextField
                                name={"broker.preferredName"}
                                disabled
                                label={S.PDO_PREFERRED_BROKER_NAME_LABEL}
                              />
                            </Col>
                            <Col xs="12" sm="3">
                              <TextField
                                name="broker.primaryPhone"
                                disabled
                                type="email"
                                label={S.PDO_BROKER_PRIMARY_PHONE_LABEL}
                              />
                            </Col>
                            <Col xs="12" sm="3">
                              <TextField
                                name={"broker.companyName"}
                                disabled
                                label={S.PDO_BROKER_COMPANY_LABEL}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                )}
                {selectedBrokerID != null && (
                  <Row>
                    <Col>
                      <div className="overview__proposal-info">
                        <Container className="bootsrap-container-override">
                          <Row>
                            <Col>
                              <TextField
                                name={"broker.affiliateName"}
                                disabled
                                label={S.PDO_BROKER_AFFILIATE_LABEL}
                              />
                            </Col>
                            <Col>
                              <TextField
                                name={"broker.brokerID"}
                                disabled
                                label={S.PDO_BROKER_ID_LABEL}
                              />
                            </Col>
                            <Col>
                              <TextField
                                name={"broker.externalID"}
                                disabled
                                label={S.PDO_BROKER_EXTERNAL_ID_LABEL}
                              />
                            </Col>
                            <Col>{/* Do not remove, spacer column */}</Col>
                          </Row>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <h1 className="heading3">{S.PDO_PRODUCT_TITLE}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <SingleSelectField
                      key={"product-type-field"}
                      name={"productTypeID"}
                      label={S.PDO_PRODUCT_TYPE_LABEL}
                      options={productOptions}
                    />
                  </Col>
                  <OnChange name='productTypeID'>
                      {value => {
                        if (!isNew)
                          this.props.triggerProductChange(proposal.proposalID, value);
                      }
                    }
                  </OnChange>
                </Row>
                <Row>
                  <Col>
                    <h1 className="heading3">{S.PDO_CLIENT_TITLE}</h1>
                  </Col>
                </Row>
                <FieldArray name="clients">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div key={name}>
                        <Row>
                          <Col>
                            <TextField
                              key={"client-first-name"}
                              name={`${name}.firstName`}
                              label={S.PDO_CLIENT_FIRST_NAME_LABEL}
                            />
                          </Col>
                          <Col>
                            <TextField
                              key={"client-last-name"}
                              name={`${name}.lastName`}
                              label={S.PDO_CLIENT_LAST_NAME_LABEL}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))
                  }
                </FieldArray>
                <Row>
                  <Col>
                    <h1 className="heading3">{S.PDO_PROPOSAL_SOURCE_TITLE}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <Container className="overview__proposal-info bootsrap-container-override">
                        <Row>
                          <Col>
                            <SingleSelectField
                              key={"origination source key"}
                              label={S.PDO_ORIGINATION_LABEL}
                              name={"originationTypeID"}
                              options={sourceOptions}
                            />
                          </Col>
                          <Col>
                            <SingleSelectField
                              key={"hear_about key"}
                              label={S.PDO_HOW_DID_LABEL}
                              name={"hearAboutUsTypeID"}
                              options={hearAboutUsOptions}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className="buttons buttons__wrap">
                <div className="overview__next-button buttons__display">
                  <button className="button__orange" type="submit" disabled={this.isNextPrevented(isSavingNext, isClassificationLoaded)}>
                    {this.isNextPrevented(isSavingNext, isClassificationLoaded) ? <Spinner color="white" /> : S.PC_NEXT_BUTTON}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
        />
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  overviewOptions: state.proposalOptions.overviewOptions,
  proposalSourceOptions: state.proposalOptions.overviewOptions.selectOriginList,
  proposalHearAboutUsOptions: state.proposalOptions.overviewOptions.selectHearAboutUsList,
  isSubmitting: state.proposalOptions.isSubmitting,
  isSavingNext: state.proposalOptions.isSavingNext,
  isClassificationLoaded: state.proposalOptions.isClassificationLoaded,
  isSavingPrevious: state.proposalOptions.isSavingPrevious,
  proposalMap: state.proposalOptions.proposalMap
});

const mapDispatchToProps = {
   fetchBrokerDetails,
   saveProposal,
   submitProposal,
   triggerProductChange,
   hideProductChangeTriggered
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const Overview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalDetailsOverview);

export default Overview;
