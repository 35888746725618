import React from "react";
import { Table } from "reactstrap";
import FinalTable, {
  IFinalTableProps,
  FinalTableHeader,
  FinalTableBody
} from "../FinalTable/FinalTable";
import * as S from "../../constants/StringConstants";

import "./Table.scss";
import { Icon } from "@material-ui/core";

export interface IDisTableProps<R> extends IFinalTableProps<R> {
  isPaginated: boolean;
  getRef?: any;
}

export default class DisTable<R> extends React.Component<IDisTableProps<R>> {
  public static defaultProps = {
    isPaginated: true
  };
  render() {
    const { isPaginated, children, getRef, ...tableProps } = this.props;

    return (
      <FinalTable<R> {...tableProps} ref={getRef}>
        {props => {
          const { page, rows, total } = props;
          const rowStart = (page - 1) * rows + 1;
          const rowEnd = Math.min(page * rows, total);
          return (
            <div className="table-container">
              <div className="table-toolbar">
                <div className="table-toolbar__wrap">{children}</div>
              </div>
              <div className="table-view__main-wrap">
                <div className="table-view__main-wrap__inner">
                  <Table className="table-view__wrap">
                    <FinalTableHeader />
                    <FinalTableBody />
                  </Table>
                </div>
              </div>
              {isPaginated && (
                <section className="table-footer">
                  <div className="table-footer__container">
                    <ul className="table-footer__rows">
                      <li>{S.PAGINATION_ROWS_PER_PAGE}</li>
                      <select
                        className="table-footer__pagination-select"
                        value={props.rows}
                        disabled={props.loading}
                        onChange={event =>
                          props.update(state => ({ page: 1, rows: parseInt(event.target.value, 10), query: state.query }))
                        }>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </ul>
                    <ul className="table-footer__pagination-page">
                      <li>
                        <div
                          onClick={() =>
                            {
                              if(!props.loading && rowStart > 1){
                                props.update(state => ({ page: state.page - 1, rows: state.rows, query: state.query }))
                              }
                            }
                          }>
                          <Icon>chevron_left</Icon>
                        </div>
                      </li>
                      <li> {S.PAGINATION_PAGE_STATUS(rowStart, rowEnd, props.total)} </li>
                      <li>
                        <div
                          onClick={() =>
                            {
                              if(!props.loading && rowEnd < props.total) {
                                props.update(state => ({ page: state.page + 1, rows: state.rows, query: state.query }));
                              }
                            }
                          }>
                          <Icon>chevron_right</Icon>
                        </div>
                      </li>
                    </ul>
                  </div>
                </section>
              )}
            </div>
          );
        }}
      </FinalTable>
    );
  }
}
