import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Prompt } from "react-router-dom";
import { Container, Row, Col, Spinner } from "reactstrap";
import { FieldArray } from "react-final-form-arrays";
import { Form as FinalForm, FormRenderProps } from "react-final-form";
import { get, set } from "lodash";
import createDecorator from "final-form-calculate";
import moment from "moment";
import arrayMutators from "final-form-arrays";

import * as S from "../../../../../constants/StringConstants";
import { IRootState } from "../../../../../store/reducers";
import Classification from "./Classification";
import AnalyzerColumn, { analyzerDropDownStructure } from "./AnalyzerColumn";
import { IProposal } from "../../../../../store/models/proposal.model";
import { roundCurrency } from "../../../../../util/utils.currency";
import {
  fetchProductOptions,
  fetchProductOptionsButWithAGet
} from "../../../../../store/reducers/analyzerTree";
import { saveSymbol, stepSymbol, stepIndexSymbol } from "./ProposalBuilder";

import "./Analyzer.scss";

export interface IProposalDetailsAnalyzerProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  proposal: IProposal;
  next: (values: any) => void;
  generatePDF: (values: any) => void;
  previous: (values: any) => void;
  step: (values: any, pageIndex: number) => void;
}

const classificationPath = (index: number, name: string): string => {
  return `classifications.${index}.analyzer.${name}`;
};

export class ProposalDetailsAnalyzer extends React.Component<IProposalDetailsAnalyzerProps> {
  handleSubmit = values => {
    const { next, previous, generatePDF, step } = this.props;

    const { formAction, ...rest } = values;

    if (window[saveSymbol]) {
      generatePDF(rest);
      window[saveSymbol] = false;
    } else if (window[stepSymbol]) {
      step(values, window[stepIndexSymbol]);
      window[stepSymbol] = false;
    } else {
      if (formAction === "next") {
        next(rest);
      } else {
        previous(rest);
      }
    }
  };

  handleValidate = values => {
    const errors = {};

    const {isClassificationLoaded } = this.props;
    // Classifications are not required for multi life clients
    const isMultiLife = values.isMultiLife;
    if (isMultiLife) {
      return errors;
    }

    if(isClassificationLoaded && values.classifications){
      values.classifications.map((c, index) => {
        const baseBenefitPath = classificationPath(index, "baseBenefitAmount");
        const ssibPath = classificationPath(index, "ssib");
        const renewabilityPath = classificationPath(index, "renewabilityTypeID");
        const bpPath = classificationPath(index, "bpTypeID");
        const epPath = classificationPath(index, "epTypeID");
        const ownOccPath = classificationPath(index, "ownOccTypeID");
        const residualPath = classificationPath(index, "residualTypeID");
        const airPath = classificationPath(index, "airTypeID");
        const colaPath = classificationPath(index, "colaTypeID");
        const apPath = classificationPath(index, "annualPremium");

        const keys = [
          baseBenefitPath,
          ssibPath,
          renewabilityPath,
          bpPath,
          epPath,
          ownOccPath,
          residualPath,
          airPath,
          colaPath,
          apPath
        ];

        keys.map(k => {
          const value = get(values, k);
          if (value === undefined || value === null || isNaN(value)) {
            set(errors, k, S.FORM_FIELD_REQUIRED);
          } else if (typeof value === "number" && value < 0) {
            set(errors, k, S.FORM_FIELD_INVALID);
          }
        });
      });
    }

    return errors;
  };

  /**
   * Return the number of months associated with a bp type id.
   *
   * When computing an age based BP, years are rounded up to the nearest full month.
   */
  bpMonths = (bpTypeID: number, dateOfBirth: string): number => {
    const { bpTypeFullList } = this.props;

    var months = null;

    if (bpTypeFullList) {
      const bpType = bpTypeFullList.find(bp => {
        return bp.bpTypeID === bpTypeID;
      });
      if (bpType) {
        if (bpType.isByAge) {
          if (dateOfBirth) {
            const endMoment = moment(dateOfBirth).add(bpType.amount, "years");
            const nowMoment = moment();
            const months = Math.abs(Math.ceil(nowMoment.diff(endMoment, "months", true)));
            return months;
          }
        } else {
          months = bpType.amount * 12;
        }
      }
    }

    return months;
  };

  /**
   * Compute the Total Benefit for the specified column in Step 5
   * Total Benefit = Base Benefit + SSIB
   * @param columnName
   * @param allValues
   */
  computeTotalBenefitForColumn(columnName: string, allValues: Object): number {
    // Input Fields
    const ssibAmountField = columnName + ".analyzer.ssib";
    const baseBenefitField = columnName + ".analyzer.baseBenefitAmount";

    // Parse Values
    const ssibValue = get(allValues, ssibAmountField, 0);
    const baseBenefitValue = get(allValues, baseBenefitField, 0);

    const totalBenefit = ssibValue + baseBenefitValue;
    return totalBenefit;
  }

  /**
   * Compute the Potential Benefit for the specified column in step 5
   * Total Benefit = Total Benefit * BP months
   * @param columnName
   * @param allValues
   */
  computePotentialBenefitForColumn(columnName: string, allValues: Object): number {
    // Input Fields
    const dateOfBirthField = "clients.0.dateOfBirth";
    const bpField = columnName + ".analyzer.bpTypeID";
    const totalBenefitField = columnName + ".analyzer.totalBenefitAmount";

    // Parse Values
    const dateOfBirthValue = get(allValues, dateOfBirthField);

    const bpTypeID = get(allValues, bpField);
    const bpValue = this.bpMonths(bpTypeID, dateOfBirthValue);
    const totalBenefitValue = get(allValues, totalBenefitField);

    // Compute total value
    const totalValue = totalBenefitValue * bpValue;

    return totalValue;
  }

  /**
   * Compute the Monthly value for the specified column in step 5.
   * Monthly Amount = ((AP - APF) * MF) + MPF
   * @param columnName
   * @param allValues
   */
  computeMonthlyPremiumForColumn(columnName: string, allValues: Object): number {
    const { designTypeFullList } = this.props;

    // Input Fields
    const designTypeField = columnName + ".designTypeID";
    const annualPremiumField = columnName + "analyzer.annualPremium";

    // Parse Values
    const designTypeIDString = get(allValues, designTypeField);
    const designTypeID = parseInt(designTypeIDString, 10);

    const apValue = get(allValues, annualPremiumField, 0);

    const designType = designTypeFullList.find(d => {
      return d.designTypeID === designTypeID;
    });

    var monthlyBenefit = apValue;

    if (designType) {
      const annualPolicyFee = designType.annualPolicyFee;
      const modalFactor = designType.modalFactor;
      const modalPolicyFee = designType.modalPolicyFee;

      monthlyBenefit = (apValue - annualPolicyFee) * modalFactor + modalPolicyFee;
    }

    return monthlyBenefit;
  }

  /**
   * Compute the Cost per 100 of Benefit value for the specified
   * column in step 5 (Analyzer)
   * Corrected Formula: AP/MB*100
   * where AP = Annual Premium MP=Total Benefit (line 3 on the analyzer)
   */

  computeCostPer100BenefitForColumn(columnName: string, allValues: Object): number {

    // Input Fields
    const annualPremiumField = columnName + "analyzer.annualPremium";
    // const baseBenefit = columnName + "analyzer.baseBenefitAmount";
    const totalBenefit = columnName + "analyzer.totalBenefitAmount"

    // Parse Values
    const baseTypeIDString = get(allValues, totalBenefit);
    const baseTypeID = parseInt(baseTypeIDString, 10);
    const apValue = get(allValues, annualPremiumField, 0);
    // Operation
    let costPerBenefits = apValue/baseTypeID*100

    return costPerBenefits
  }


  analyzerFieldDecorator = createDecorator({
    field: /classifications\[[0-9]+\]\.analyzer\./,
    updates: (value, name, allValues, previousValues) => {
      if (previousValues) {
        var proposalIdForPrevValues = get(previousValues, "proposalID");
        if (!proposalIdForPrevValues) {
          return {};
        }
      }

      if (isNaN(value)) {
        console.log("value not number");
        return {};
      }

      const regex = /(classifications\[[0-9]+\])\.(analyzer\..+)$/;
      const match = regex.exec(name);

      if (!match) {
        console.log(`failed to match analyzer value: ${name}`);
        return {};
      }

      const columnName = match[1];
      const fieldName = match[2];
      const column = get(allValues, columnName);
      const column1 = get(column, fieldName);

      const updates = this.compute(columnName, fieldName, allValues);

      if (columnName) {
        // Compute Monthly
        const monthlyField = columnName + ".analyzer.monthlyPremium";
        const monthlyAmount = this.computeMonthlyPremiumForColumn(columnName, allValues);
        updates[monthlyField] = roundCurrency(monthlyAmount);

        // Compute Total Benefit
        const totalBenefitField = columnName + "analyzer.totalBenefitAmount";
        const totalBenefit = this.computeTotalBenefitForColumn(columnName, allValues);
        updates[totalBenefitField] = roundCurrency(totalBenefit);

        // Compute Potential Benefit
        const potentialBenefitField = columnName + ".analyzer.potentialBenefitPayout";
        const totalValue = this.computePotentialBenefitForColumn(columnName, allValues);
        updates[potentialBenefitField] = roundCurrency(totalValue);
      }
      // Compute Cost per 100 of Benefit
      const benefitCost = columnName + "analyzer.costPer100Benefit"
      const benefitCostAmount = this.computeCostPer100BenefitForColumn(columnName, allValues);
      updates[benefitCost] = roundCurrency(benefitCostAmount);

      return updates;
    }
  });

  compute = (columnName, fieldName, formValues) => {
    const fieldIndex = analyzerDropDownStructure.findIndex(f => {
      return f.fieldName === fieldName;
    });

    if (fieldIndex === -1) {
      console.log(`${fieldName} not found in analyzerDropDowns`);
      return {};
    }

    const fieldsToClear = analyzerDropDownStructure.slice(
      fieldIndex + 1,
      analyzerDropDownStructure.length
    );
    const fieldsForRequest = analyzerDropDownStructure.slice(0, fieldIndex + 1);

    const updates = {};

    // Mark fields to clear
    for (let i = 0; i < fieldsToClear.length; i++) {
      const fieldNameToClear = columnName + "." + fieldsToClear[i].fieldName;
      updates[fieldNameToClear] = undefined;
    }

    // Gather fields for network request
    const requestParams = {};
    for (let i = 0; i < fieldsForRequest.length; i++) {
      const field = fieldsForRequest[i];
      const fieldNameForRequest = columnName + "." + field.fieldName;
      const paramName = field.paramName;
      requestParams[paramName] = get(formValues, fieldNameForRequest);
    }
    requestParams["productTypeID"] = get(formValues, "productTypeID");

    const route = analyzerDropDownStructure[fieldIndex].route;
    if (route !== "") {
      this.props.fetchProductOptions(route, requestParams);
    }

    return updates;
  };

  render() {
    const { proposal, isSubmitting, isSavingNext, isSavingPrevious, isClassificationLoaded } = this.props;

    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={proposal}
        decorators={[this.analyzerFieldDecorator]}
        mutators={{ ...arrayMutators }}>
        {formProps => {
          const formValues = formProps.values;
          const productTypeID = get(formValues, "productTypeID");
          return (
            <FieldArray name={"classifications"}>
              {({ fields }) => (
                <form
                  className={
                    fields.length > 3 ? "analyzer__wrap analyzer__overflow" : "analyzer__wrap "
                  }
                  onSubmit={formProps.handleSubmit}
                  id="proposal-builder-form-4">
                  <Prompt
                    when={formProps.dirty && !formProps.submitting}
                    message={S.MOD_DIALOG_LEAVING}
                  />
                  <Container>
                    <Row className="row__wrap bootstrap-col-pad-override">
                      {isClassificationLoaded && fields.map((name, index) => (
                        <AnalyzerColumn
                          key={name}
                          name={name}
                          value={fields.value[index]}
                          productTypeID={productTypeID}
                        />
                      ))}
                    </Row>
                  </Container>
                  <div className="buttons buttons__wrap submitButtonsWrap">
                    <button
                      className="button__orange buttons__display buttons__mr-20"
                      type="submit"
                      disabled={isSubmitting || !isClassificationLoaded}
                      onClick={e => {
                        formProps.form.change("formAction", "previous");
                      }}>
                      {isSavingPrevious || !isClassificationLoaded ? <Spinner color="white" /> : S.PC_PREV_BUTTON}
                    </button>
                    <div className="overview__next-button buttons__display">
                      <button
                        className="button__orange"
                        type="submit"
                        disabled={isSubmitting || !isClassificationLoaded}
                        onClick={e => {
                          formProps.form.change("formAction", "next");
                        }}>
                        {isSavingNext || !isClassificationLoaded ? <Spinner color="white" /> : S.ANL_SAVE_BUTTON}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </FieldArray>
          );
        }}
      </FinalForm>
    );
  }
}

const mapStateToProps = function(state: IRootState){
  return ({
    isSubmitting: state.proposalOptions.isSubmitting,
    bpTypeFullList: state.proposalOptions.classificationOptions.bpTypeFullList,
    designTypeFullList: state.proposalOptions.classificationOptions.designTypeFullList,
    isSavingNext: state.proposalOptions.isSavingNext,
    isClassificationLoaded: state.proposalOptions.isClassificationLoaded,
    isSavingPrevious: state.proposalOptions.isSavingPrevious
  });
}

const mapDispatchToProps = {
  fetchProductOptions,
  fetchProductOptionsButWithAGet
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalDetailsAnalyzer);
