import * as React from "react";
import { Component, createRef } from 'react';
import { connect, memoize } from "react-redux";
import { RouteComponentProps, Link } from "react-router-dom";
import { set, get } from "lodash";
import MaterialTable from "material-table";
import {
    updateCensusData,
    deleteCensusData,
    saveCensusColumnsOrder,
    getCensusColumnsOrder
  } from "../../../../../../store/reducers/proposalCensus";

import { IRootState } from "../../../../../../store/reducers";

import * as S from "../../../../../../constants/StringConstants";
import "./TwoCarrierGrid.scss";

export interface ITwoCarrierGridProps extends RouteComponentProps, StateProps, DispatchProps {
    proposalID: number;
  }

class TwoCarrierGrid extends React.Component<ITwoCarrierGridProps> {

    tableRef: any;

    constructor(props) {
        super(props);
        this.tableRef = createRef();
    }

    componentDidMount() {
      this.props.getCensusColumnsOrder(`${this.props.proposalID}`);
    }

    checkChanges = changes => {
        let isValid = true;

        var attempt = true;
        var i = 0;
        do {
            const change = get(changes, `${i}`);
            if (change) {
            isValid = change.newData.income >= 0
                    && change.newData.groupMonthlyBenefit >= 0
                    && change.newData.groupMonthlyBenefit >= 0
                    && change.newData.individualMonthlyBenefit >= 0
                    && change.newData.monthlyDiscountedPremium >= 0 
                    && change.newData.percentOfIncomeProtectedByGroup >= 0 && change.newData.percentOfIncomeProtectedByGroup <= 100
                    && change.newData.totalPercentOfIncomeProtected >= 0 && change.newData.totalPercentOfIncomeProtected <= 100; 
            i++;
            }
            else {
            attempt = false;
            }
        } while (attempt && isValid);

        return isValid;
    }
    
  render() {
    const {
        proposalCensus
      } = this.props;

      var uploadedCensus = proposalCensus.map[this.props.proposalID];
      var uploadedCensusSortOrder = proposalCensus.mapSortOrder[this.props.proposalID];
      if(uploadedCensus && uploadedCensusSortOrder && uploadedCensusSortOrder.field) {
        var sortOrder = 1;
        var property = uploadedCensusSortOrder.field;
        if(uploadedCensusSortOrder.sortOrder === "desc"){
          sortOrder = -1;
        }
        uploadedCensus = uploadedCensus.sort((a, b) => {
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        })
      }

    var ref = this.tableRef;
    const TBD = "TBD";

    return (
        <MaterialTable
        
        tableRef={this.tableRef}
        options={{
          search: false,
          paging: false,
          maxBodyHeight: "552px"
        }}
        title=""
        columns={[
          {
            title: 'Name',
            field: 'name',
            sorting: true,
            render: rowData => {
              let className = "";
              if(rowData.percentOfIncomeProtectedByGroup < 50)
                className = 'red-cell';
              if(rowData.percentOfIncomeProtectedByGroup >=50 && rowData.percentOfIncomeProtectedByGroup <= 60)
                className = 'yellow-cell';
              
              return <span className={className}>{rowData.name}</span>
            } 
          },
          { 
            title: 'Income', 
            field: 'income',
            sorting: true,
            type: 'currency',
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            validate: rowData => rowData.income < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
            render: rowData => {
              return rowData.income;
            }
          },

          { 
            title: 'GLTD Benefit Amount', 
            field: 'twoCarrierGltdBenefitAmount',
            type: 'currency',
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            validate: rowData => rowData.twoCarrierGltdBenefitAmount < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
            render: rowData => {
              return rowData.twoCarrierGltdBenefitAmount;
            }
          },
          { 
            title: 'GLTD Benefit %', 
            field: 'twoCarrierGltdBenefitPercent',
            type: 'currency',
            validate: rowData => 
            {
              if (rowData.twoCarrierGltdBenefitPercent < 0 || rowData.twoCarrierGltdBenefitPercent > 100)
                return { isValid: false, helperText: S.PERCENT_VALUE_INVALID };
              else if(rowData.twoCarrierGltdBenefitPercent + rowData.twoCarrierIdiBenefitPercent + rowData.twoCarrierLloydsBenefitPercent > 100){
                return { isValid: false, helperText: S.PERCENT_SUM_VALUE_INVALID };
              }
              else {
                return true;
              } 
            },
            render: rowData => {
              return rowData.twoCarrierGltdBenefitPercent;
            }
          },

          { 
            title: 'IDI Benefit Amount', 
            field: 'twoCarrierIdiBenefitAmount',
            type: 'currency',
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            validate: rowData => rowData.twoCarrierIdiBenefitAmount < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
            render: rowData => {
              return rowData.twoCarrierIdiBenefitAmount;
            }
          },
          { 
            title: 'IDI Benefit %', 
            field: 'twoCarrierIdiBenefitPercent',
            type: 'currency',
            validate: rowData => 
            {
              if (rowData.twoCarrierIdiBenefitPercent < 0 || rowData.twoCarrierIdiBenefitPercent > 100)
                return { isValid: false, helperText: S.PERCENT_VALUE_INVALID };
              else if(rowData.twoCarrierGltdBenefitPercent + rowData.twoCarrierIdiBenefitPercent + rowData.twoCarrierLloydsBenefitPercent > 100){
                return { isValid: false, helperText: S.PERCENT_SUM_VALUE_INVALID };
              }
              else {
                return true;
              } 
            },
            render: rowData => {
              return rowData.twoCarrierIdiBenefitPercent;
            }
          },

          { 
            title: 'Lloyds Benefit Amount', 
            field: 'twoCarrierLloydsBenefitAmount',
            type: 'currency',
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            validate: rowData => rowData.twoCarrierLloydsBenefitAmount < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
            render: rowData => {
              return rowData.twoCarrierLloydsBenefitAmount;
            }
          },
          { 
            title: 'Lloyds Benefit %', 
            field: 'twoCarrierLloydsBenefitPercent',
            type: 'currency',
            validate: rowData => 
            {
              if (rowData.twoCarrierLloydsBenefitPercent < 0 || rowData.twoCarrierLloydsBenefitPercent > 100)
                return { isValid: false, helperText: S.PERCENT_VALUE_INVALID };
              else if(rowData.twoCarrierGltdBenefitPercent + rowData.twoCarrierIdiBenefitPercent + rowData.twoCarrierLloydsBenefitPercent > 100){
                return { isValid: false, helperText: S.PERCENT_SUM_VALUE_INVALID };
              }
              else {
                return true;
              } 
            },
            render: rowData => {
              if(!rowData.twoCarrierLloydsBenefitPercent)
                return TBD;
              return rowData.twoCarrierLloydsBenefitPercent;
            }
          },

          { 
            title: 'Total Protection Amount',
            type: 'currency',
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            render: rowData => {
                return (+rowData.twoCarrierGltdBenefitAmount || 0) + (+rowData.twoCarrierIdiBenefitAmount || 0) + (+rowData.twoCarrierLloydsBenefitAmount || 0);
            }
          },
          { 
            title: 'Total Protection %',
            type: 'currency',
            render: rowData => {
              return (+rowData.twoCarrierGltdBenefitPercent || 0) + (+rowData.twoCarrierIdiBenefitPercent || 0) + (+rowData.twoCarrierLloydsBenefitPercent || 0);
            }
          },

          { 
            title: 'IDI Benefit Premium', 
            field: 'twoCarrierIdiBenefitPremium',
            type: 'currency',
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            validate: rowData => rowData.twoCarrierIdiBenefitPremium < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
            render: rowData => {
              return rowData.twoCarrierIdiBenefitPremium;
            }
          },
          { 
            title: 'Lloyds Premium', 
            field: 'twoCarrierLloydsPremium',
            type: 'currency',
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            validate: rowData => rowData.twoCarrierLloydsPremium < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
            render: rowData => {
              if(!rowData.twoCarrierLloydsPremium)
                return TBD;
              return rowData.twoCarrierLloydsPremium;
            }
          }
        ]}
        data={uploadedCensus}
        onOrderChange={(orderedColumnId, orderDirection) => {
          if(ref.current.dataManager.columns[orderedColumnId]){
            var field = ref.current.dataManager.columns[orderedColumnId].field;
            this.props.saveCensusColumnsOrder(`${this.props.proposalID}`, field, orderDirection);
          }
        }}
        editable={{
          onBulkUpdate: changes =>
            new Promise((resolve, reject) => {
              if (!this.checkChanges(changes)) {
                return reject(); // Reject when invalid
              }
              else {
                this.props.updateCensusData(`${this.props.proposalID}`, changes);
                setTimeout(() => { resolve(); }, 1000);
              }
            }),     
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              this.props.deleteCensusData(`${this.props.proposalID}`, oldData);
              setTimeout(() => {
                resolve();
              }, 1000);
            }),     
        }}
      />
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
    proposalMap: state.proposalOptions.proposalMap,
    proposalCensus: state.proposalCensus
});

const mapDispatchToProps = { 
    updateCensusData,
    deleteCensusData,
    saveCensusColumnsOrder,
    getCensusColumnsOrder
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoCarrierGrid);
