import React from "react";
import { Form as FinalForm, FormSpy } from "react-final-form";
import { connect } from "react-redux";
import { get, set } from "lodash";
import { Container, Row, Col } from "reactstrap";

import { IRootState } from "../../store/reducers";
import { IUser, UserRole } from "../../store/models/user.model";
import { createCompany, fetchCompany, updateCompany } from "../../store/reducers/companies";
import { fetchCompanyOptions } from "../../store/reducers/companyBuilder";

import * as S from "../../constants/StringConstants";
import TextField from "../../components/Form/TextField/TextField";
import NumberFormatField from "../../components/Form/NumberFormatField/NumberFormatField";
import SingleSelectField from "../../components/Form/SingleSelectField/SingleSelectField";
import TypeaheadField, {
  keyValueToDropdownOption,
  ITypeaheadOption
} from "../../components/Form/TypeaheadField/TypeaheadField";
import { RouteComponentProps } from "react-router";
import "./CompanyBuilder.scss";
import { ICompany } from "src/store/models/company.model";
import { FORM_ERROR } from "final-form";
import { Divider } from "@material-ui/core";

import formatString from "format-string-by-pattern";
import * as P from "../../util/utils.validators";

import {
  defaultOptionValue,
  addDefaultOption,
  isEmptyOrDefault,
  undefinedIfDefault
} from "../../util/utils.defaultValues";
import CompanyQuotesWidjet from './CompanyQuotesWidjet';

interface ICompanyBuilderProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

class CompanyBuilder extends React.Component<ICompanyBuilderProps> {
  validate = (values: any) => {
    const errors = {};

    if (P.isEmpty(values.name)) {
      set(errors, "name", S.FORM_FIELD_REQUIRED);
    }

    // const firstName = get(values, "primaryContact.firstName");
    // if (P.isEmpty(firstName)) {
    //   set(errors, "primaryContact.firstName", S.FORM_FIELD_REQUIRED);
    // }

    // const lastName = get(values, "primaryContact.lastName");
    // if (P.isEmpty(lastName)) {
    //   set(errors, "primaryContact.lastName", S.FORM_FIELD_REQUIRED);
    // }

    // if (!values.branchTypeID) {
    //   set(errors, "branchTypeID", S.FORM_FIELD_REQUIRED);
    // }

    // if (!values.emailAddress) {
    //   set(errors, "emailAddress", S.FORM_FIELD_REQUIRED);
    // }

    // const phoneNumber = get(values, "phoneNumber");
    // const pattern = P.PHONE_NUMBER_REGEX;
    // if (phoneNumber && !pattern.test(phoneNumber)) {
    //   set(errors, "phoneNumber", S.FORM_FIELD_INVALID);
    // }

    const zipCode = get(values, "address.zip");
    const zipPattern = P.ZIP_CODE_REGEX;
    if (zipCode && !zipPattern.test(zipCode)) {
      set(errors, "address.zip", S.FORM_FIELD_INVALID_ZIPCODE);
    }

    // const externalID = get(values, "externalID");
    // if (externalID && !P.EXTERNAL_ID_REGEX.test(externalID)) {
    //   set(errors, "externalID", S.FORM_FIELD_INVALID);
    // }

    return errors;
  };

  companyToFormValues(company: any, affiliates: ITypeaheadOption[]) {
    if (!company || !affiliates || !affiliates.length) {
      return company;
    }
    const affiliate = affiliates.find(a => a.value === get(company, "affiliateID"));

    if (affiliate) {
      company.affiliateID = [affiliate];
    } else {
      company.affiliateID = undefined;
    }

    return company;
  }

  formValuesToCompany(values: any) {
    if (!values) {
      return values;
    }

    let affiliateIDs = get(values, "affiliateID");

    values.branchTypeID = undefinedIfDefault(values.branchTypeID);
    values.rbManagerID = undefinedIfDefault(values.rbManagerID);
    if (values.address) {
      values.address.stateID = undefinedIfDefault(values.address.stateID);
    }

    if (affiliateIDs && affiliateIDs.length && affiliateIDs[0].value) {
      values.affiliateID = affiliateIDs[0].value;
    } else {
      values.affiliateID = undefined;
    }

    return values;
  }

  onSubmit = async (values: any) => {
    const company = this.formValuesToCompany(values);
    try {
      if (this.companyID) {
        await this.props.updateCompany(company);
      } else {
        await this.props.createCompany(company);
      }
    } catch (err) {
      return { [FORM_ERROR]: "Error Submitting" };
    }

    // TODO: Get this path from a library/directory
    this.props.history.push("/companies/all");
  };

  async componentDidMount() {
    await this.props.fetchCompanyOptions();

    // check match for id param
    const id = this.companyID;
    if (id) {
      await this.props.fetchCompany(this.companyID.toString());
    }
  }

  get companyID() {
    const id = parseInt(this.props.match.params.id, 10);

    return id && !isNaN(id) ? id : null;
  }

  getAffiliateButtonLabel(form: any) {
    const affIDField = form.getFieldState("affiliateID");
    if (!affIDField || !affIDField.value || !affIDField.value.length) {
      return S.CO_ADD_AFFILIATE_BUTTON;
    }

    return S.CO_AFFILIATE_DETAILS_BUTTON;
  }

  handleAffiliateDetailsClick = (e, form) => {
    e.preventDefault();
    const affIDField = form.getFieldState("affiliateID");
    if (affIDField && affIDField.value) {
      window.open(`${window.location.origin}/affiliates/${affIDField.value[0].value}`);
    }
  };

  render() {
    const { dropdownOptions, companies } = this.props;
    const phoneMask = { name: "phone-1", parse: "(999) 999-9999" };
    const zipMask = { name: "address.zip", parse: "00000" };

    // if there's an id then we should be editing
    const company = companies.map[this.companyID];
    const { user } = this.props;

    const defaultValues = {
      branchTypeID: undefined
    };

    const affiliateList = (get(dropdownOptions, "affiliateList") || []).map(
      keyValueToDropdownOption
    );

    const affiliate = affiliateList.find(a => a.value === get(company, "affiliateID"));
    const branchList = addDefaultOption("", get(dropdownOptions, "branchList", [])) || [];
    const rbmList = addDefaultOption("", get(dropdownOptions, "rbmList", []));
    const stateList = addDefaultOption("", get(dropdownOptions, "selectStateList", []));

    const initialValues = this.companyToFormValues(company, affiliateList) || defaultValues;

    var affiliateButton = null;
    if (user.userRole === UserRole.Admin) {
      affiliateButton = <FormSpy
                          render={({ form }) => (
                            <Col sm="3">
                              <button
                                className="heading5 button__white"
                                onClick={e => this.handleAffiliateDetailsClick(e, form)}>
                                {this.getAffiliateButtonLabel(form)}
                              </button>
                            </Col>
                          )}
                        />
    }
    return (
      <div>
        <FinalForm
          validate={this.validate}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, invalid }) => {
            return (
              <React.Fragment>
                <div>
                  <h1 className="heading1 grey--light company-builder__title">
                    {S.CO_DETAIL_PAGE_TITLE}
                  </h1>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className='company-builder__form'>
                    <section className="company-builder__wrap">
                      <Container>
                        <Row>
                          <Col>
                            <div className="company-builder__card-title">
                              <h2 className="heading3">{S.CO_SECOND_TITLE}</h2>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="8">
                            <TextField name="name" label={S.CO_NAME_LABEL} key="company.name-key" />
                          </Col>
                          <Col sm="2">
                            <TextField
                              name="companyID"
                              disabled
                              label={S.CO_COMPANY_ID_LABEL}
                              key="company.company-id-key"
                            />
                          </Col>
                          <Col sm="2">
                            <NumberFormatField
                              name="externalID"
                              label={S.CO_EXTERNAL_ID_LABEL}
                              key="company.external-id-key"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="3">
                            <SingleSelectField
                              name="branchTypeID"
                              label={S.CO_BRANCH_LABEL}
                              key="company.branch-id-key"
                              options={branchList}
                            />
                          </Col>
                          <Col sm="3">
                            <SingleSelectField
                              name="rbManagerID"
                              label={S.CO_RBM_LABEL}
                              key="company.rbm-id-key"
                              options={rbmList}
                            />
                          </Col>
                          <Col sm="3">
                            <TypeaheadField
                              name="affiliateID"
                              label={S.CO_AFFILIATE_LABEL}
                              key="company.affiliateID-key"
                              options={affiliateList}
                              selected={affiliate}
                            />
                          </Col>
                          { affiliateButton }
                        </Row>
                        <Row>
                          <Col sm="6">
                            <TextField
                              name="emailAddress"
                              label={S.CO_EMAIL_LABEL}
                              key="company.email-key"
                              type="email"
                            />
                          </Col>
                          <Col sm="6">
                            <TextField
                              name="phoneNumber"
                              label={S.CO_PHONE_LABEL}
                              key="company.phone-key"
                              type="text"
                              parse={formatString(phoneMask.parse)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="company-builder__card-title">
                              <h2 className="heading3">{S.CO_THIRD_TITLE}</h2>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <TextField
                              name="primaryContact.firstName"
                              label={S.CO_FIRST_NAME_LABEL}
                              key="company.first-name-key"
                            />
                          </Col>
                          <Col sm="6">
                            <TextField
                              name="primaryContact.lastName"
                              label={S.CO_LAST_NAME_LABEL}
                              key="company.last-name-key"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="company-builder__card-title">
                              <h2 className="heading3">{S.CO_FOURTH_TITLE}</h2>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <TextField
                              name="address.streetAddress"
                              label={S.CO_STREET_ADDRESS_LABEL}
                              key="company.address-key"
                            />
                          </Col>
                          <Col sm="2">
                            <TextField
                              name="address.city"
                              label={S.CO_CITY_LABEL}
                              key="company.city-key"
                            />
                          </Col>
                          <Col sm="2">
                            <SingleSelectField
                              name="address.stateID"
                              label={S.CO_STATE_LABEL}
                              key="company.state-key"
                              options={stateList}
                            />
                          </Col>
                          <Col sm="2">
                            <TextField
                              name="address.zip"
                              label={S.CO_ZIP_LABEL}
                              key="company.zip-key"
                              type="text"
                            />
                          </Col>
                        </Row>
                      </Container>
                      <div className="company-builder__button-wrap">
                        <button className="button__orange" type="submit" disabled={submitting}>
                          {S.CO_SUBMIT_BUTTON}
                        </button>
                      </div>
                    </section>
                    <aside>
                      <div className='company-builder__card-wrap'>
                        <CompanyQuotesWidjet />
                      </div>
                    </aside>
                  </div>
                </form>
              </React.Fragment>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  companies,
  affiliates,
  companyBuilder: { loading, ...dropdownOptions }
}: IRootState) => ({
  user: auth.user,
  companies,
  affiliates,
  loading,
  dropdownOptions
});

const mapDispatchToProps = { createCompany, fetchCompanyOptions, fetchCompany, updateCompany };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyBuilder);
