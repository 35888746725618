import { IBroker, defaultBroker } from "./broker.model";
import { IClassification, defaultClassification } from "./classification.model";
import { IClient, defaultClient } from "./client.model";
import * as S from "../../constants/StringConstants";

/**
 *  ProposalRecords represent a single entry in the proposals table.
 */
export interface IProposalRecord {
  timeSpan: string;
  proposalID: number;
  revisionNumber: number;
  branch: string;
  rbm: string;
  affiliate: string;
  memberCompany: string;
  brokerName: string;
  clientName: string;
  isMultiLife: boolean;
  specialistName: string;
  status: string;
  fromBrokerQuoteEngine?: boolean;
}

export const defaultValue: Readonly<IProposalRecord> = {
  timeSpan: "",
  proposalID: null,
  revisionNumber: null,
  branch: "",
  rbm: "",
  affiliate: "",
  memberCompany: "",
  brokerName: "",
  clientName: "",
  isMultiLife: null,
  specialistName: "",
  status: "",
  fromBrokerQuoteEngine: null
};

export interface IProposal {
  proposalID: number;
  proposalGroupID: number;
  broker: IBroker;
  clients: [IClient];
  classifications: Array<IClassification>;
  organization: string;
  specialistID: number;
  productTypeID: number;
  epTypeID: number;
  bpTypeID: number;
  payorTypeID: number;
  tobaccoTypeID: number;
  tobaccoFrequencyTypeID: number;
  isMaxBenefit: boolean;
  statusTypeID: number;
  originationTypeID: number;
  clientNote: string;
  twoCarrier: boolean;
  isFiveCarrier: boolean;
  isProductChangeTriggered: boolean;
}

export const defaultProposal: Readonly<IProposal> = {
  proposalID: null,
  proposalGroupID: null,
  broker: defaultBroker,
  clients: [defaultClient],
  classifications: new Array<IClassification>(),
  organization: S.PDO_QUOTE_ENGINE,
  specialistID: undefined,
  productTypeID: undefined,
  epTypeID: undefined,
  bpTypeID: undefined,
  payorTypeID: undefined,
  tobaccoTypeID: 1,
  tobaccoFrequencyTypeID: 1,
  isMaxBenefit: true,
  statusTypeID: 2,
  originationTypeID: undefined,
  clientNote: undefined,
  twoCarrier: false,
  isFiveCarrier: false,
  isProductChangeTriggered: false
};

export const overviewDefaults = {
  proposalID: null,
  proposalGroupID: null,
  broker: defaultBroker,
  clients: [defaultClient]
};
