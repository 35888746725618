import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Link, Redirect } from "react-router-dom";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Container, Row, Col, Spinner } from "reactstrap";

import { IRootState } from "../../store/reducers";
import TextField from "../../components/Form/TextField/TextField";
import { login } from "../../store/reducers/authentication";
import * as S from "../../constants/StringConstants";
import "./Login.scss";

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps {}

export class Login extends React.Component<ILoginProps> {
  onSubmit = async values => {
    const { email, password } = values;
    try {
      await this.props.login(email, password);
      this.props.history.push("/proposals/all");
    } catch (error) {
      return {
        [FORM_ERROR]: S.LOGIN_FAILED_MESSAGE
      };
    }
  };

  render() {
    const { location, isAuthenticated } = this.props;
    const { from } = location.state || { from: { pathname: "/", search: location.search } };

    if (isAuthenticated) {
      return <Redirect to={from} />;
    }

    return (
      <section className="login">
        <Container fluid>
          <Row>
            <Col>
              <div className="login__form-wrap">
                <h1>{S.LOGIN_SIGN_IN}</h1>

                <FinalForm
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, invalid, submitFailed, submitError, submitting }) => {
                    return (
                      <form noValidate className="login__form" onSubmit={handleSubmit}>
                        {submitFailed && <div className="not-valid">{S.LOGIN_FAILED_MESSAGE}</div>}

                        <TextField
                          label={S.EMAIL_LABEL}
                          name="email"
                          type="email"
                          light={true}
                          validate={value => (value ? undefined : S.FORM_FIELD_REQUIRED)}
                        />

                        <TextField
                          label={S.LOGIN_PASSWORD}
                          name="password"
                          type="password"
                          light={true}
                          validate={value => (value ? undefined : S.LOGIN_PASSWORD_REQUIRED)}
                        />
                        <button type="submit" disabled={submitting}>
                          {submitting ? <Spinner color="white" /> : S.LOGIN_SIGN_IN}
                        </button>
                      </form>
                    );
                  }}
                />
                <div className="login__forgot-pwd">
                  <Link to="/forgot-password">{S.FORGOT_PASSWORD}</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  isAuthenticated: auth.isAuthenticated
});
const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
