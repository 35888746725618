/*
 * Main
 */
export const MAIN_TITLE = "DIS";
export const MAIN_PROPOSAL_DETAILS = (proposalID: any) => `DIS | Proposal - ${proposalID}`

/*
 * Login
 */
export const LOGIN_SIGN_IN = "Sign In";
export const FORGOT_PSWD = "Submit";
export const EMAIL_LABEL = "Email";
export const LOGIN_PASSWORD = "Password";
export const LOGIN_INVALID_PASSWORD = "Invalid Password";
export const LOGIN_FAILED_MESSAGE = "Login Failed!";
export const ACCESS_FAILED_MESSAGE = "Failed to fetch account data.";
export const LOGIN_PASSWORD_REQUIRED = "Please enter a password.";
export const LOGIN_EMAIL_REQUIRED = "Please enter an email.";

/*
 * Forgot Password
 */
export const FORGOT_PASSWORD = "Forgot your password?";
export const FORGOT_PASSWORD_LABEL = "FORGOT PASSWORD";
export const FORGOT_PASSWORD_DESCRIPTION =
  "Please input your email below and the administrator will reset your password";
export const FORGOT_PASSWORD_RESET_BUTTON = "Send";
export const FORGOT_PASSWORD_ADMIN_NOTIFIED = "ADMINISTRATOR SUCCESSFULLY NOTIFIED";

/*
 * Product Builder
 */
export const PB_PRODUCT_TYPE = "Product";
export const PB_CARRIER_TYPE = "Carrier";
export const PB_OCCUPATION_CLASS_TYPE = "Occupation Class";
export const PB_DESIGN_TYPE = "Design";
export const PB_RENEWABILITY_TYPE = "Renewability";
export const PB_BP_TYPE = "BP";
export const PB_EP_TYPE = "EP";
export const PB_OWN_OCC_TYPE = "Own OCC";
export const PB_RESIDUAL_TYPE = "Residual";
export const PB_AIR_TYPE = "AIR";
export const PB_COLA_TYPE = "COLA";
export const PB_CATASTROPHIC_BENEFIT_TYPE = "Catastrophic Benefit Rider";
export const PB_FUTURE_INSURABILITY_OPTIONS_TYPE = "Future Insurability Options";
export const PB_UNIQUE_PROVISIONS_TYPE = "Unique Provisions";
export const PB_MINIMUM_RESIDUAL_BENEFIT_TYPE = "Minimum Residual Benefit Payable";
export const PB_MENTAL_NERVOUS_LIMITATION_TYPE = "Mental Nervous Limitation";
export const PB_SUBMIT_BUTTON = "Submit";
export const PB_PRODUCT_CREATED_MESSAGE = "Success!";
export const PB_DEFAULT_PRODUCT_TYPE = "Select a Product";
export const PB_SUBMIT_SUCCESS_ANALYZER_TOAST =
  "Successfully submitted! Proposal and Analyzer PDF files created.";
export const PB_SUBMIT_SUCCESS_EQUALIZER_TOAST =
  "Successfully submitted! Proposal and Equalizer PDF files created.";
export const PB_SAVE_SUCCESS_TOAST = "Your changes have been saved.";
export const PB_PROPOSAL_SUBMITTED = "Proposal Submitted, please wait.";
export const PB_PRODUCTS_LOADED = "Products are being loaded, please wait.";
export const PB_PRODUCTS_CREATION_STARTED = "Product creation process launched. You will be notified via email if finished.";
export const PB_PRODUCT_CREATION_PROCESS_BUSY = "Another product creation process in progress, please wait.";
export const PB_PRODUCT_TYPE_CHANGED_TOAST = "Changing the product will delete ALL analyzer options.";

/*
 * Loading
 */
export const LOADING = "Loading...";

/*
 * Components
 */
export const DD_NO_RESULTS = "No Results Found";

/*
 * Errors
 */
export const ERROR_NETWORK_GENERAL = "Network error. Please try again later.";
export const ERROR_USER_ROLE_UNAUTHORIZED = "You are not authorized to access this page.";
export const EQ_CENSUS_ERROR_FILE_UPLOAD = "Could not upload File. please check format and try again later.";

/**
 * Form Errors
 */
export const FORM_FIELD_REQUIRED = "This field is required";
export const FORM_FIELD_INVALID = "Invalid";
export const FORM_FIELD_INVALID_ZIPCODE = "Zip code must be numeric";
export const FORM_DOCUMENT_REQUIRED = "Document required";
export const FORM_FILE_UPLOAD_REQUIRED = "* Please note: It is mandatory to upload the file.";
export const VALUE_COULD_NOT_BE_NEGATIVE = "value could not be negative";
export const PERCENT_VALUE_INVALID = "invalid percent value";
export const PERCENT_SUM_VALUE_INVALID = "percents sum should be less 100";

/*
 * Proposals Table
 */
export const PRO_COL_RECEIVED_DATE = "Rec'd date";
export const PRO_COL_TIME = "Time";
export const PRO_COL_PROPOSAL = "Proposal";
export const PRO_COL_PRODUCT_TYPE = "Product";
export const PRO_COL_REV = "Rev";
export const PRO_COL_BRANCH = "Branch";
export const PRO_COL_RBM = "RBM";
export const PRO_COL_AFFILIATE = "Affiliate";
export const PRO_COL_MEMBER = "Member Company";
export const PRO_COL_BROKER = "Broker";
export const PRO_COL_CLIENT = "Client";
export const PRO_COL_PROPOSAL_SPECIALIST = "Specialist";
export const PRO_COL_STATUS = "Status";
export const PRO_COL_MULTI_LIFE = "Multi-Life";
export const PRO_ALL = "All";

/**
 * Brokers Table
 */
export const BRO_COL_NAME = "Broker Name";
export const BRO_COL_PRIMARY_PHONE = "Primary Phone";
export const BRO_COL_PRIMARY_EMAIL = "Primary Email";
export const BRO_COL_COMPANY = "Company";
export const BRO_COL_AFFILIATE = "Affiliate";
export const BRO_PAGE_TITLE = "Brokers";


export const H_TITLE = "System Health Check";
export const H_TWILIO_TITLE = "Twilio";
export const H_TWILIO_ACCOUNT_SID = "Account SID:";
export const H_TWILIO_AUTH_TOKEN = "Authentication Token:";
export const H_TWILIO_FROM_NUMBER = "Sender Number:";
export const H_TWILIO_SEND = "Send:";
export const H_TWILIO_LASTSMS_PHONE = "Last Receiver:";
export const H_TWILIO_LASTSMS_BODY = "Last Body:";
export const H_TWILIO_LASTSMS_DATE = "Last Date:";
export const H_TWILIO_LASTSMS_SEND = "Last Send:";
export const H_TWILIO_SMS_SEND = "SMS queued into mobile operators line.";


export const H_SMS_TEST_TITLE = "System Health Check";
export const H_SMS_TEST_TEXT_LABEL = "SMS:";
export const H_SMS_TEST_PHONE_LABEL = "Phone number:";
export const H_SMS_SUBMIT_BUTTON = "Send test SMS";

export const H_REINDEX_TITLE = "Other";
export const H_REINDEX_BUTTON = "Reindex";

export const H_EMAIL_TITLE = "Email";
export const H_EMAIL_FROM_EMAIL = "Sender Email:";
export const H_EMAIL_FROM_NAME = "Sender Name:";
export const H_EMAIL_SMTP_DOMAIN = "Smpt Server:";
export const H_EMAIL_PORT = "Port:";
export const H_EMAIL_USERNAME = "User Name:";
export const H_EMAIL_USERPASSWORD = "Password:";
export const H_EMAIL_CLIENTID = "ClientId:";
export const H_EMAIL_SECRET = "Secret:";
export const H_EMAIL_TENANTID = "TenantId:";
export const H_EMAIL_TOKEN_URL = "Office365:";
export const H_EMAIL_OFFICE_SERVER = "Office365:";
export const H_EMAIL_SEND = "Send:";

export const H_INSIGHTLY_TITLE = "Insightly";
export const H_INSIGHTLY_LAMBDAACCESSKEYID = "Access Key:";
export const H_INSIGHTLY_LAMBDAREGIONENDPOINTNAME = "Region Name:";
export const H_INSIGHTLY_LAMBDASECRETKEY = "Secret Key:";
export const H_INSIGHTLY_LAMBDAWRITERNAME = "Writer Name:";
export const H_INSIGHTLY_QUEUEURL = "Queue Url:";

export const H_AWS_TITLE = "AWS";
export const H_AWS_ACCESSKEYID = "Access Key:";
export const H_AWS_REGIONENDPOINTNAME = "Region Name:";
export const H_AWS_S3BUCKETNAME = "S3 Bucket:";
export const H_AWS_SECRETKEY = "Secret Key:";
/**
 * Broker Builder
 */

export const BRO_NEW_TITLE = "Broker Detail";
export const BRO_SUBMIT_BUTTON = "Save";

export const BRO_PRIMARY_EMAIL_BUSY = "Broker with such email already exists";

export const BRO_EMAIL_TYPE_PRIMARY = "Primary";
export const BRO_EMAIL_TYPE_ALT = "Alternate";
export const BRO_EMAIL_TYPE_CC = "CC";
export const BRO_EMAIL_TYPE_REQUESTER = "Requester";

export const BRO_PHONE_TYPE_OFFICE = "Office";
export const BRO_PHONE_TYPE_CELL = "Cell";
export const BRO_PHONE_TYPE_FAX = "Fax";
export const BRO_PRIMARY_LABEL = "PRIMARY #";

export const BRO_CARD_TITLE = "Broker Production";
export const BRO_CARD_CARRIER = "Carrier";
export const BRO_CARD_YTD_TOTAL = "YTD Total";
export const BRO_CARD_NOTHING_YET = "Nothing yet";
export const BRO_CARD_TOTAL_LABEL = "Total";

export const BRO_INFO_TITLE = "BROKER INFORMATION";
export const BRO_PHONE_TITLE = "PHONE";
export const BRO_EMAIL_TITLE = "EMAIL";
export const BRO_ADDRESS_TITLE = "ADDRESS";
export const BRO_NOTES_TITLE = "BROKER NOTES";

export const BRO_FIRST_NAME_LABEL = "First Name";
export const BRO_LAST_NAME_LABEL = "Last Name";
export const BRO_PREF_NAME_LABEL = "Preferred Name";
export const BRO_AFFILIATE_PUBLIC_LABEL = "Broker entered";
export const BRO_AFFILIATE_LABEL = "Affiliate";
export const BRO_COMPANY_LABEL = "Company";
export const BRO_COMPANY_PUBLIC_LABEL = "Broker entered";
export const BRO_HOW_DID_YOU_HEAR_ABOUT_US_LABEL = "How did you hear about us?";
export const BRO_BROKER_ID_LABEL = "Broker ID";
export const BRO_EXTERNAL_ID_LABEL = " External ID";
export const BRO_BRANCH_LABEL = "Branch";
export const BRO_RBM_LABEL = "RBM";
export const BRO_BUSINESS_OWNER_LABEL = "Business Owner";
export const BRO_STREET_ADDRESS_LABEL = "Street Address";
export const BRO_CITY_LABEL = "City";
export const BRO_STATE_LABEL = "State";
export const BRO_ZIP_LABEL = "Zip";
export const BRO_NOTES_LABEL = "Saved Notes";

/*
 * Proposal Status
 */
export const PRO_STATUS_QA = "QA";
export const PRO_STATUS_SINGLE_CLASS = "Single Class";
export const PRO_STATUS_ASSIGNED = "Assigned";
export const PRO_STATUS_APP_SUBMITTED = "App Submitted";
export const PRO_STATUS_PENDING = "Pending";
export const PRO_STATUS_IN_FORCE = "In Force";
export const PRO_STATUS_VOID = "Void";

/*
 * Side Navigation
 */
export const NEW_AFFILIATE = "Affiliate";
export const NEW_COMPANY = "Company";
export const NEW_PROPOSAL = "Proposal";
export const NEW_PROPOSAL_ML = "ML/GSI";
export const NEW_BROKER = "Broker";
export const NAV_PROPOSALS = "Proposals";
export const NAV_BROKERS = "Brokers";
export const NAV_COMPANIES = "Companies";
export const NAV_AFFILIATES = "Affiliates";
export const NAV_USERS = "Users";
export const NAV_PRODUCT_BUILDER = "Product Builder";
export const NAV_SECURE_UPLOAD = "File Upload";
export const NAV_HEALTH_CHECK_UPLOAD = "Health Check";
export const PAGINATION_ROWS_PER_PAGE = "Rows per page";
export const PAGINATION_PAGE_STATUS = (rowStart: number, rowEnd: number, rowTotal: number) =>
  `${rowStart}-${rowEnd} of ${rowTotal}`;
export const NAV_LOGOUT = "Logout";
/**
 * Product Builder
 */
export const PB_STATUS_LABEL = "Status";

/**
 * Proposal Census Step
 */
export const CENSUS_TITLE = "CENSUS";
export const CENSUS_OPTIONS = "IDI DETAILS";

export const CENSUS_COLUNM_NAME = "Name";
export const CENSUS_COLUNM_DOB = "DOB";
export const CENSUS_COLUNM_GENDER = "Gender";
export const CENSUS_COLUNM_TOBACOUSAGE = "S/NS";
export const CENSUS_COLUNM_STATE = "State";
export const CENSUS_COLUNM_OCCUPATION = "Occupation";
export const CENSUS_COLUNM_INCOME = "Income";
export const CENSUS_COLUNM_BONUS = "Bonus";

/**
 * Proposal Details Overview ML
 */
export const PML_COMPANY_NAME_LABEL = "Company Name";
export const PML_COMPANY_TITLE = "COMPANY";

/**
 * Proposal Details Overview
 */
export const PDO_SPECIALIST_TITLE = "SPECIALIST";
export const PDO_BROKER_TITLE = "BROKER";
export const PDO_PRODUCT_TITLE = "PRODUCT";
export const PDO_CLIENT_TITLE = "CLIENT";
export const PDO_PROPOSAL_SOURCE_TITLE = "";

export const PDO_SPECIALIST_NAME_LABEL = "Proposal Specialist Name";
export const PDO_BROKER_NAME_LABEL = "Broker Name";
export const PDO_PREFERRED_BROKER_NAME_LABEL = "Preferred Broker Name";
export const PDO_BROKER_PRIMARY_PHONE_LABEL = "Primary Phone";
export const PDO_BROKER_COMPANY_LABEL = "Company";
export const PDO_BROKER_AFFILIATE_LABEL = "Affiliate";
export const PDO_BROKER_ID_LABEL = "Broker ID";
export const PDO_BROKER_EXTERNAL_ID_LABEL = "External ID";
export const PDO_BROKER_DETAILS_BUTTON = "View Broker Details";
export const PDO_PRODUCT_TYPE_LABEL = "";
export const PDO_CLIENT_FIRST_NAME_LABEL = "Client First Name";
export const PDO_CLIENT_LAST_NAME_LABEL = "Client Last Name";
export const PDO_ORIGINATION_LABEL = "Origination";
export const PDO_HOW_DID_LABEL = "How Did You Hear About Us?";
export const PDO_QUOTE_ENGINE = "Quote Engine";

/**
 * Proposal Details Broker Quotes
 */
export const PD_BROKER_QUOTES_LINKS_HEADER = "Broker quotes links";
export const PD_BROKER_SECURE_DOCUMENTS = "Documents Uploaded";

/**
 * Proposal Details QA
 */
export const PD_QA_HEADER = "QA tracker";

/**
 * Proposal Details Links to 5 Carrires
 */
 export const FIVE_CARRIER_HEADER = "Analyzer Links";

/**
 * Proposal Details Client
 */
export const PDC_CLIENT_INFORMATION_TITLE = "CLIENT INFORMATION";
export const PDC_CLIENT_FIRST_NAME_LABEL = "Client First Name";
export const PDC_CLIENT_LAST_NAME_LABEL = "Client Last Name";
export const PDC_PREFIX_LABEL = "Prefix";
export const PDC_CREDENTIALS_LABEL = "Credentials";
export const PDC_STATE_LABEL = "State";
export const PDC_DOB_LABEL = "Date of Birth";
export const PDC_YEARS_LABEL = "Years";
export const PDC_MONTHS_LABEL = "Months";
export const PDC_DAYS_LABEL = "Days";
export const PDC_GENDER_LABEL = "Gender";
export const PDC_HEIGHT_LABEL = "Height";
export const PDC_WEIGHT_LABEL = "Weight";

export const PDC_NCLIENT_PHONE = "Phone Number";
export const PDC_NCLIENT_EMAIL = "Email";

export const PDC_OCCUPATION_TITLE = "OCCUPATION";
export const PDC_OCCUPATION_NAME_LABEL = "Occupation Name";
export const PDC_DUTIES_LABEL = "Duties";
export const PDC_ANNUAL_INCOME_LABEL = "Annual Income";
export const PDC_BONES_LABEL = "Bonus";
export const PDC_PASSIVE_INCOME_LABEL = "Passive Income";
export const PDC_GOVERNMENT_LABEL = "Government";
export const PDC_GOVERNMENT_YEARS_LABEL = "Government Years";
export const PDC_BUSINESS_OWNER_LABEL = "Business Owner";
export const PDC_WORK_FROM_HOME_LABEL = "Work from Home";
export const PDC_YEARS_IN_BUSINESS_LABEL = "Years in Business";
export const PDC_EMPLOYEES_LABEL = "Employees";
export const PDC_GOVERNMENT_TYPE_LABEL = "Type";
export const PDC_PERCENTAGE_FROM_HOME = "% Work from Home";

export const PDC_TOBACCO_USAGE_TITLE = "TOBACCO USAGE";
export const PDC_TOBACCO_TYPE_LABEL = "Type";
export const PDC_TOBACCO_FREQUENCY_LABEL = "Frequency";
export const PDC_TOBACCO_LAST_USE_LABEL = "Last Use";
export const PDC_MEDICAL_NOTES_TITLE = "MEDICAL NOTES";
export const PDC_NAME_OF_CONDITION_LABEL = "Name of Condition";
export const PDC_DATE_OF_DIAGNOSIS_LABEL = "Date of Diagnosis";
export const PDC_TYPE_OF_TREATMENT_LABEL = "Type of Treatment";
export const PDC_MEDICATION_NAME_LABEL = "Medication Name";
export const PDC_DOSE_LABEL = "Dose";
export const PDC_FREQUENCY_LABEL = "Frequency";
export const PDC_ADD_MEDICATION_BUTTON = "ADD ANOTHER MEDICATION";
export const PDC_ADDITIONAL_NOTES_LABEL = "Additional Notes";
export const PDC_ADD_CONDITION_LABEL = "Add Another Condition";
export const PDC_SPOUSE_DETAILS_TITLE = "SPOUSE DETAILS";
export const PDC_SPOUSE_DETAILS_LABEL = "Spouse Details";
export const PDC_DELETE = "Delete";
export const PDC_EXISTING_COVERAGE = "EXISTING COVERAGE";
export const PDC_INDIVIDUAL_LABEL = "Individual Monthly Benefit";
export const PDC_REPLACE_EXISTING_LABEL = "Replace Existing";
export const PDC_GROUP_PERCENT_LABEL = "Group %";
export const PDC_GROUP_CAP_LABEL = "Group Cap";
export const PDC_PAID_BY_LABEL = "Paid By";

export const PDC_RESIDENCY = 'RESIDENCY';
export const PDC_RESIDENT_PROGRAM = "Resident Program";
export const PDC_END_DATE = "End Date";
export const PDC_CURRENT_ANNUAL_INCOME = "Current Annual Income";
export const PDC_STARTING_FELLOWSHIP = "Starting Fellowship";
export const PDC_FELLOWSHIP_PROGRAM = "Fellowship Program";
export const PDC_SIGNED_EMPLOYMENT_CONTRACT = "Signed Employment Contract Date";


export const PDC_YES = "Yes";
export const PDC_NO = "No";

export const BQW_CLIENT = "Client";
export const BQW_BROKER = "Broker";
export const CQW_COMPANY = "Company";
export const AQW_AFFILIATE = "Affiliate";

/**
 * Proposal Builder Steps
 */
export const PDS_OVERVIEW = "Overview";
export const PDS_CLIENT = "Client";
export const PDS_CLASSIFICATION = "Classification";
export const PDS_CASE_DESIGN = "Case Design";
export const PDS_ANALYZER = "Analyzer";
export const PDS_CENSUS = "Census";
export const PDS_EQUALIZER = "Equalizer";


/**
 * Proposal Container
 */
export const PC_NEW_TITLE = "New Proposal";
export const PC_EXISTING_TITLE = "Existing Proposal";
export const PC_DETAILS_TAB = "Details";
export const PC_DOCUMENTATION_TAB = "Proposals";
export const PC_CORRESPONDENCE_TAB = "Documentation";
export const PC_PROPOSAL_BUTTON = "Proposal";
export const PC_ANALYZER_BUTTON = "Analyzer";
export const PC_EQUALIZER_BUTTON = "Equalizer";
export const PC_SAVE_BUTTON = "Save";
export const PC_CREATE_BUTTON = "Create";
export const PC_NEXT_BUTTON = "Next";
export const PC_PREV_BUTTON = "Previous";
export const PC_SUBMIT_BUTTON = "Submit";
export const PC_REVISIONS = "Revisions";
export const PC_PROPOSAL_NOTES = "Proposal Notes";
export const PC_BROKER_NOTES = "Broker Notes";
export const PC_NOTE_DEFAULT_TITLE = "None Yet";
export const PC_NOTE_DEFAULT_DESC = "Data will appear here when available.";
export const PC_REVISION_DEFAULT_TITLE = "No Revisions";
export const PC_REVISION_DEFAULT_DESC = "Proposal Revisions will appear here.";
export const PC_NEW_NOTE = "New Note";
export const PC_DISCLAIMER = "Disclaimer";
export const PC_SAVE_DISCLAIMER_BUTTON = "Save";
export const PC_NEW_REVISION = "+";
export const PC_NEW_REVISION_WARNING = "New revision will be created. Are you sure?";

/**
 * Affiliates Table
 */

export const AFF_COL_NAME = "Affiliate Name";
export const AFF_COL_ADDRESS = "Address";
export const AFF_COL_BRANCH = "Branch";
export const AFF_COL_PHONE = "Phone";
export const AFF_COL_PRIMARY_CONTACT = "Primary Contact";
export const AFF_COL_AFFILIATE_ID = "Affiliate ID";
export const AFF_COL_EXTERNAL_ID = "External ID";
export const AFF_PAGE_TITLE = "Affiliates";

/**
 * Affiliates Form
 */

export const AFF_NAME_LABEL = "Affiliate Name";
export const AFF_BRANCH_TYPE_LABEL = "Branch Type";
export const AFF_RBM_LABEL = "RBM";
export const AFF_PHONE_LABEL = "Phone";
export const AFF_EMAIL_LABEL = "Email";
export const AFF_AFFILIATE_ID_LABEL = "Affiliate ID";
export const AFF_EXTERNAL_ID_LABEL = "External ID";
export const AFF_STREET_ADDRESS_LABEL = "Street Address";
export const AFF_CITY_LABEL = "City";
export const AFF_STATE_LABEL = "State";
export const AFF_ZIP_LABEL = "Zip";
export const AFF_CONTACT_ID_LABEL = "Contact ID";
export const AFF_FIRST_NAME_LABEL = "First Name";
export const AFF_LAST_NAME_LABEL = "Last Name";
export const AFF_IS_PRIMARY_LABEL = "Primary Contact";
export const AFF_ERROR_ONLY_1_PRIMARY = "Only one contact may be primary";
export const AFF_CONTACT_HEADER = "Contacts";
export const AFF_SUBMIT_BUTTON = "Save";
export const AFF_ADD_ALT_CONTACT = "Add Alternate Contact";
export const AFF_DETAIL_PAGE_TITLE = "Affiliate Detail";
export const AFF_DETAIL_CARD_TITLE = "Affiliate Information";
export const AFF_DETAIL_CARD_TITLE_PRI = "Primary Contact";
export const AFF_DETAIL_CARD_TITLE_AFF = "Alternate Contact";
export const AFF_DETAIL_CARD_TITLE4 = "address";
export const AFF_DETAIL_CARD_TITLE5 = "Companies";
export const AFF_COM_COL_COMPANY_NAME = "Company Name";
export const AFF_COM_COL_BRANCH = "Branch";
export const AFF_COM_COL_EMAIL = "Email";
export const AFF_COM_COL_PHONE = "Phone";
export const AFF_NOTES_LABEL = "Saved Notes";
export const AFF_NOTES_TITLE = "AFFILIATE NOTES";

/**
 * Companies Table
 */

export const CO_COL_NAME = "Company Name";
export const CO_COL_ADDRESS = "Address";
export const CO_COL_BRANCH = "Branch";
export const CO_COL_AFFILIATE = "Affiliate";
export const CO_COL_RBM = "RBM";
export const CO_COL_PHONE = "Phone";
export const CO_COL_EMAIL = "Email";
export const CO_COL_PRIMARY_CONTACT = "Primary Contact";
export const CO_COL_COMPANY_ID = "Company ID";
export const CO_COL_EXTERNAL_ID = "External ID";
export const CO_PAGE_TITLE = "Companies";

/**
 * Company Form
 */

export const CO_NAME_LABEL = "Company Name";
export const CO_AFFILIATE_LABEL = "Affiliate";
export const CO_FIRST_NAME_LABEL = "First Name";
export const CO_LAST_NAME_LABEL = "Last Name";
export const CO_COMPANY_ID_LABEL = "Company ID";
export const CO_EXTERNAL_ID_LABEL = "External ID";
export const CO_BRANCH_LABEL = "Branch";
export const CO_RBM_LABEL = "RBM";
export const CO_PHONE_LABEL = "Phone";
export const CO_EMAIL_LABEL = "Email";
export const CO_STREET_ADDRESS_LABEL = "Street Address";
export const CO_CITY_LABEL = "City";
export const CO_STATE_LABEL = "State";
export const CO_ZIP_LABEL = "Zip";
export const CO_SUBMIT_BUTTON = "Save";
export const CO_DETAIL_PAGE_TITLE = "Company Detail";
export const CO_SECOND_TITLE = "Company Information";
export const CO_THIRD_TITLE = "Primary Contact";
export const CO_FOURTH_TITLE = "Address";
export const CO_ADD_AFFILIATE_BUTTON = "Add Affiliate";
export const CO_AFFILIATE_DETAILS_BUTTON = "Affiliate Details";
export const CO_NO_AFF_RESULTS = "No Results Found";

/**
 *
 * Products
 */

export const PROD_COL_PRODUCT_TYPE = "Product";
export const PROD_COL_CARRIER_TYPE = "Carrier";
export const PROD_COL_OCC_CLASS_TYPE = "OCC Class";
export const PROD_COL_DESIGN_TYPE = "Design";
export const PROD_COL_RENEWABILITY_TYPE = "Renewability";
export const PROD_COL_BP_TYPE = "BP";
export const PROD_COL_EP_TYPE = "EP";
export const PROD_COL_OWN_OCC_TYPE = "Own OCC";
export const PROD_COL_RESIDUAL_TYPE = "Residual";
export const PROD_COL_AIR_TYPE = "AIR";
export const PROD_COL_COLA_TYPE = "COLA";
export const PROD_COL_PRODUCT_ID = "ID";
export const PROD_DELETE_BUTTON = "Delete";

/**
 * Medical Notes
 */
export const MN_CONDITION_NAME_LABEL = "Name of Condition";
export const MN_DATE_OF_DIAGNOSIS_LABEL = "Date of Diagnosis";
export const MN_TYPE_OF_TREATMENT_LABEL = "Type of Treatment";
export const MN_MEDICATIONS_TAKEN_LABEL = "Medications Taken";
export const MN_MEDICATION_NAME_LABEL = "Medication Name";
export const MN_DOSE_LABEL = "Dose";
export const MN_FREQUENCY_LABEL = "Frequency";
export const MN_ADD_MEDICATION_BUTTON = "ADD ANOTHER MEDICATION";
export const MN_ADDITIONAL_NOTES_LABEL = "Additional Notes";
export const MN_ADD_CONDITION_LABEL = "Add Another Condition";

/**
 * Modal
 */
export const MOD_DIALOG_LEAVING = "You have made changes to this proposal without saving them.";

/**
 * Classifications Step
 */
export const PBC_CARRIER_LABEL = "Carrier";
export const PBC_OCC_LABEL = "Occupation Class";
export const PBC_TITLE = "DISABILITY INSURANCE PRODUCT CLASSIFICATION DETAILS";
export const PBC_FIVE_CARRIERS_CHECKBOX = "TruSpecialty";
export const PBC_FIVE_CARRIERS_CHECKBOX_DIS = "DIS";
export const PBC_MAX_CARRIER_ACHIEVED = "Max allowed carriers amount achieved";
export const PBC_PRODUCT_LABEL = "Product";
export const PBC_ADD_COLUMN_BUTTON = "Add Column";
export const PBC_DELETE = "Delete";

/**
 * Case Design
 */
export const PBCD_PRODUCT_SELECTION_TITLE = "PRODUCT SELECTION";
export const PBCD_PRODUCT_TYPE_LABEL = "Product Type";
export const PBCD_BP_LABEL = "BP";
export const PBCD_EP_LABEL = "EP";
export const PBCD_PAYOR_LABEL = "Payor";
export const PBCD_BENEFIT_AMOUNT = "Benefit Amount";
export const PBCD_ADDITIONAL_CLIENT_NOTE = "Additional client or case design info";

/**
 * Analyzer
 */
export const ANL_BASE_BENEFIT_LABEL = "Base Benefit";
export const ANL_OCC_CLASS_LABEL = "Occ Class";
export const ANL_SSIB_LABEL = "SSIB";
export const ANL_TOTAL_BENEFIT_LABEL = "Total Benefit";
export const ANL_RENEWABILITY_LABEL = "Renewability";
export const ANL_BP_LABEL = "BP";
export const ANL_EP_LABEL = "EP";
export const ANL_OWN_OCC_LABEL = "Own Occ";
export const ANL_RESIDUAL_LABEL = "Residual";
export const ANL_MINIMUM_RESIDUAL_LABEL = "Minimum Residual Benefit Payable";
export const ANL_FUTURE_INSURABILITY_LABEL = "Future Insurability Options";
export const ANL_AIR_LABEL = "AIR";
export const ANL_COLA_LABEL = "COLA";
export const ANL_MENTAL_NERVOUS_LABEL = "Mental Nervous Limitation";
export const ANL_CATASTROPHIC_LABEL = "Catastrophic";
export const ANL_CATASTROPHIC_AMOUNT_LABEL = "Amount";
export const ANL_UNIQUE_PROVISION_LABEL = "Unique Provision";
export const ANL_AP_LABEL = "Annual Premium";
export const ANL_POTENTIAL_BENEFIT_LABEL = "Potential Benefit Payout";
export const ANL_MONTHLY_LABEL = "Monthly Premium";
export const ANL_SAVE_BUTTON = "Save Page";
export const ANL_COST_PER_100_LABEL = "Cost per 100 of Benefit ";

/**
 * Documentation
 */
export const DOC_DOCUMENTS_TITLE = "DOCUMENTS ASSOCIATED WITH PROPOSAL";
export const DOC_ADD_DOCUMENT_BUTTON = "Add Document(s)";
export const EQ_DOC_ADD_DOCUMENT_BUTTON = "Add documents - *.csv files only";
export const DOC_ATTACH_COL = "Attach";
export const DOC_NAME_COL = "Name";
export const DOC_TYPE_COL = "Type";
export const DOC_DOWNLOAD_COL = "Download";
export const DOC_DELETE_COL = "Delete";
export const DOC_MODIFIED_BY_COL = "Modified By";
export const DOC_MODIFIED_DATE_COL = "Modified";
export const DOC_SEND_DOCUMENTATION_TITLE = "SEND DOCUMENTATION";
export const DOC_DEFAULT_RECIPIENTS = "Default Recipients:";
export const DOC_OTHER_EMAIL = "Other Email:";
export const DOC_SUBJECT = "Subject:";
export const DOC_BODY_TITLE = "Email Body:";
export const DOC_SMS_TITLE = "SMS Body:";
export const DOC_SEND_BUTTON = "Send";
export const DOC_SEND_WITHOUT_REVISION = "Send without revision";
export const DOC_FILE_UPLOAD_SUCCESS_TOAST = "File uploaded successfully!";
export const DOC_DOWNLOAD_BUTTON = "Download";
export const DOC_OPEN_BUTTON = "Open";
export const DOC_DELETE_BUTTON = "Delete";
export const DOC_CONF_MODAL_TITLE = "Documentation Successfully Sent";
export const DOC_CONF_FAIL_MODAL_TITLE = "Documentation not sent";
export const DOC_CONF_MODAL_REV_NOTE = "Confirmation will be noted in the Revisions section";
export const DOC_CONF_MODAL_REV_FAIL_NOTE = "Confirmation could not be noted in the Revisions section";
export const DOC_CONF_MODAL_BUTTON = "Continue";
export const DOC_EMAIL_VALIDATION_FAIL_TOAST =
  "Please ensure the email contains an attachment and a valid recipient";
export const DOC_DELETE_CONFIRM_TEXT = "Are you sure you want to delete this document?";
export const DOC_DELETE_YES_BUTTON = "Yes";
export const DOC_DELETE_NO_BUTTON = "No";

/*
 * SecureUpload Table
 */
export const SU_ID = "Id";
export const SU_AUTHOR = "Broker";
export const SU_CATEGORY = "Category";
export const SU_FILE = "File";
export const SU_NOTE = "Note";
export const SU_UPLOAD_DATE = "Upload Date";
export const SU_DELETED = "deleted";
export const SU_BROKER_FILE_COLUMN = "File";
export const SU_BROKER_TIME_COLUMN = "Uploaded";
export const SU_BROKER_NO_FILES_UPLOADED = "no files uploaded";




/**
 * Upload Document Modal
 */
export const UDM_TITLE = "Upload Document";
export const UDM_CANCEL_BUTTON = "Cancel";
export const UDM_UPLOAD_BUTTON = "Upload";
export const UDM_DEFAULT_FILE_TYPE = "Document Type";
export const UDM_SEND_SMS = "Send SMS";
export const UDM_DROPZONE = "Drag 'n' drop or click to select file.";
export const UDM_DELETE_BUTTON = "Delete";

export const EQ_GLTD_PLAN_DETAILS = "GLTD Details";
export const EQ_DISCOUNT_RATE_LABEL = "Discount Rate";
export const EQ_BENEFIT_CAP_LABEL = "GLTD Benefit Cap";
export const EQ_PERCENT_CAP_LABEL = "GLTD % Cap";
export const EQ_GROUP_CARIER_LABEL = "GLTD Carrier";
export const EQ_PERCENT_CAP_OTHER_LABEL = "Other";
export const EQ_BENEFIT_CAP_OTHER_LABEL = "Other";
export const EQ_GROUP_CARIER_OTHER_LABEL = "Other";
export const EQ_TWO_CARIER = "Supplemental Lloyd's Benefit";
export const EQ_FORCE_USER_COMPLETE_EDIT = "Please complete table edit first.";

export const P_OVERVIEW_PROMPT_MODAL_TITLE = "Product type critical change!";
export const P_OVERVIEW_PROMPT_MODAL_CONFIRM_BUTTON = "OK";
export const P_OVERVIEW_PROMPT_MODAL_REJECT_BUTTON = "Reject";

/**
 * Form Modal
 */
export const FM_DEFAULT_SAVE = "Save";
export const FM_DEFAULT_CANCEL = "Cancel";

/**
 * Accordion
 */
export const AC_EDIT_BUTTON = "Edit";

/**
 * Toast
 */
export const T_BUTTON = "CLOSE";

/*
 * Dropdown Builder Labels
 */
export const DD_BUILDER_LABEL_BRANCH = "";
export const DD_BUILDER_LABEL_HOWDIDYOUHEARABOUTUS = "";
export const DD_BUILDER_LABEL_COMPANY = "";
export const DD_BUILDER_LABEL_RBM = "RBM";
export const DD_BUILDER_LABEL_BUSINESS_OWNER = "Business Owner";

/*
 *
 */
export const REV_ITEM_SENT = (date: any, time: any, zone: any) =>
  `Sent on ${date} at ${time} ${zone}`;
export const REV_NEW_VALUE_LABEL = "New Value:";
export const REV_OLD_VALUE_LABEL = "Old Value:";
