import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { get } from "lodash";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import { IBroker } from "../../store/models/broker.model";
import { fetchBrokersList } from "../../store/reducers/brokers";

import DisTable from "../../components/Table/Table";
import SearchFieldTool from "../../components/Table/SearchField";

interface IBrokersProps extends StateProps, DispatchProps, RouteComponentProps {}

class Brokers extends React.Component<IBrokersProps> {
  render() {
    const {
      fetchBrokersList,
      brokers: { map, projection, total, listOptions }
    } = this.props;
    const columns = [
      {
        key: "brokerName", 
        title: S.BRO_COL_NAME,
        render: item => {
          if (item.fromBrokerQuoteEngine) {
            return (
              <div className="fromBrokerQuoteEngine">
                {item.brokerName}
              </div>
            );
          } else {
            return item.brokerName;
          }
        },
        style: { minWidth: '12rem', width: '29rem' },
        sortKey: "BrokerName"
      },
      {
        key: "primaryPhone", 
        title: S.BRO_COL_PRIMARY_PHONE,
        style: { minWidth: '12rem' }
      },
      {
        key: "primaryEmail", 
        title: S.BRO_COL_PRIMARY_EMAIL,
        style: { minWidth: '12rem', width: '29rem', paddingRight: '1rem' }
      },
      {
        key: "companyName",
        title: S.BRO_COL_COMPANY,
        style: { minWidth: '12rem' },
        sortKey: "CompanyName"
      },
      {
        key: "affiliateName", 
        title: S.BRO_COL_AFFILIATE,
        style: { minWidth: '12rem' }
      }
    ];
    return (
      <div className="table-view__content">
        <h1 className="heading1 grey--light">{S.BRO_PAGE_TITLE}</h1>
        <DisTable<IBroker>
          columns={columns}
          initialState={{ rows: 25, map, projection, total, ...listOptions }}
          onRowClick={(data: IBroker) => {
            this.props.history.push(`/brokers/${data.brokerID}`);
          }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchBrokersList({ page, rows, sort, query });
            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as IBroker[]
            };
          }}>
          <SearchFieldTool />
        </DisTable>
      </div>
    );
  }
}

const mapStateToProps = ({ brokers }: IRootState) => ({ brokers });
const mapDispatchToProps = { fetchBrokersList };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Brokers);
