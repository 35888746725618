import { IBroker } from "../models/broker.model";
import ResourceFactory from "./resource";

const ACTION_TYPES = {
    FETCH_OPTIONS: "companies/FETCH_OPTIONS"
  };

const resourceKey = "brokers";
const resourceUrl = "brokers";
const resourceIdKey = "brokerID";

const { reducer, fetchList, create, fetch, update, hideErrorToast } = ResourceFactory<IBroker>(resourceKey, resourceUrl, resourceIdKey);

export type BrokerResourcesState = ReturnType<typeof reducer>;
export const fetchBrokersList = fetchList;
export const createBroker = create;
export const fetchBroker = fetch;
export const updateBroker = update;
export const hideErrorToastBroker = hideErrorToast;

export default reducer;
