/**
 *  utils.dates.ts
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Thu May  21 13:20:42 2019
 */
import moment from "moment";

/**
 * Convert an iso 8601 formatted date string to a YYYY-MM-DD date string
 * accepted by an html form input.
 * @param {string} input - iso 8601 formatted string
 */
export const iso8601ToHtmlInput = (input: string): string => {
  if (input) {
    const m = moment(input);
    const formatted = m.format("YYYY-MM-DD");
    return formatted;
  } else {
    return "";
  }
};

export const iso8601ToShort = (input: string): string => {
  if (input) {
    const m = moment(input);
    const formatted = m.format("MM-DD-YYYY");
    return formatted;
  } else {
    return "";
  }
};

export const iso8601ToCustomFormat = (input: string, format: string): string => {
  if (input) {
    const m = moment(input);
    const formatted = m.format(format);
    return formatted;
  } else {
    return "";
  }
};
