import * as React from "react";
import { connect, memoize } from "react-redux";
import { RouteComponentProps, Link } from "react-router-dom";
import { Form as FinalForm, FormRenderProps } from "react-final-form";
import { Prompt } from "react-router-dom";
import Toast from "../../../../../components/Toast/Toast";

import { Container, Row, Col, Spinner } from "reactstrap";
import { set, get } from "lodash";
import axios from "axios";
import arrayMutators from "final-form-arrays";

import proposalCensus, {
  getUploadedDocument
} from "../../../../../store/reducers/proposalCensus";

import { IRootState } from "../../../../../store/reducers";
import { fetchBrokerDetails } from "../../../../../store/reducers/proposalOptions";

import { IProposal } from "../../../../../store/models/proposal.model";
import { saveSymbol, stepSymbol, stepIndexSymbol } from "../Regular/ProposalBuilder";
import {
  updateCensusCellData,
  forceUserCompleteEdit,
  flushCensusDataChanges
} from "../../../../../store/reducers/proposalCensus";


import { saveProposal, submitProposal } from "../../../../../store/reducers/proposalOptions";
import * as S from "../../../../../constants/StringConstants";
import "./EqualizerML.scss";
import SingleCarrierGrid from './Carriers/SingleCarrierGrid';
import TwoCarrierGrid from './Carriers/TwoCarrierGrid';

export interface IEqualizerMLProps
  extends RouteComponentProps,
    StateProps,
    DispatchProps {
  proposalID: any;
  proposal: IProposal;
  next: (values: any) => void;
  previous: (values: any) => void;
  generatePDF: (values: any) => void;
  step: (values: any, pageIndex: number) => void;
}

class EqualizerML extends React.Component<IEqualizerMLProps> {
  componentDidMount() {
    const { proposal } = this.props;

    this.props.getUploadedDocument(`${proposal.proposalID}`);
  }

  handleSubmit = values => {
    const { next, previous, generatePDF, step } = this.props;
    const { formAction, ...rest } = values;

    if (window[saveSymbol]) {
      generatePDF(rest);
      window[saveSymbol] = false;
    } else if (window[stepSymbol]) {
      step(rest, window[stepIndexSymbol]);
      window[stepSymbol] = false;
    } else {
      if (formAction === "next") {
        next(rest);
      } else {
        previous(rest);
      }
    }
  };

  handleValidate = values => {
    const errors = {};

    return errors;
  };

  canSaveProceed(){
    var editExists = false;
    const form = document.getElementById(`proposal-builder-form-3`);
    if (form){
      var elements = form.getElementsByClassName("MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit");
      
      for(var i=0; i<elements.length; i++){
        var editExists = elements[i].children[0].textContent === "edit";
        if (editExists)
          break;
      }
    }

    return editExists;
  }

  render() {
    const {
      proposal,
      isSubmitting,
      isSavingNext,
      isSavingPrevious,
      isForceUserCompleteEditVisible
    } = this.props;
    
    return (
      <div>
      <Toast
        message={S.EQ_FORCE_USER_COMPLETE_EDIT}
        onClose={() => {
          this.props.forceUserCompleteEdit(false);
        }}
        open={isForceUserCompleteEditVisible}
      />
      <FinalForm
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={proposal}
        mutators={{ ...arrayMutators }}>
        {formProps => {
          const formValues = formProps.values;
          const isTwoCarrier = proposal.twoCarrier
          return (
            <form
              className="overview__form-wrap"
              onSubmit={formProps.handleSubmit}
              id={"proposal-builder-form-3"}>
              <Prompt when={this.props.censusDataDirty && !formProps.submitting} message={S.MOD_DIALOG_LEAVING} />
              <Container fluid={isTwoCarrier}>
                <Row>
                  <Col>
                   {isTwoCarrier
                    ? <TwoCarrierGrid proposalID={proposal.proposalID} />
                    : <SingleCarrierGrid proposalID={proposal.proposalID} />
                   }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    &nbsp;
                  </Col>
                </Row>
              </Container>
              <div className="buttons buttons__wrap">
                  <button
                    className="button__orange buttons__display buttons__mr-20"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={e => {
                      var editExists = this.canSaveProceed();
                      if (editExists){
                        this.props.flushCensusDataChanges(`${this.props.proposalID}`);
                        formProps.form.change("formAction", "previous");
                      }
                      else{
                        e.preventDefault();
                        this.props.forceUserCompleteEdit(true);
                        return;
                      }
                    }}>
                    {isSavingPrevious || isSavingNext ? <Spinner color="white" /> : S.PC_PREV_BUTTON}
                  </button>
                  <div className="overview__next-button buttons__display">
                    <button
                      className="button__orange"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={e => {
                        var editExists = this.canSaveProceed();
                        if (editExists){
                          this.props.flushCensusDataChanges(`${this.props.proposalID}`);
                          formProps.form.change("formAction", "next");
                        }
                        else{
                          e.preventDefault();
                          this.props.forceUserCompleteEdit(true);
                          return;
                        }
                      }}>
                      {isSavingPrevious || isSavingNext ? <Spinner color="white" /> : S.PC_SAVE_BUTTON}
                    </button>
                  </div>
                </div>
            </form>
          );
        }}
      </FinalForm>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  overviewOptions: state.proposalOptions.overviewOptions,
  proposalSourceOptions: state.proposalOptions.overviewOptions.selectOriginList,
  proposalHearAboutUsOptions: state.proposalOptions.overviewOptions.selectHearAboutUsList,
  isSubmitting: state.proposalOptions.isSubmitting,
  isSavingNext: state.proposalOptions.isSavingNext,
  isClassificationLoaded: state.proposalOptions.isClassificationLoaded,
  isSavingPrevious: state.proposalOptions.isSavingPrevious,
  isForceUserCompleteEditVisible: state.proposalCensus.isForceUserCompleteEditVisible,
  censusDataDirty: state.proposalCensus.censusDataDirty
});

const mapDispatchToProps = { 
  fetchBrokerDetails, 
  saveProposal, 
  submitProposal,
  updateCensusCellData,
  
  getUploadedDocument,
  forceUserCompleteEdit,
  flushCensusDataChanges
 };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const Overview = connect(
  mapStateToProps,
  mapDispatchToProps
)(EqualizerML);

export default Overview;
