/**
 * util.fileService.ts
 * Disability Insurance Services
 *
 * Created by Jeremy Moyers
 */

/**
 * Create a file named fileName of type mime with the specified data.
 * @param fileName - The file's name.
 * @param mime - The Mime type passed to the Blob structure. ex "application/pdf"
 * @param data - The binary data to be written to the file.
 */
export const saveToDownloads = (fileName: string, mime: string, data: any) => {
  var downloadLink = document.createElement("a");
  downloadLink.target = "_blank";
  downloadLink.download = fileName;

  const blob = new Blob([data], { type: mime });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
    return;
  }

  const downloadUrl = window.URL.createObjectURL(blob);

  downloadLink.href = downloadUrl;

  document.body.appendChild(downloadLink);

  downloadLink.click();

  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadUrl);
};
