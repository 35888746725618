import React from "react";
import { get } from "lodash";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import * as S from "../../../../constants/StringConstants";
import { BASE_API_URL } from "../../../../constants/EnvConstants";
import { IRootState } from "../../../../store/reducers";
import "./AnalyzerLinks.scss"

export interface IAnalyzerLinksProps extends StateProps, DispatchProps, RouteComponentProps {}
interface IAnalyzerLinksState { }

class AnalyzerLinks extends React.Component<IAnalyzerLinksProps> {

  onCopyButtonClick(url) {
    navigator.clipboard.writeText(url);
  }

  render() {
    const { questionAnswersStatus } = this.props;

    const proposalID = get(this.props.match.params, "id", "new");
    var questionsAnswersTracker = questionAnswersStatus.map[proposalID];
    if(!questionsAnswersTracker){
      questionsAnswersTracker = new Array();
    }

    var url = `${BASE_API_URL}proposals/submissions/html/${proposalID}?type=Analyzer`;

    return (
      <Container className="bootsrap-container-override">
        <div className="heading3 proposal__card-title">{S.FIVE_CARRIER_HEADER}</div>
        <Row>
            <Col className="trackItem" >
              <a href={url} className="link" target="__blank">
                Latest Analyzer&nbsp;
              </a>
            </Col>
            <Col className="trackItemCopy">
              <span className="pointer material-icons MuiIcon-root" onClick={() => this.onCopyButtonClick(url)} >
                content_copy
              </span>
            </Col>
          </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  questionAnswersStatus: state.questionAnswersStatus
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
   connect(
      mapStateToProps,
      mapDispatchToProps
    )(AnalyzerLinks)
);
