import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { IListOption } from "../../../store/models/listOption.models";
import { defaultOptionValue } from "../../../util/utils.defaultValues";
import "./SingleSelectField.scss";

/**
 * @param {string} label - user facing string used for the title of the Select.
 * @param {Array<IListOption>} - options presented in the Select to the user.
 */
export interface ISelectProps<El extends HTMLSelectElement> extends FieldProps<any, El> {
  label?: string;
  options: Array<IListOption>;
}

export interface ISelectRenderProps
  extends ISelectProps<HTMLSelectElement>,
    FieldRenderProps<any, HTMLSelectElement> {}

/**
 * The standard select component.
 *
 * Displays a list of IListOption objects.
 */
export class SingleSelect extends React.Component<ISelectRenderProps> {
  render() {
    const { label, options, input, meta, disabled, isRequired, selected, ...rest } = this.props;

    return (
      <div
        className={
          disabled
            ? "single-select-field-disabled"
            : meta.touched && meta.error
            ? "single-select-field-error"
            : "single-select-field"
        }>
        <ul>
          <li>
            <label>{label}</label>
            {isRequired ?
              <label className="required">&nbsp;*</label>
              : ''
            }
          </li>
          <li>
            <select className="field-input" {...input} disabled={disabled}>
              {options.map(item => (
                <option key={item.value} value={item.value} disabled={item.disabled}>
                  {item.key}
                </option>
              ))}
            </select>
          </li>
          {meta.touched && meta.error && <span>{meta.error}</span>}
        </ul>
      </div>
    );
  }
}

// HTMLSelectElement

export interface ISelectFieldProps
  extends ISelectProps<HTMLSelectElement>,
    FieldProps<any, HTMLSelectElement> {}

export default function SingleSelectField(props: ISelectFieldProps) {
  return (
    <Field
      component={SingleSelect}
      parse={(value, name) => {
        const id = parseInt(value, 10);
        /*
        This was once a way to avoid having to filter the default value out of every
        model to DTO conversion. However, in order to differentiate between deleting
        an analyzer column and selecting a default value from the dropdown we need to
        allow the default Option value to persist. A possible solution is to add a
        'doesFilterDefaultValues' prop but for now were just gonna filter default
        values in every dto conversion.
        if (id === defaultOptionValue) {
          return undefined;
        }
        */
        return id;
      }}
      {...props}
    />
  );
}
