import React, { useEffect } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col, Spinner } from "reactstrap";
import { saveToDownloads } from "../../util/util.fileService";
import InnerHTML from 'dangerously-set-html-content'
import { IRootState } from "../../store/reducers";
import "./FileRef.scss";
import { downloadPublicDocument } from "../../store/reducers/secureUpload";

export interface IFileRefProps extends StateProps, DispatchProps, RouteComponentProps {}
export interface IFileRefState {
  template: { __html: string };
}
export class FileRef extends React.Component<IFileRefProps, IFileRefState> {
  constructor(props) {
    super(props);
    this.state = {
      template: { __html: "" },
    };
  }

  downloadDocument = async (proposalID: string, documentID: string) => {
    const result = await this.props.downloadPublicDocument(proposalID, documentID);
    const fileName = get(result, "value.headers.x-attachment-filename");
    const data = get(result, "value.data");
    const staticHTML = await data.text();

    this.setState({
      template: {
        __html: staticHTML
      }
    })
  };

  componentDidMount() {
    const { pid, did } = this.props.match.params as any;
    if (pid && did) {
      this.downloadDocument(pid, did);
    }
  }

  render() {
    return (
      <Container fluid className="print-container">
        {this.state.template.__html ? (
          <InnerHTML html={this.state.template.__html } />
          // <span dangerouslySetInnerHTML={this.state.template} />
        ) : (
          <><h1>Your DIS Analyzer will download shortly.....</h1>
          <h3>You may need to refresh this page if it doesn't load in 30 seconds.</h3></>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({
  SecureUploadState,
  proposalOptions: { statusList },
}: IRootState) => ({});

const mapDispatchToProps = {
  downloadPublicDocument,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FileRef);
