import React, { Fragment } from "react";
import { Form as FinalForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { FORM_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import { connect } from "react-redux";
import { get, set, omit } from "lodash";
import { Container, Row, Col, Button, Spinner } from "reactstrap";

import { IRootState } from "../../store/reducers";
import { createAffiliate, fetchAffiliate, updateAffiliate, hideErrorToastAffiliate } from "../../store/reducers/affiliates";
import { fetchAffiliateOptions } from "../../store/reducers/affiliateBuilder";

import * as S from "../../constants/StringConstants";
import TextField from "../../components/Form/TextField/TextField";
import NumberFormatField from "../../components/Form/NumberFormatField/NumberFormatField";
import SingleSelectField from "../../components/Form/SingleSelectField/SingleSelectField";
import { RouteComponentProps } from "react-router";
import "./AffiliateBuilder.scss";

import formatString from "format-string-by-pattern";
import * as P from "../../util/utils.validators";

import DisTable from "../../components/Table/Table";
import { ICompany } from "../../store/models/company.model";
import { addDefaultOption, undefinedIfDefault } from "../../util/utils.defaultValues";
import { uploadAffiliateLogo, saveAffiliateLogo, deleteAffiliateLogo } from '../../store/reducers/affiliatesImages'
import { Icon } from '@material-ui/core';
import { BASE_API_URL } from "../../constants/EnvConstants";
import AffiliateQuotesWidjet from './AffiliateQuotesWidjet';
import Toast from "../../components/Toast/Toast";

interface IAffiliateBuilderProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

class AffiliateBuilder extends React.Component<IAffiliateBuilderProps> {
  validate = (values: { [x: string]: any; contacts: any[] }) => {
    if (Object.keys(values).length === 0) {
      // early exit to avoid validating a form that hasn't recieved default values yet
      return;
    }

    const errors = {};

    if (P.isEmpty(values.name)) {
      errors["name"] = S.FORM_FIELD_REQUIRED;
    }

    // if (!values.branchTypeID || values.branchTypeID < 0) {
    //   errors["branchTypeID"] = S.FORM_FIELD_REQUIRED;
    // }

    // if (!values.phoneNumber) {
    //   errors["phoneNumber"] = S.FORM_FIELD_REQUIRED;
    // }

    // if (!values.email) {
    //   errors["email"] = S.FORM_FIELD_REQUIRED;
    // }

    // if (values.address && !values.address.streetAddress) {
    //   set(errors, "address.streetAddress", S.FORM_FIELD_REQUIRED);
    // }

    // if (values.address && !values.address.city) {
    //   set(errors, "address.city", S.FORM_FIELD_REQUIRED);
    // }

    // if (values.address && !values.address.stateID) {
    //   set(errors, "address.stateID", S.FORM_FIELD_REQUIRED);
    // }

    // const externalID = get(values, "externalID");
    // if (externalID && !P.EXTERNAL_ID_REGEX.test(externalID)) {
    //   set(errors, "externalID", S.FORM_FIELD_INVALID);
    // }

    const zipCode = get(values, "address.zip");
    const zipPattern = P.ZIP_CODE_REGEX;
    if (zipCode && !zipPattern.test(zipCode)) {
      set(errors, "address.zip", S.FORM_FIELD_INVALID_ZIPCODE);
    }

    // if (!values.contacts || values.contacts.length == 0) {
    //   errors["contacts"] = S.FORM_FIELD_REQUIRED;
    // } else if (values.contacts) {
    //   const primaryIndex = values.contacts.findIndex(c => c.isPrimary);
    //   if (primaryIndex == -1) {
    //     errors[FORM_ERROR] = S.AFF_ERROR_ONLY_1_PRIMARY;
    //   } else {
    //     const primary = values.contacts[primaryIndex];
    //     if (P.isEmpty(primary.firstName)) {
    //       const firstNamePath = `contacts.${primaryIndex}.firstName`;
    //       set(errors, firstNamePath, S.FORM_FIELD_REQUIRED);
    //     }
    //     if (P.isEmpty(primary.lastName)) {
    //       const lastNamePath = `contacts.${primaryIndex}.lastName`;
    //       set(errors, lastNamePath, S.FORM_FIELD_REQUIRED);
    //     }
    //   }
    // }

    // const phoneNumber = get(values, "phoneNumber");
    // const pattern = P.PHONE_NUMBER_REGEX;
    // if (!pattern.test(phoneNumber)) {
    //   set(errors, "phoneNumber", S.FORM_FIELD_INVALID);
    // }

    return errors;
  };

  handleSubmit = async (values: any) => {
    try {
      if (this.affiliateID) {
        await this.props.updateAffiliate(values);
      } else {
        // passing a 0 for ID seems to break saving of this
        if (values.affiliateID === 0) {
          delete values.affiliateID;
        }
        values.branchTypeID = undefinedIfDefault(values.branchTypeID);
        if (values.address) {
          values.address.stateID = undefinedIfDefault(values.address.stateID);
        }
        await this.props.createAffiliate(values);
      }
    } catch (err) {
      return { [FORM_ERROR]: "Error Submitting" };
    }

    // TODO: Get this path from a library/directory
    this.props.history.push("/affiliates/all");
  };

  componentDidMount() {
    this.props.fetchAffiliateOptions();

    // check match for id param
    const id = this.affiliateID;
    if (id) {
      this.props.fetchAffiliate(id.toString());
    }
  }

  get affiliateID() {
    const id = parseInt(this.props.match.params.id, 10);

    return id && !isNaN(id) ? id : null;
  }

  getContactTitle(contacts: any[], index: number) {
    const contact = contacts[index];
    return contact && contact.isPrimary ? S.AFF_DETAIL_CARD_TITLE_PRI : S.AFF_DETAIL_CARD_TITLE_AFF;
  }

  onAffiliateFileSelectionChange = ({ target }) => {
    const fileReader = new FileReader();
    const name = target.accept.includes('image') ? 'images' : 'videos';

    var formData = new FormData();
    formData.append("image", target.files[0]);

    fileReader.onload = (e) => {
      this.props.saveAffiliateLogo(this.affiliateID, formData, e.target.result);
      this.props.uploadAffiliateLogo(this.affiliateID, formData);
    };
    fileReader.readAsDataURL(target.files[0]);
  };

  onAffiliateFileDelete(affiliateID: number) {
    const { affiliates } = this.props;
    const affiliate = affiliates.map[affiliateID];
    affiliate.affiliateLogo = undefined;
    this.props.deleteAffiliateLogo(affiliateID);
  }

  render() {
    const { dropdownOptions, affiliates, affiliateImages, auth, hideErrorToastAffiliate } = this.props;
    const phoneMask = { name: "phone-1", parse: "(999) 999-9999" };
    const zipMask = { name: "address.zip", parse: "00000" };

    // if there's an id then we should be editing
    const id = this.affiliateID;
    const affiliate = affiliates.map[id];

    const defaultValues = {
      branchTypeID: undefined,
      affiliateID: 0,
      externalID: 0,
      contacts: [
        {
          isPrimary: true
        }
      ],
      address: {}
    };

    const companies = affiliate ? affiliate.companies : [];
    const branchList = addDefaultOption("", get(dropdownOptions, "branchList", [])) || [];
    const rbmList = addDefaultOption("", get(dropdownOptions, "rbmList", [])) || [];
    const stateList = addDefaultOption("", get(dropdownOptions, "selectStateList", []));

    const initialValues = affiliate
      ? omit(affiliate, ["companies", "selectBranchList"])
      : defaultValues;

    var logoAffiliateUrl = undefined;
    var image = affiliateImages.images.find(x => x.affiliateID == this.affiliateID);
    if(image){
      logoAffiliateUrl = image.imagePreview;
    }
     else {
      if(affiliate && affiliate.affiliateID && affiliate.affiliateLogo){
        logoAffiliateUrl = BASE_API_URL + "affiliates/" + affiliate.affiliateLogo + "/affiliateLogo";
      }
    }
    
    return (
      
      <div>
        <Toast
          message={affiliates.errorMessage}
          onClose={() => {
            this.props.hideErrorToastAffiliate();
          }}
          open={affiliates.error}
        />
        <React.Fragment>
          <div>
            <h1 className="heading1 grey--light affiliate-builder__title">
              {S.AFF_DETAIL_PAGE_TITLE}
            </h1>
          </div>
          <FinalForm
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            validate={this.validate}
            render={({ handleSubmit, submitting, submitError, invalid }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className='affiliate-builder__form'>
                    <section className="affiliate-builder__wrap">
                      <Container>
                        <Row>
                          <Col>
                            <div className="company-builder__card-title">
                              <h2 className="heading3">{S.AFF_DETAIL_CARD_TITLE}</h2>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="8">
                            <TextField label={S.AFF_NAME_LABEL} key="affiliate.name-key" name="name" />
                          </Col>
                          <Col sm="2">
                            <TextField
                              disabled={true}
                              label={S.AFF_AFFILIATE_ID_LABEL}
                              key="affiliate.affiliate-id-key"
                              name="affiliateID"
                            />
                          </Col>
                          <Col sm="2">
                            <NumberFormatField
                              label={S.AFF_EXTERNAL_ID_LABEL}
                              key="affiliate.external-id-key"
                              name="externalID"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <SingleSelectField
                              label={S.AFF_BRANCH_TYPE_LABEL}
                              key="affiliate.brancyTypeID-key"
                              name="branchTypeID"
                              options={branchList}
                            />
                          </Col>
                          <Col sm="6">
                            <SingleSelectField
                              label={S.AFF_RBM_LABEL}
                              key="affiliate.rbmId-key"
                              name="rbmId"
                              options={rbmList}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <TextField
                              label={S.AFF_PHONE_LABEL}
                              key="affiliate.phone-key"
                              name="phoneNumber"
                              type="tel"
                              parse={formatString(phoneMask.parse)}
                            />
                          </Col>
                          <Col sm="6">
                            <TextField
                              label={S.AFF_EMAIL_LABEL}
                              key="affiliate.email-key"
                              name="email"
                              type="email"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FieldArray name="contacts">
                              {({ fields }) => (
                                <Container className="bootsrap-container-override">
                                  {fields.map((name, index) => (
                                    <React.Fragment key={`contacts[${index}]`}>
                                      <Row>
                                        <Col>
                                          <div className="company-builder__card-title">
                                            <h2 className="heading3">
                                              {(() => this.getContactTitle(fields.value, index))()}
                                            </h2>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="6">
                                          <TextField
                                            key={`affiliate.contacts[${index}].firstName`}
                                            label={S.AFF_FIRST_NAME_LABEL}
                                            name={`${name}.firstName`}
                                          />
                                        </Col>
                                        <Col sm="6">
                                          <TextField
                                            key={`affiliate.contacts[${index}].lastName`}
                                            label={S.AFF_LAST_NAME_LABEL}
                                            name={`${name}.lastName`}
                                          />
                                        </Col>
                                        <Col>
                                          {index != 0 && (
                                            <div className="affiliate-builder__delete-btn">
                                              <Button
                                                color="secondary"
                                                onClick={() => fields.remove(index)}>
                                                {" "}
                                                Delete{" "}
                                              </Button>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  ))}
                                  <Row>
                                    <Col>
                                      <div className="affiliate-builder__alt-button">
                                        <Button
                                          color="secondary"
                                          className="button__white button__white--orange-hover"
                                          onClick={e => {
                                            e.preventDefault();
                                            fields.push({ isPrimary: fields.length === 0 });
                                          }}>
                                          {S.AFF_ADD_ALT_CONTACT}
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                              )}
                            </FieldArray>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="company-builder__card-title">
                              <h2 className="heading3">{S.AFF_DETAIL_CARD_TITLE4}</h2>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <TextField
                              label={S.AFF_STREET_ADDRESS_LABEL}
                              key="affiliate.address-key"
                              name="address.streetAddress"
                            />
                          </Col>
                          <Col sm="6">
                            <TextField
                              label={S.AFF_CITY_LABEL}
                              key="affiliate.city-key"
                              name="address.city"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <SingleSelectField
                              label={S.AFF_STATE_LABEL}
                              key="affiliate.state-key"
                              name="address.stateID"
                              options={stateList}
                            />
                          </Col>
                          <Col sm="6">
                            <TextField
                              label={S.AFF_ZIP_LABEL}
                              key="affiliate.zip-key"
                              name="address.zip"
                              //parse={formatString(zipMask.parse)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h1 className="heading3">{S.AFF_NOTES_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextField
                              name="notes"
                              key="affiliate.notes-key"
                              label={S.AFF_NOTES_LABEL}
                            />
                          </Col>
                        </Row>
                        {companies && companies.length > 0 && (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <div className="company-builder__card-title">
                                  <h2 className="heading3">{S.AFF_DETAIL_CARD_TITLE5}</h2>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="affiliate-builder__table-override">
                                <DisTable
                                  columns={columns}
                                  initialState={{}}
                                  isPaginated={false}
                                  onUpdate={async ({ page, rows, sort, query }) => {
                                    let mapped = (companies as any[]).map(c => {
                                      c.id = c.companyID;
                                      return c;
                                    });
                                    return {
                                      total: companies.length,
                                      data: mapped
                                    };
                                  }}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Container>
                      <Container>
                        <Row>
                          <Col sm='6'>
                            <img src={logoAffiliateUrl} className="logoPreview" />
                            <br/><br/><br/>
                            <Fragment>
                              <input
                                color="primary"
                                accept="image/*"
                                type="file"
                                onChange={this.onAffiliateFileSelectionChange}
                                id="icon-button-file"
                                style={{ display: 'none', }}
                              />
                              &nbsp;
                              <label htmlFor="icon-button-file">
                                <span className="button5 button_grey">Affiliate Logo&nbsp;
                                  <span className="material-icons MuiIcon-root" aria-hidden="true">backup</span>
                                </span>
                              </label>
                              &nbsp;&nbsp;
                              <label>
                                <span className="button5 button_grey" onClick={() => this.onAffiliateFileDelete(this.affiliateID)} >Delete Logo&nbsp;
                                  <span className="material-icons MuiIcon-root" aria-hidden="true">delete</span>
                                </span>
                              </label>
                            </Fragment>
                          </Col>
                        </Row>
                      </Container>
                      {auth.user.userRole === 3 
                        ? "" 
                        : 
                        <div className="affiliate-builder__button-wrap">
                          <button className="button__orange" type="submit" disabled={submitting}>
                            {submitting ? <Spinner color="white" /> : S.AFF_SUBMIT_BUTTON}
                          </button>
                        </div>
                      }
                    </section>
                    <aside>
                      <div className='affiliate-builder__card-wrap'>
                        <AffiliateQuotesWidjet />
                      </div>
                    </aside>
                  </div>
                </form>
              );
            }}
          />
        </React.Fragment>
      </div>
    );
  }
}

const columns = [
  { key: "name", title: S.AFF_COM_COL_COMPANY_NAME },
  { key: "branch", title: S.AFF_COM_COL_BRANCH },
  { key: "emailAddress", title: S.AFF_COM_COL_EMAIL },
  { key: "phoneNumber", title: S.AFF_COM_COL_PHONE }
];

const mapStateToProps = ({
  auth,
  affiliates,
  affiliateImages,
  affiliateBuilder: { loading, ...dropdownOptions }
}: IRootState) => ({
  auth,
  affiliates,
  affiliateImages,
  dropdownOptions,
  loading
});

const mapDispatchToProps = {
  createAffiliate,
  fetchAffiliateOptions,
  fetchAffiliate,
  updateAffiliate,
  saveAffiliateLogo,
  deleteAffiliateLogo,
  uploadAffiliateLogo,
  hideErrorToastAffiliate
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AffiliateBuilder);
