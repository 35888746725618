import { combineReducers } from "redux";

import auth, { AuthState } from "./authentication";
import proposals, { ProposalResourcesState } from "./proposals";
import productBuilder, { ProductBuilderState } from "./productBuilder";
import proposalOptions, { ProposalOptionsState } from "./proposalOptions";
import brokers, { BrokerResourcesState } from "./brokers";
import brokerImages, { BrokerImageState } from "./brokersImages";
import companies, { CompanyResourcesState } from "./companies";
import affiliates, { AffiliateResourcesState } from "./affiliates";
import brokerSecureUpload, { BrokerSecureUploadState } from "./brokerSecureUpload";
import affiliateImages, { AffiliateImageState } from "./affiliatesImages";
import products, { ProductResourcesState } from "./products";
import companyBuilder, { CompanyBuilderState } from "./companyBuilder";
import affiliateBuilder, { AffiliateBuilderState } from "./affiliateBuilder";
import brokerBuilder, { BrokerBuilderState } from "./brokerBuilder";
import analyzerTree, { AnalyzerTreeState } from "./analyzerTree";
import documentation, { DocumentationState } from "./documentation";
import correspondence, { CorrespondenceState } from "./correspondence";
import proposalNotes, { ProposalNotesState } from "./proposalNotes";
import proposalIndividualCarier, { IProposalIndividualCarierState } from "./proposalIndividualCarier";
import proposalCensus, { ProposalCensusState } from "./proposalCensus";
import proposalRevisions, { ProposalRevisionsState } from "./proposalRevisions";
import proposalDisclaimer, { IProposalDisclaimerState } from "./proposalDisclaimer";
import questionAnswersStatus, { IQuestionAnswersStatusState } from "./question-answers-status";
import { SecureUploadState, ISecureUploadState } from "./secureUpload";
import healthCheck, { HealthCheckState } from "./healthCheck";

export interface IRootState {
  readonly auth: AuthState;
  readonly proposals: ProposalResourcesState;
  readonly proposalOptions: ProposalOptionsState;
  readonly productBuilder: ProductBuilderState;
  readonly brokers: BrokerResourcesState;
  readonly brokerImages: BrokerImageState;
  readonly companies: CompanyResourcesState;
  readonly affiliates: AffiliateResourcesState;
  readonly affiliateImages: AffiliateImageState;
  readonly products: ProductResourcesState;
  readonly companyBuilder: CompanyBuilderState;
  readonly affiliateBuilder: AffiliateBuilderState;
  readonly brokerBuilder: BrokerBuilderState;
  readonly analyzerTree: AnalyzerTreeState;
  readonly documentation: DocumentationState;
  readonly correspondence: CorrespondenceState;
  readonly proposalNotes: ProposalNotesState;
  readonly proposalIndividualCarier: IProposalIndividualCarierState;
  readonly proposalCensus: ProposalCensusState;
  readonly proposalRevisions: ProposalRevisionsState;
  readonly proposalDisclaimer: IProposalDisclaimerState;
  readonly questionAnswersStatus: IQuestionAnswersStatusState;
  readonly SecureUploadState: ISecureUploadState;
  readonly brokerSecureUpload: BrokerSecureUploadState;
  readonly healthCheck: HealthCheckState;
}

const rootReducer = combineReducers<IRootState>({
  auth,
  proposals,
  proposalOptions,
  productBuilder,
  brokers,
  brokerImages,
  companies,
  affiliates,
  affiliateImages,
  products,
  companyBuilder,
  affiliateBuilder,
  brokerBuilder,
  analyzerTree,
  documentation,
  correspondence,
  proposalNotes,
  proposalCensus,
  proposalRevisions,
  proposalDisclaimer,
  questionAnswersStatus,
  SecureUploadState,
  brokerSecureUpload,
  proposalIndividualCarier,
  healthCheck
});

export default rootReducer;
