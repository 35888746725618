import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Button, Icon } from '@material-ui/core'
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dropzone, { DropEvent } from "react-dropzone";
import { Form as FinalForm } from "react-final-form";
import { withStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "reactstrap";

import * as S from "../../constants/StringConstants";

import "./UploadDocumentModal.scss";

export interface UploadDocumentModalProps {
  accept?: string;
  open: boolean;
  handleFiles: <T extends File>(acceptedFiles: T[]) => void;
  onCancel: () => void;
}

interface UploadDocumentModalState {
  files: File[];
}

const DialogActions = withStyles(theme => ({
  root: {
    marginBottom: "1,5rem",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
}))(MuiDialogActions);

export default class UploadDocumentModal extends React.Component<
  UploadDocumentModalProps,
  UploadDocumentModalState
> {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  handleDrop = (accepted, rejected) => {
    if (accepted.length > 0) {
      this.setState(state => ({
        files: [...state.files, ...accepted]
      }));
    }
  };

  handleClickCancel = () => {
    const { onCancel } = this.props;
    onCancel();
    this.clearFiles();
  };

  handleClickUpload = () => {
    const { handleFiles } = this.props;
    const { files } = this.state;
    handleFiles(files);
    this.clearFiles();
  };

  clearFiles = () => {
    this.setState(state => ({
      files: []
    }));
  };

  handleDeleteFile = (e, fileName) => {
    e.preventDefault();
    const { files } = this.state;
    const updatedFiles = files.filter((value, index) => {
      return value.name !== fileName;
    });
    this.setState(state => ({
      files: updatedFiles
    }));
  };

  render() {
    const { open, onCancel } = this.props;
    const { files } = this.state;

    return (
      <div>
        <Dialog
          open={open}
          PaperProps={{
            style: {
              width: "64rem"
            }
          }}>
          <FinalForm onSubmit={this.handleClickUpload} initialValues={{}}>
            {formProps => {
              return (
                <form onSubmit={formProps.handleSubmit}>
                  <DialogTitle>{S.UDM_TITLE}</DialogTitle>
                  <DialogContent>
                    <Dropzone accept={this.props.accept} onDrop={this.handleDrop}>
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div className="dropzone" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>{S.UDM_DROPZONE}</p>
                        </div>
                      )}
                    </Dropzone>
                    <Container>
                      {files.map((file, index) => {
                        return (
                          <Row key={`${file.name}-${index}`}>
                            <Col className="fileNameColumn">{file.name}</Col>
                            <Col className="actionButtonColumn">
                              <Button
                                variant="contained"
                                component="span"
                                className='button5 button__orange'
                                onClick={e => this.handleDeleteFile(e, file.name)}
                                size="large">
                                {S.UDM_DELETE_BUTTON}
                                <Icon>delete</Icon>
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    </Container>
                    {formProps.error && formProps.dirty && (
                      <div className="dropzone__error">{formProps.error}</div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button className="dropzone__cancel-button" onClick={this.handleClickCancel}>
                      {S.UDM_CANCEL_BUTTON}
                    </Button>
                    <Button
                      disabled={!this.state.files || !this.state.files.length}
                      className="dropzone__upload-button"
                      type="submit">
                      {S.UDM_UPLOAD_BUTTON}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </div>
    );
  }
}
