import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AppContainer from "./views/AppContainer";
import Login from "./views/Login/Login";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import AllTable from "./views/Proposals/Proposals";
import Brokers from "./views/Brokers/Brokers";
import Companies from "./views/Companies/Companies";
import CompanyBuilder from "./views/CompanyBuilder/CompanyBuilder";
import Affiliates from "./views/Affiliates/Affiliates";
import ProductBuilder from "./views/ProductBuilder/ProductBuilder";
import FileUpload from "./views/FileUpload/FileUpload";
import HealthCheck from "./views/HealthCheck/HealthCheck";
import PrivateRoute from "./components/PrivateRoute";
import ProposalContainer from "./views/Proposals/Proposal/ProposalContainer";
import AffiliateBuilder from "./views/AffiliateBuilder/AffiliateBuilder";
import BrokerBuilder from "./views/BrokerBuilder/BrokerBuilder";
import { UserRole } from "./store/models/user.model";
import FileRef from "./views/FileRef/FileRef";

const Routes = () => (
  <div className="app-routes">
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/file-ref/:pid/:did" component={FileRef} />
      <Route
        path="/"
        render={props => (
          <AppContainer>
            <Switch>
              <PrivateRoute path="/proposals/all" 
                            component={AllTable}
                            authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.RegionalBranchManager, UserRole.BranchManager]} />
              <PrivateRoute path="/proposals/:id/(ml)?"
                            component={ProposalContainer} 
                            authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.RegionalBranchManager, UserRole.BranchManager]}/>
              <PrivateRoute path="/brokers/all" 
                            component={Brokers}
                            authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.RegionalBranchManager, UserRole.BranchManager]} />
              <PrivateRoute path="/brokers/:id" 
                            component={BrokerBuilder}
                            authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.RegionalBranchManager, UserRole.BranchManager]} />
              <PrivateRoute
                path="/companies/all"
                component={Companies}
                authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.RegionalBranchManager, UserRole.BranchManager]}
              />
              <PrivateRoute
                path="/companies/:id"
                component={CompanyBuilder}
                authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.RegionalBranchManager, UserRole.BranchManager]}
              />
              <PrivateRoute
                path="/affiliates/all"
                component={Affiliates}
                authorizedRoles={[UserRole.Admin, UserRole.RegionalBranchManager, UserRole.BranchManager]}
              />
              <PrivateRoute
                path="/affiliates/:id"
                component={AffiliateBuilder}
                authorizedRoles={[UserRole.Admin, UserRole.RegionalBranchManager]}
              />
              <PrivateRoute
                path="/product-builder"
                component={ProductBuilder}
                authorizedRoles={[UserRole.Admin]}
              />
              <PrivateRoute
                path="/file-upload"
                component={FileUpload}
                authorizedRoles={[UserRole.Admin, UserRole.RegionalBranchManager, UserRole.BranchManager, UserRole.Specialist]}
              />
              <PrivateRoute
                path="/health-check"
                component={HealthCheck}
                authorizedRoles={[UserRole.Admin]}
              />
              <Redirect to="/proposals/all" />
            </Switch>
          </AppContainer>
        )}
      />
    </Switch>
  </div>
);

export default Routes;
