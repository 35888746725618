import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Container, Row, Col, Spinner } from "reactstrap";
// import cx from 'classnames'
import * as S from "../../constants/StringConstants";
import TextField from "../../components/Form/TextField/TextField";
import { IRootState } from "../../store/reducers";
import { FormApi } from "final-form";
import { requestPasswordReset } from "../../store/reducers/authentication";
import "./ForgotPassword.scss";


export interface IForgotPasswordProps extends StateProps, DispatchProps, RouteComponentProps {}

export class ForgotPassword extends React.Component<IForgotPasswordProps> {
  onSubmit = async values => {
    const { email } = values;
    try {
      await requestPasswordReset(email);
    } catch (error) {
      return {
        [FORM_ERROR]: S.ERROR_NETWORK_GENERAL
      };
    }
  };

  render() {
    return (
      <section className="login">
        <Container fluid>
          <Row>
            <Col>
              <div className="login__form-wrap">
                <h1>{S.FORGOT_PASSWORD_LABEL}</h1>

                <FinalForm
                  onSubmit={this.onSubmit}
                  render={({
                    handleSubmit,
                    submitting,
                    pristine,
                    submitSucceeded,
                    submitFailed,
                    submitError
                  }) => {
                    return (
                      <form noValidate className="login__form" onSubmit={handleSubmit}>
                        {submitSucceeded ? (
                          <div className="is-valid">{S.FORGOT_PASSWORD_ADMIN_NOTIFIED}</div>
                        ) : submitError ? (
                          <div className="not-valid">{submitError}</div>
                        ) : (
                          <p className="forgot-pass__description">
                            {S.FORGOT_PASSWORD_DESCRIPTION}
                          </p>
                        )}

                        <TextField 
                        label={S.EMAIL_LABEL} 
                        name="email" 
                        type="email"
                        light={true} 
                        />
                        <button type="submit" disabled={pristine}>
                          {submitting ? <Spinner color="white" /> : S.FORGOT_PSWD}
                        </button>
                      </form>
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
