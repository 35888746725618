import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import StatusBadge, { ProposalStatus } from "../../components/StatusBadge/StatusBadge";
import { IProposalRecord } from "../../store/models/proposal.model";
import { fetchProposalsList } from "../../store/reducers/proposals";
import { fetchStatusList, fetchProductTypeList } from "../../store/reducers/proposalOptions";
import { renderTimeSpan } from "../../components/Table/columnRenders";

import DisTable from "../../components/Table/Table";
import StatusFilterTool from "../../components/Table/StatusFilter";
import ProductTypeFilterTool from "../../components/Table/ProductTypeFilter";
import SearchFieldTool from "../../components/Table/SearchField";

import { addDefaultOption } from "../../util/utils.defaultValues";
import "./Proposals.scss";

export interface IProposalTableAllProps extends StateProps, DispatchProps, RouteComponentProps {}

export class Proposals extends React.Component<IProposalTableAllProps> {
  async componentDidMount() {
    await this.props.fetchStatusList();
    await this.props.fetchProductTypeList();
  }

  render() {
    const {
      fetchProposalsList,
      proposals: { map, projection, total, listOptions },
      statusList,
      productTypeList
    } = this.props;

    const statusOptions = addDefaultOption(S.PRO_ALL, statusList);
    const productTypeOptions = addDefaultOption(S.PRO_ALL, productTypeList);

    const columns = [
      {
        key: "receivedDateDisplay",
        title: S.PRO_COL_RECEIVED_DATE,
        //render: renderDateSpan,
        style: { minWidth: "12rem" }
      },
      {
        key: "timeSpan",
        title: S.PRO_COL_TIME,
        render: renderTimeSpan,
        style: { minWidth: "12rem" }
      },
      {
        key: "productTypeAbbreviation",
        title: S.PRO_COL_PRODUCT_TYPE,
        style: { minWidth: "12rem" }
      },      {
        key: "proposalID",
        title: S.PRO_COL_PROPOSAL,
        render: item => {
          if (item.fromBrokerQuoteEngine) {
            return (
              <div className="fromBrokerQuoteEngine">
                {item.proposalID}
              </div>
            );
          } else {
            return item.proposalID;
          }
        },
        style: { minWidth: "5rem" }
      },
      {
        key: "revisionNumber",
        title: S.PRO_COL_REV,
        style: { minWidth: "5rem" }
      },
      {
        key: "branch",
        title: S.PRO_COL_BRANCH,
        style: { minWidth: "8rem" },
        sortKey: "Branch"
      },
      {
        key: "rbm",
        title: S.PRO_COL_RBM,
        style: { minWidth: "8rem" },
        sortKey: "RBM"
      },
      {
        key: "affiliate",
        title: S.PRO_COL_AFFILIATE,
        style: { minWidth: "7rem" }
      },
      {
        key: "memberCompany",
        title: S.PRO_COL_MEMBER,
        style: { minWidth: "12rem" },
        sortKey: "MemberCompany"
      },
      {
        key: "brokerName",
        title: S.PRO_COL_BROKER,
        style: { minWidth: "10rem", width: "10rem" }
      },
      {
        key: "clientName",
        title: S.PRO_COL_CLIENT,
        render: item => {
          if (item.isMultiLife) {
            return (
              <div>
                <i className="icon icon--client" /> {S.PRO_COL_MULTI_LIFE}
              </div>
            );
          } else {
            return item.clientName;
          }
        },
        style: { minWidth: "8rem" }
      },
      {
        key: "specialistName",
        title: S.PRO_COL_PROPOSAL_SPECIALIST,
        style: { minWidth: "8rem" }
      },
      {
        key: "status",
        title: S.PRO_COL_STATUS,
        render: item => <StatusBadge status={item.status} />,
        style: { minWidth: "12rem" }
      }
    ];

    return (
      <div className="table-view__content">
        <DisTable<IProposalRecord>
          columns={columns}
          initialState={{ rows: 25, map, projection, total, ...listOptions }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchProposalsList({ page, rows, sort, query });
            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as IProposalRecord[]
            };
          }}
          onRowClick={(data: IProposalRecord) => {
            window.open(`/proposals/${data.proposalID}/details`);
          }}>
          <ProductTypeFilterTool productTypeList={productTypeOptions} />
          <StatusFilterTool statusList={statusOptions} />
          <SearchFieldTool />
        </DisTable>
      </div>
    );
  }
}

const mapStateToProps = ({ proposals, proposalOptions: { statusList, productTypeList } }: IRootState) => ({
  proposals,
  statusList,
  productTypeList
});

const mapDispatchToProps = { fetchProposalsList, fetchStatusList, fetchProductTypeList };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Proposals);
