import axios from "axios";
import { get } from "lodash";
import { PENDING, FULFILLED, REJECTED } from "./action-type.util";
import { IOption } from "../models/option.model";
import { defaultProposal } from "../models/proposal.model";
import { defaultCondition } from "../models/client.model";
import { roundCurrency } from "../../util/utils.currency";

const ACTION_TYPES = {
  RESET_BUILDER: "proposal/RESET_BUILDER",
  FETCH_OPTIONS: "proposal/FETCH_OPTIONS",
  FETCH_BROKER: "proposal/FETCH_BROKER",
  FETCH_RIDERS: "proposal/FETCH_RIDERS",
  FETCH_STATUS_LIST: "proposal/FETCH_STATUS_LIST",
  FETCH_PRODUCTTYPE_LIST: "proposal/FETCH_PRODUCTTYPE_LIST",
  FETCH_PROPOSAL: "proposal/FETCH_PROPOSAL",
  FETCH_PROPOSAL_CLASSIFICATION: "proposal/FETCH_PROPOSAL_CLASSIFICATION",
  SAVE_PROPOSAL: "proposal/SAVE_PROPOSAL",
  SUBMIT_PROPOSAL: "proposal/SUBMIT_PROPOSAL",
  FETCH_AGE: "proposal/FETCH_AGE",
  HIDE_SUBMIT_TOAST: "proposal/HIDE_SUBMIT_TOAST",
  HIDE_SAVE_TOAST: "proposal/HIDE_SAVE_TOAST",
  FETCH_OCCUPATION: "proposal/FETCH_OCCUPATION",
  CHANGE_STATUS_TYPE: "proposal/CHANGE_STATUS_TYPE",
  TRIGGER_PRODUCT_CHANGE: "proposal/TRIGGER_PRODUCT_CHANGE",
  HIDE_TRIGGER_PRODUCT_CHANGE: "proposal/HIDE_TRIGGER_PRODUCT_CHANGE"
};

const overviewOptions = {
  selectOriginList: [],
  selectSpecialistList: [],
  selectBrokerList: [],
  selectProductList: [],
  selectHearAboutUsList: []
};

const clientOptions = {
  selectGenderList: [],
  selectPrefixList: [],
  selectTobaccoTypeList: [],
  selectTobaccoFrequencyList: [],
  selectMedicationFrequencyList: [],
  selectCredentialTypeList: [],
  selectGovernmentTypeList: [],
  selectStateList: [],
  selectPaidByTypeList: []
};

const censusOptions = {
  selectDiscountRateList: [],
  selectBenefitCapList: [],
  selectPercentCapList: [],
  selectIndividualCarierList: [],
  selectGroupCarierList: [],
};

const classificationOptions = {
  bpTypeFullList: [],
  designTypeFullList: []
};

const caseDesignOptions = {
  selectBPList: [],
  selectEPList: [],
  selectPayorList: [],
  selectRiderList: []
};

type StatusList = IOption[];

type CarrierOptions = {
  [productTypeID: number]: IOption[];
};

type OccClassOptions = {
  [productTypeID: number]: {
    [carrierValue: number]: IOption[];
  };
};

type productOptions = {
  [productTypeID: number]: {
    [carrierValue: number]: {
      [occClassValue: number]: IOption[];
    };
  };
};

type CaseDesignRiderOptions = {
  productID: number;
  productName: string;
  riders: Array<IOption>;
};

const initialState = {
  isSubmitting: false,
  isSavingNext: false,
  isClassificationLoaded: false,
  isSavingPrevious: false,
  overviewOptions: overviewOptions,
  clientOptions: clientOptions,
  classificationOptions: classificationOptions,
  caseDesignOptions: caseDesignOptions,
  censusOptions: censusOptions,
  // Step 4
  caseDesignRiderOptions: {
    productID: null,
    productName: "",
    riders: []
  },
  statusList: [],
  productTypeList: [],
  age: {
    years: "",
    months: "",
    days: ""
  },
  isSubmitToastVisible: false,
  isSaveToastVisible: false,
  proposalMap: {},
  selectedOccupationOption: undefined,
};

export type ProposalOverviewOptionsState = Readonly<typeof overviewOptions>;
export type ProposalClientOptions = Readonly<typeof clientOptions>;
export type ProposalClassificationOptions = Readonly<typeof caseDesignOptions>;
export type ProposalCaseDesignOptions = Readonly<typeof caseDesignOptions>;
export type ProposalOptionsState = Readonly<typeof initialState>;

/**
 * ProposalOptions Reducer
 */
export default (state: ProposalOptionsState = initialState, action): ProposalOptionsState => {
  switch (action.type) {
    // Pending

    case PENDING(ACTION_TYPES.SUBMIT_PROPOSAL): {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case PENDING(ACTION_TYPES.SAVE_PROPOSAL): {
      const formAction = action.meta.formAction;

      return {
        ...state,
        isSavingNext: formAction === "next" || formAction === "save",
        isClassificationLoaded: false,
        isSavingPrevious: formAction === "previous" || formAction === "save"
      };
    }
    case PENDING(ACTION_TYPES.FETCH_OCCUPATION):
    case PENDING(ACTION_TYPES.FETCH_AGE):
    case PENDING(ACTION_TYPES.FETCH_PROPOSAL):
    case PENDING(ACTION_TYPES.FETCH_STATUS_LIST):
    case PENDING(ACTION_TYPES.FETCH_PRODUCTTYPE_LIST):
    case PENDING(ACTION_TYPES.FETCH_RIDERS):
    case PENDING(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state
      };
    }

    // Fulfilled

    case FULFILLED(ACTION_TYPES.RESET_BUILDER): {
      return {
        ...initialState
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_OCCUPATION): {
      const occupationOption = get(action, "payload.data");
      return {
        ...state,
        selectedOccupationOption: occupationOption
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_AGE): {
      const age = {
        years: action.payload.data.years,
        months: action.payload.data.months,
        days: action.payload.data.days
      };
      return {
        ...state,
        age
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_RIDERS): {
      const productID = action.payload.data.productID;
      const productName = action.payload.data.productName;
      const riders = action.payload.data.riders;
      return {
        ...state,
        caseDesignRiderOptions: {
          productID,
          productName,
          riders
        }
      };
    }

    case FULFILLED(ACTION_TYPES.SAVE_PROPOSAL): {
      const newState = newProposal(state, action);
      return {
        ...state,
        ...newState,
        isSaveToastVisible: true,
        isSavingNext: false,
        isSavingPrevious: false
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_PROPOSAL): {
      const newState = newProposal(state, action);

      return {
        ...state,
        ...newState
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_PROPOSAL_CLASSIFICATION): {
      const { classifications, proposalId, ...classificationOptions } = action.payload.data;

      classifications.map(c => {
        c.analyzer.baseBenefitAmount = roundCurrency(c.analyzer.baseBenefitAmount);
        c.analyzer.ssib = roundCurrency(c.analyzer.ssib);
        c.analyzer.totalBenefitAmount = roundCurrency(c.analyzer.totalBenefitAmount);
        c.analyzer.annualPremium = roundCurrency(c.analyzer.annualPremium);
        c.analyzer.potentialBenefitPayout = roundCurrency(c.analyzer.potentialBenefitPayout);
        c.analyzer.monthlyPremium = roundCurrency(c.analyzer.monthlyPremium);
        return c;
      });

      //state.proposalMap[proposalId].classifications = classifications; // that is human notation
      return {
        ...state,
        isClassificationLoaded: true,
        classificationOptions,
        proposalMap:{
          ...state.proposalMap,
          [proposalId]:{
            ...state.proposalMap[proposalId],
            classifications
          }
        }
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_OPTIONS): {
      const overviewOptions = action.payload.data.overviewDetails;
      const clientOptions = action.payload.data.clientDetails;
      const classificationOptions = action.payload.data.classificationDetails;
      const caseDesignOptions = action.payload.data.caseDesignDetails;
      const specialistID = action.meta.specialistID;

      return {
        ...state,
        overviewOptions,
        clientOptions,
        classificationOptions,
        caseDesignOptions,
        proposalMap: {
          ["new"]: {
            ...defaultProposal,
            specialistID
          },
          ["newml"]: {
            ...defaultProposal,
            isMultiLife: true,
            specialistID
          }
        }
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_STATUS_LIST): {
      const statusList = get(action, "payload.data");
      return {
        ...state,
        statusList
      };
    }

    case FULFILLED(ACTION_TYPES.FETCH_PRODUCTTYPE_LIST): {
      const productTypeList = get(action, "payload.data");
      return {
        ...state,
        productTypeList
      };
    }

    case FULFILLED(ACTION_TYPES.SUBMIT_PROPOSAL): {
      const newState = newProposal(state, action);
      return {
        ...state,
        ...newState,
        isSubmitting: false,
        isSubmitToastVisible: true
      };
    }

    // Rejected
    case REJECTED(ACTION_TYPES.FETCH_OCCUPATION):
    case REJECTED(ACTION_TYPES.SUBMIT_PROPOSAL):
    case REJECTED(ACTION_TYPES.FETCH_AGE):
    case REJECTED(ACTION_TYPES.SAVE_PROPOSAL):
    case REJECTED(ACTION_TYPES.FETCH_STATUS_LIST):
    case REJECTED(ACTION_TYPES.FETCH_PRODUCTTYPE_LIST):
    case REJECTED(ACTION_TYPES.FETCH_RIDERS):
    case REJECTED(ACTION_TYPES.FETCH_OPTIONS): {
      return {
        ...state,
        isSubmitting: false,
        isSavingNext: false,
        isSavingPrevious: false
      };
    }

    // Non - network
    case ACTION_TYPES.HIDE_SUBMIT_TOAST: {
      return {
        ...state,
        isSubmitToastVisible: false
      };
    }

    case ACTION_TYPES.HIDE_SAVE_TOAST: {
      return {
        ...state,
        isSaveToastVisible: false
      };
    }

    case ACTION_TYPES.CHANGE_STATUS_TYPE: {
      const { proposalID, statusTypeID } = action.meta;
      const proposal = state.proposalMap[proposalID];

      return {
        ...state,
        proposalMap: {
          ...state.proposalMap,
          [proposalID]: {
            ...proposal,
            statusTypeID
          }
        }
      };
    }

    case ACTION_TYPES.TRIGGER_PRODUCT_CHANGE: {
      const { proposalID, productTypeID } = action.meta;
      var proposal = state.proposalMap[action.meta.proposalID];

      proposal.isProductChangeTriggered = true;
      proposal.productTypeID = productTypeID;
      return {
        ...state,
        proposalMap: {
          ...state.proposalMap,
          [proposalID]: {
            ...proposal
          }
        }
      };
    }

    case ACTION_TYPES.HIDE_TRIGGER_PRODUCT_CHANGE: {
      const { proposalID } = action.meta;
      var proposal = state.proposalMap[action.meta.proposalID];

      proposal.isProductChangeTriggered = false;
      return {
        ...state,
        proposalMap: {
          ...state.proposalMap,
          [proposalID]: {
            ...proposal
          }
        }
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

const newProposal = (state: ProposalOptionsState, action): ProposalOptionsState => {
  const {
    specialistID,
    broker,
    productTypeID,
    hearAboutUsTypeID,
    originationTypeID,
    ...overviewOptions
  } = action.payload.data.overviewDetails;

  const { isMultiLife, ...clientOptions } = action.payload.data.clientDetails;
  var { clients } = action.payload.data.clientDetails;

  const { ...censusDetails } = action.payload.data.censusDetails;
  const censusOptions = {
    selectBenefitCapList: censusDetails.selectBenefitCapList,
    selectDiscountRateList: censusDetails.selectDiscountRateList,
    selectPercentCapList: censusDetails.selectPercentCapList,
    selectIndividualCarierList: censusDetails.selectIndividualCarierList,
    selectGroupCarierList: censusDetails.selectGroupCarierList
  }

  // Ensure clients have a default medical condition.
  clients = clients.map(c => {
    if (c.conditions.length === 0) {
      c.conditions.push(defaultCondition);
    }
    c.isReplaceExistingCoverage = c.isReplaceExistingCoverage ? 1 : 0;
    return c;
  });

  const {
    productType,
    bpTypeID,
    epTypeID,
    payorTypeID,
    individualCarrierTypeID,
    benefitAmount,
    isMaxBenefit,
    riders,
    clientNote,
    ...caseDesignOptions
  } = action.payload.data.caseDesignDetails;

  const {
    proposalID,
    proposalGroupID,
    statusTypeID,
    createdDate,
    isSubmitted
  } = action.payload.data;

  const proposal = {
    proposalID,
    proposalGroupID,
    specialistID,
    broker,
    productTypeID,
    originationTypeID,
    hearAboutUsTypeID,
    isMultiLife,
    clients,
    productType,
    bpTypeID,
    epTypeID,
    individualCarrierTypeID,
    payorTypeID,
    benefitAmount,
    isMaxBenefit,
    riders,
    statusTypeID,
    createdDate,
    clientNote,
    censusDiscountRateId: censusDetails.discountRateId || 0,
    censusBenefitCapId: censusDetails.benefitCapId || 0,
    censusBenefitCapOther: censusDetails.benefitCapOther || 0,
    censusPercentCapId: censusDetails.percentCapId || 0,
    censusPercentCapOther: censusDetails.percentCapOther || 0,
    carrierGroupTypeId: censusDetails.carrierGroupTypeId || 0,
    carrierGroupTypeOther: censusDetails.carrierGroupTypeOther,
    twoCarrier: censusDetails.twoCarrier,
    isFiveCarrier: censusDetails.isFiveCarrier,
    isSubmitted,
    isProductChangeTriggered: false
  };

  // HACK: pull the age out and store it in the top level state.
  const age = get(proposal, "clients.0.age");

  return {
    ...state,
    overviewOptions,
    clientOptions,
    caseDesignOptions,
    censusOptions: censusOptions,
    proposalMap: {
      ...state.proposalMap,
      [proposalID]: proposal
    },
    age
  };
};

export const resetBuilder = () => {
  return {
    type: FULFILLED(ACTION_TYPES.RESET_BUILDER),
    payload: {}
  };
};

/**
 * Fetch an existing proposal and it's options
 */
export const fetchProposal = (id: string) => {
  return {
    type: ACTION_TYPES.FETCH_PROPOSAL,
    payload: axios.get(`proposals/${id}`)
  };
};

/**
 * Fetch an existing proposal Classification options
 */
export const fetchProposalClassifications = (id: string) => {
  return {
    type: ACTION_TYPES.FETCH_PROPOSAL_CLASSIFICATION,
    payload: axios.get(`proposals/${id}/classifications`)
  };
};

/**
 * Create a resource with the specified parameters.
 * @param {[key: string]: any} params - key value pairs to initialize the resource.
 */
export const saveProposal = (params: { [key: string]: any }) => {
  return {
    type: ACTION_TYPES.SAVE_PROPOSAL,
    meta: { formAction: "save" },
    payload: axios.post("proposals", params)
  };
};

/**
 * Submit a proposal for analysis.
 * @param params
 */
export const submitProposal = (params: { [key: string]: any }) => {
  return {
    type: ACTION_TYPES.SUBMIT_PROPOSAL,
    payload: axios.post(`proposals/submissions`, params)
  };
};

/**
 * Submit the proposal and trigger next is loading.
 * @param params -proposal json data.
 */
export const saveProposalNext = (params: { [key: string]: any }) => ({
  type: ACTION_TYPES.SAVE_PROPOSAL,
  meta: { formAction: "next" },
  payload: axios.post(`proposals`, params)
});

/**
 * Submit the proposal and trigger previous is loading.
 * @params params - proposal json data.
 */
export const saveProposalPrevious = (params: { [key: string]: any }) => ({
  type: ACTION_TYPES.SAVE_PROPOSAL,
  meta: { formAction: "previous" },
  payload: axios.post(`proposals`, params)
});

/**
 * Fetch all status options available.
 */
export const fetchStatusList = () => ({
  type: ACTION_TYPES.FETCH_STATUS_LIST,
  payload: axios.get("proposals/statuses")
});

/**
 * Fetch all producttype options available.
 */
export const fetchProductTypeList = () => ({
  type: ACTION_TYPES.FETCH_PRODUCTTYPE_LIST,
  payload: axios.get("proposals/producttypes")
});

/**
 * Fetch dropdown options for creating a new proposal.
 */
export const fetchProposalOptions = (specialistID: number, ml: string) => ({
  type: ACTION_TYPES.FETCH_OPTIONS,
  meta: { specialistID },
  payload: axios.get("proposals/new/" + ml)
});

/**
 * Fetch details for the specified broker.
 * @param {number} brokerId
 */
export const fetchBrokerDetails = brokerId => ({
  type: ACTION_TYPES.FETCH_BROKER,
  payload: axios.get(`brokers/${brokerId}`)
});

/**
 * Fetch the riders for the specified product.
 * @param {number} productTypeID
 */
export const fetchRiders = (productTypeID: number) => ({
  type: ACTION_TYPES.FETCH_RIDERS,
  payload: axios.get(`products/${productTypeID}/riders`, { params: { id: productTypeID } })
});

/**
 * Request age break down for the specified date of birth.
 * Age breakdown: X years X months X days
 * @param dob
 */
export const fetchAge = (dob: string) => {
  return {
    type: ACTION_TYPES.FETCH_AGE,
    payload: axios.get(`datetime/age/${dob.split('T')[0]}`)
  };
};

export const fetchOccupation = (occupationID: number) => {
  return {
    type: ACTION_TYPES.FETCH_OCCUPATION,
    payload: axios.get(`proposals/occupations/${occupationID}`)
  };
};

/**
 * Update state to hide the submission toast.
 */
export const hideToast = () => {
  return {
    type: ACTION_TYPES.HIDE_SUBMIT_TOAST
  };
};

export const hideSaveToast = () => {
  return {
    type: ACTION_TYPES.HIDE_SAVE_TOAST
  };
};

/**
 * Change the statusTypeID for the specified proposal in redux.
 * The status type dropdown lives outside the rest of the proposal builder form so it
 * requires a workaround to propagate it's changes. I hate life.
 * @param {string} proposalID - id of the specified proposal. "new" is a valid id.
 * @param {number} statusTypeID - id of the status type.
 */
export const changeStatusTypeForProposal = (proposalID: string, statusTypeID: number) => {
  return {
    type: ACTION_TYPES.CHANGE_STATUS_TYPE,
    meta: { proposalID, statusTypeID }
  };
};

export const triggerProductChange = (proposalID: number, productTypeID: number) => {
  return {
    type: ACTION_TYPES.TRIGGER_PRODUCT_CHANGE,
    meta: { proposalID,  productTypeID }
  };
};

export const hideProductChangeTriggered = (proposalID: number) => {
  return {
    type: ACTION_TYPES.HIDE_TRIGGER_PRODUCT_CHANGE,
    meta: { proposalID }
  };
};
