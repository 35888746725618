import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import * as S from "../../constants/StringConstants";
import { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { PRO_COL_PROPOSAL_SPECIALIST } from "src/constants/StringConstants";

import "./PromptModal.scss";

export interface IPromptModalProps {
  title: string
  confirmText: string
  rejectText: string
  open: boolean;
  onReject?: () => void
  onConfirm?: () => void
}

const DialogTitle = withStyles(theme => ({
  root: {
    "& h6": {
      fontSize: "2rem"
    }
  }
}))(MuiDialogTitle);

const DialogContent = withStyles(theme => ({
  root: {
    "& p": {
      fontSize: "1rem",
      lineHeight: "2",
      marginBottom: 0,
      color: theme.palette.text.primary
    },
    "& div": {
      marginTop: "1rem"
    }
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    marginBottom: "1,5rem",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
  }
}))(MuiDialogActions);

class PromptModal extends React.Component<IPromptModalProps> {
  constructor(props: IPromptModalProps) {
    super(props);
  }

  handleClickOpen = () => {
    ;
  };

  handleConfirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const { onConfirm } = this.props;
    this.setState({ open: false });

    if (onConfirm) {
      onConfirm();
    }
  };

  handleReject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const { onReject } = this.props;
    this.setState({ open: false });

    if (onReject) {
      onReject();
    }
  };

  render() {
    const { children, title, confirmText, rejectText, open } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleReject}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "64rem"
            }
          }}>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {children}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              className="prompt-modal__save-button"
              onClick={this.handleConfirm}>
              {confirmText}
            </Button>
            <Button
              className="prompt-modal__save-button"
              onClick={this.handleReject}
              autoFocus>
              {rejectText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default PromptModal;
