import * as React from "react";
import { connect } from "react-redux";
import { Component } from "react";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";

import {
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import * as S from "../../constants/StringConstants";
import { IUser, UserRole } from "../../store/models/user.model";
import { IRootState } from "src/store/reducers";
import GuardedComponent from "../../components/GuardedComponent/GuardedComponent";
import "./AppSideNav.scss";
import { createCompany } from "src/store/reducers/companies";
import { logout } from "../../store/reducers/authentication";

interface IAppSideNavProps extends StateProps, DispatchProps, RouteComponentProps {
  user: IUser;
}

export interface AppSideNavState {
  dropdownOpen?: boolean;
}

export interface AppSideNavProps {
  dropdownOpen?: boolean;
}

const proposalsPath = "/proposals/all";
const brokersPath = "/brokers/all";
const companiesPath = "/companies/all";
const affiliatesPath = "/affiliates/all";
const productBuilderPath = "/product-builder";
const fileUploadPath = "/file-upload";
const healthCheckPath = "/health-check";

class AppSideNav extends React.Component<IAppSideNavProps, AppSideNavProps, AppSideNavState> {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggle = this.toggle.bind(this);
    this.state = { dropdownOpen: false };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  createProposal = {
    name: S.NEW_PROPOSAL,
    path: "/proposals/new/details"
  };

  createProposalML = {
    name: S.NEW_PROPOSAL_ML,
    path: "/proposals/new/ml/details"
  };

  createCompany = {
    name: S.NEW_COMPANY,
    path: "/companies/new"
  };

  createAffiliate = {
    name: S.NEW_AFFILIATE,
    path: "/affiliates/new"
  };

  createBroker = {
    name: S.NEW_BROKER,
    path: "/brokers/new"
  };

  createMap = {
    ["proposals"]: this.createProposal,
    ["proposalsML"]: this.createProposalML,
    ["companies"]: this.createCompany,
    ["affiliates"]: this.createAffiliate,
    ["brokers"]: this.createBroker
  };

  handleOnClick = () => {
    const path = this.getCreateButton().path;

    if (path === this.createProposal.path) {
      window.open(path);
    } else {
      this.props.history.push(path);
    }
  };

  handleMlOnClick = () => {
    window.open(this.createProposalML.path);
  }

  isActiveClassName = (path: string): string => {
    return this.isActive(path) ? "isActive" : "";
  };

  isActive = (path: string) => {
    return this.props.location.pathname === path;
  };

  getCreateButton() {
    return (
      this.createMap[this.props.location.pathname.split("/").filter(x => x)[0]] ||
      this.createProposal
    );
  }

  isProposalView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "proposals";
  }

  isFileUploadView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "file-upload";
  }

  isProductBuilderView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "product-builder";
  }

  isAffiliatesView() {
    return this.props.location.pathname.split("/").filter(x => x)[0] === "affiliates";
  }

  getCreateName = () => {
    return this.getCreateButton().name;
  };

  logout = () => {
    this.props.logout();
  };

  render() {
    const { user } = this.props;

    let addButton;
    if (this.isProposalView() || this.isFileUploadView() || this.isProductBuilderView()) {
      addButton = 
      <GuardedComponent authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.RegionalBranchManager, UserRole.BranchManager]}>
        <button className="side-nav__add-button" onClick={this.handleOnClick}>
          <span>+</span> {this.getCreateName()}
        </button>
        <button className="side-nav__add-button" onClick={() => { this.handleMlOnClick(); }}>
          <span>+</span> {S.NEW_PROPOSAL_ML}
        </button>
      </GuardedComponent>;
    }
    else if (this.isAffiliatesView()){
      addButton = 
      <GuardedComponent authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.BranchManager]}>
        <button  className="side-nav__add-button" onClick={this.handleOnClick}>
          <span>+</span> {this.getCreateName()}
        </button>
      </GuardedComponent>;
    }
    else {
      addButton = 
      <GuardedComponent authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.RegionalBranchManager, UserRole.BranchManager]}>
        <button  className="side-nav__add-button" onClick={this.handleOnClick}>
          <span>+</span> {this.getCreateName()}
        </button>
      </GuardedComponent>;
    }

    return (
      <aside className="side-nav">
        <div className="side-nav__logo" />
        {addButton}
        <div className="side-nav__links">
          <GuardedComponent authorizedRoles={[UserRole.Admin, UserRole.Specialist, UserRole.RegionalBranchManager, UserRole.BranchManager]}>
            <Link className={this.isActiveClassName(proposalsPath)} to={proposalsPath}>
              {S.NAV_PROPOSALS}
            </Link>
            <Link className={this.isActiveClassName(brokersPath)} to={brokersPath}>
              {S.NAV_BROKERS}
            </Link>
            <Link className={this.isActiveClassName(companiesPath)} to={companiesPath}>
              {S.NAV_COMPANIES}
            </Link>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin, UserRole.RegionalBranchManager]}>
            <Link className={this.isActiveClassName(affiliatesPath)} to={affiliatesPath}>
              {S.NAV_AFFILIATES}
            </Link>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <Link className={this.isActiveClassName(productBuilderPath)} to={productBuilderPath}>
              {S.NAV_PRODUCT_BUILDER}
            </Link>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin, UserRole.RegionalBranchManager, UserRole.Specialist, UserRole.BranchManager]}>
            <Link className={this.isActiveClassName(fileUploadPath)} to={fileUploadPath}>
              {S.NAV_SECURE_UPLOAD}
            </Link>
          </GuardedComponent>
          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <Link className={this.isActiveClassName(healthCheckPath)} to={healthCheckPath}>
              {S.NAV_HEALTH_CHECK_UPLOAD}
            </Link>
          </GuardedComponent>
        </div>
        <div className="side-nav__footer">
          <Dropdown direction="up" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>{user.email}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={e => {
                  this.logout();
                }}>
                {S.NAV_LOGOUT}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => ({
  user: auth.user
});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppSideNav)
);
