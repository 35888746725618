import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { IRootState } from "../../../../store/reducers";

import ProposalBuilder from "./Regular/ProposalBuilder";
import ProposalBuilderML from "./MultiLife/ProposalBuilderML";

import {
  fetchProposal,
  fetchProposalOptions
} from "../../../../store/reducers/proposalOptions";

import "./ProposalDetailsContainer.scss";

export interface IProposalDetailsContainerProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }>  {
  proposalID: string;
  onBrokerChange: (notes: string) => void
}

class ProposalDetailsContainer extends React.Component<IProposalDetailsContainerProps> {
  async componentDidMount() {
    const { user, proposalID } = this.props;
    const id = parseInt(proposalID, 10);
    if (id) {
      await this.props.fetchProposal(`${id}`);
    }
    else {
        this.props.fetchProposalOptions(user.userID, this.props.match.params[0]);
    }
  }

  render() {
    const { proposalID, proposalMap } = this.props;
    if (proposalMap) {
      const proposal = proposalMap[proposalID];
      if (proposal){
        if (proposal.isMultiLife){
          return <ProposalBuilderML {...this.props} proposalID={proposalID} />;
        }
        else {
          return <ProposalBuilder {...this.props} proposalID={proposalID} />;
        }
      }
    }

    return '';
  }
}

const mapStateToProps = (state: IRootState) => ({
  proposalMap: state.proposalOptions.proposalMap,
  user: state.auth.user,
});

const mapDispatchToProps = {
  fetchProposal,
  fetchProposalOptions
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalDetailsContainer);
