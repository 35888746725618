import * as React from "react";
import { Component, createRef } from 'react';
import { connect, memoize } from "react-redux";
import { RouteComponentProps, Link } from "react-router-dom";
import { set, get } from "lodash";
import {
    updateCensusData,
    deleteCensusData,
    saveCensusColumnsOrder,
    getCensusColumnsOrder
  } from "../../../../../../store/reducers/proposalCensus";

import { IRootState } from "../../../../../../store/reducers";
import MaterialTable, { Query, QueryResult } from "material-table";

import * as S from "../../../../../../constants/StringConstants";
import "./SingleCarrierGrid.scss";

export interface ISingleCarrierGridProps extends RouteComponentProps, StateProps, DispatchProps {
    proposalID: number;
}

class SingleCarrierGrid extends React.Component<ISingleCarrierGridProps> {
    tableRef: any;

    constructor(props) {
        super(props);
        this.tableRef = createRef();
    }

    componentDidMount() {
      this.props.getCensusColumnsOrder(`${this.props.proposalID}`);
    }

    checkChanges = changes => {
        let isValid = true;

        var attempt = true;
        var i = 0;
        do {
            const change = get(changes, `${i}`);
            if (change) {
            isValid = change.newData.income >= 0
                    && change.newData.groupMonthlyBenefit >= 0
                    && change.newData.groupMonthlyBenefit >= 0
                    && change.newData.individualMonthlyBenefit >= 0
                    && change.newData.monthlyDiscountedPremium >= 0 
                    && change.newData.percentOfIncomeProtectedByGroup >= 0 && change.newData.percentOfIncomeProtectedByGroup <= 100
                    && change.newData.totalPercentOfIncomeProtected >= 0 && change.newData.totalPercentOfIncomeProtected <= 100; 
            i++;
            }
            else {
            attempt = false;
            }
        } while (attempt && isValid);

        return isValid;
    }
    
  render() {
    const {
        proposalCensus
      } = this.props;

    var uploadedCensus = proposalCensus.map[this.props.proposalID];
    var uploadedCensusSortOrder = proposalCensus.mapSortOrder[this.props.proposalID];

    // if(uploadedCensus && uploadedCensusSortOrder && uploadedCensusSortOrder.field) {
    //   var sortOrder = 1;
    //   var property = uploadedCensusSortOrder.field;
    //   if(uploadedCensusSortOrder.sortOrder === "desc"){
    //     sortOrder = -1;
    //   }
    //   uploadedCensus = uploadedCensus.sort((a, b) => {
    //     if (typeof(a[property]) === "string")
    //         return a[property].localeCompare(b[property]) * sortOrder;

    //     var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    //       return result * sortOrder;
    //   })
    // }
    
    var ref = this.tableRef;

    return (
        <MaterialTable
        tableRef={this.tableRef}
        options={{
          search: false,
          paging: false,
          maxBodyHeight: "552px",
          sorting: true,
        }}
        title=""
        columns={[
          {
            title: 'Name',
            field: 'name',
            customSort: (a, b) => a.name.localeCompare(b.name),
            defaultSort: uploadedCensusSortOrder && uploadedCensusSortOrder.field === "name" ? uploadedCensusSortOrder.sortOrder : null,
            render: rowData => {
              let className = "";
              if(rowData.percentOfIncomeProtectedByGroup < 50)
                className = 'red-cell';
              if(rowData.percentOfIncomeProtectedByGroup >=50 && rowData.percentOfIncomeProtectedByGroup <= 60)
                className = 'yellow-cell';
              
              return <span className={className}>{rowData.name}</span>
            } 
          },
          { 
            title: 'Client Income', 
            field: 'income',
            sorting: true,
            type: 'currency',
            defaultSort: uploadedCensusSortOrder && uploadedCensusSortOrder.field === "income" ? uploadedCensusSortOrder.sortOrder : null,
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            validate: rowData => rowData.income < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
          },
          { 
            title: 'Group Monthly Benefits', 
            field: 'groupMonthlyBenefit',
            type: 'currency',
            defaultSort: uploadedCensusSortOrder && uploadedCensusSortOrder.field === "groupMonthlyBenefit" ? uploadedCensusSortOrder.sortOrder : null,
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            validate: rowData => rowData.groupMonthlyBenefit < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
          },
          { 
            title: '% of income Protected By Group', 
            field: 'percentOfIncomeProtectedByGroup',
            type: 'currency',
            defaultSort: uploadedCensusSortOrder && uploadedCensusSortOrder.field === "percentOfIncomeProtectedByGroup" ? uploadedCensusSortOrder.sortOrder : null,
            validate: rowData => rowData.percentOfIncomeProtectedByGroup < 0 || rowData.percentOfIncomeProtectedByGroup > 100 ? { isValid: false, helperText: S.PERCENT_VALUE_INVALID } : true,
            render: rowData => {
              let className = "";
              if(rowData.percentOfIncomeProtectedByGroup < 50)
                className = 'red-cell';
              if(rowData.percentOfIncomeProtectedByGroup >=50 && rowData.percentOfIncomeProtectedByGroup <= 60)
                className = 'yellow-cell';
              
              return <span className={className}>{rowData.percentOfIncomeProtectedByGroup}%</span>
            } 
          },
          { 
            title: 'Individual Monthly Benefits', 
            field: 'individualMonthlyBenefit',
            type: 'currency',
            defaultSort: uploadedCensusSortOrder && uploadedCensusSortOrder.field === "individualMonthlyBenefit" ? uploadedCensusSortOrder.sortOrder : null,
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
            validate: rowData => rowData.individualMonthlyBenefit < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
          },
          { 
            title: 'Total % of Income Protected',
            field: 'totalPercentOfIncomeProtected',
            type: 'currency',
            defaultSort: uploadedCensusSortOrder && uploadedCensusSortOrder.field === "totalPercentOfIncomeProtected" ? uploadedCensusSortOrder.sortOrder : null,
            validate: rowData => rowData.totalPercentOfIncomeProtected < 0 || rowData.totalPercentOfIncomeProtected > 100 ? { isValid: false, helperText: S.PERCENT_VALUE_INVALID } : true,
            render: rowData => {
              let className = "green-cell";
              return <span className={className}>{rowData.totalPercentOfIncomeProtected}%</span>
            } 
          },
          { 
            title: 'Monthly Discounted Premium', 
            field: 'monthlyDiscountedPremium',
            type: 'currency',
            defaultSort: uploadedCensusSortOrder && uploadedCensusSortOrder.field === "monthlyDiscountedPremium" ? uploadedCensusSortOrder.sortOrder : null,
            currencySetting: { currencyCode:'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 },
            validate: rowData => rowData.monthlyDiscountedPremium < 0 ? { isValid: false, helperText: S.VALUE_COULD_NOT_BE_NEGATIVE } : true,
          }
        ]}
        data={uploadedCensus}
        onOrderChange={(orderedColumnId, orderDirection) => {
          if(ref.current.dataManager.columns[orderedColumnId]){
            var field = ref.current.dataManager.columns[orderedColumnId].field;
            this.props.saveCensusColumnsOrder(`${this.props.proposalID}`, field, orderDirection);
          }
        }}
        editable={{
          onBulkUpdate: changes =>
            new Promise((resolve, reject) => {
              
              if (!this.checkChanges(changes)) {
                return reject(); // Reject when invalid
              }
              else {
                this.props.updateCensusData(`${this.props.proposalID}`, changes);
                setTimeout(() => { resolve(); }, 1000);
              }
            }),     
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              this.props.deleteCensusData(`${this.props.proposalID}`, oldData);
              setTimeout(() => {
                resolve();
              }, 1000);
            }),     
        }}
      />
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  proposalMap: state.proposalOptions.proposalMap,
  proposalCensus: state.proposalCensus
});

const mapDispatchToProps = { 
    updateCensusData,
    deleteCensusData,
    saveCensusColumnsOrder,
    getCensusColumnsOrder
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCarrierGrid);
