import React from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { Form as FinalForm, FormRenderProps } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { get, set } from "lodash";
import { connect } from "react-redux";
import createDecorator from "final-form-calculate";
import arrayMutators from "final-form-arrays";
import CheckboxField from "../../../../../components/Form/CheckboxField/CheckboxField";
import ToggleField from "../../../../../components/Form/ToggleField/ToggleField";
import { Prompt } from "react-router-dom";

import * as S from "../../../../../constants/StringConstants";
import Classification from "./Classification";
import "./Classifications.scss";
import {
  defaultOptionValue,
  undefinedIfDefault,
  isEmptyOrDefault
} from "../../../../../util/utils.defaultValues";
import { defaultClassification } from "../../../../../store/models/classification.model";
import { defaultAnalyzer } from "../../../../../store/models/analyzer.model";
import { IProposal } from "../../../../../store/models/proposal.model";
import { IRootState } from "../../../../../store/reducers";
import { analyzerDropDownStructure } from "./AnalyzerColumn";
import {
  fetchProductOptions,
  fetchProductOptionsButWithAGet
} from "../../../../../store/reducers/analyzerTree";
import { saveSymbol, stepSymbol, stepIndexSymbol } from "./ProposalBuilder";

interface IClassificationsProps extends StateProps, DispatchProps {
  proposal: IProposal;
  next: (values: any) => void;
  generatePDF: (values: any) => void;
  previous: (values: any) => void;
  step: (values: any, pageIndex: number) => void;
}

const classificationDropDownStructure = [
  { fieldName: "productTypeID",
    paramName: "productTypeID",
    branch: "",
    route: ""
  },
  {
    fieldName: "carrierTypeID",
    paramName: "carrierTypeID",
    branch: "carriers",
    route: "occ-classes"
  },
  {
    fieldName: "occupationClassTypeID",
    paramName: "occClassTypeID",
    branch: "occ-classes",
    route: "designs"
  },
  {
    fieldName: "designTypeID",
    paramName: "designTypeID",
    branch: "designs",
    route: "renewabilities"
  }
];

class ProposalDetailsClassifications extends React.Component<IClassificationsProps> {
  handleSubmit = values => {
    const { next, previous, generatePDF, step } = this.props;
    const { formAction, ...rest } = values;

    if (window[saveSymbol]) {
      generatePDF(rest);
      window[saveSymbol] = false;
    } else if (window[stepSymbol]) {
      step(rest, window[stepIndexSymbol]);
      window[stepSymbol] = false;
    } else {
      if (formAction === "next") {
        next(rest);
      } else {
        previous(rest);
      }
    }
  };

  handleValidate = values => {
    const errors = {};

    const { analyzerTree } = this.props;

    // Classifications are not required for multi life clients
    const isMultiLife = values.isMultiLife;
    if (isMultiLife) {
      return errors;
    }

    values.classifications.map((c, index) => {
      const carrierPath = `classifications.${index}.carrierTypeID`;
      const occupationPath = `classifications.${index}.occupationClassTypeID`;
      const designPath = `classifications.${index}.designTypeID`;
      const productTypeID = get(values, "productTypeID");

      const carrierTypeID = get(values, carrierPath);
      const occupationClassTypeID = get(values, occupationPath);
      const designTypeID = get(values, designPath);

      const carrierQuery = `carrierOptions.${productTypeID}`;
      const occupationQuery = `occ-classes.${productTypeID}.${carrierTypeID}`;
      const designTypeQuery = `designs.${productTypeID}.${carrierTypeID}.${occupationClassTypeID}`;

      // Carrier Type
      if (isEmptyOrDefault(carrierTypeID)) {
        set(errors, carrierPath, S.FORM_FIELD_REQUIRED);
      } else {
        const carrierOptions = get(analyzerTree, carrierQuery);
        if (carrierOptions) {
          const value = carrierOptions.find(o => {
            return o.value === carrierTypeID;
          });
          if (!value) {
            set(errors, carrierPath, S.FORM_FIELD_REQUIRED);
          }
        }
      }

      // Occupation Class
      if (isEmptyOrDefault(occupationClassTypeID)) {
        set(errors, occupationPath, S.FORM_FIELD_REQUIRED);
      } else {
        const occupationOptions = get(analyzerTree, occupationQuery);
        if (occupationOptions) {
          const value = occupationOptions.find(o => {
            return o.value === occupationClassTypeID;
          });
          if (!value) {
            set(errors, occupationPath, S.FORM_FIELD_REQUIRED);
          }
        }
      }

      // Design Type (Product)
      if (isEmptyOrDefault(designTypeID)) {
        set(errors, designPath, S.FORM_FIELD_REQUIRED);
      } else {
        const designOptions = get(analyzerTree, designTypeQuery);
        if (designOptions) {
          const value = designOptions.find(o => {
            return o.value === designTypeID;
          });
          if (!value) {
            set(errors, designPath, S.FORM_FIELD_REQUIRED);
          }
        }
      }
    });

    return errors;
  };

  classificationFieldDecorator = createDecorator({
    field: /classifications\[[0-9]+\]\.([a-zA-Z]+)$/,
    updates: (value, name, allValues) => {
      let v = this;

      const regex = /(classifications\[[0-9]+\])\.(.+)$/;
      const match = regex.exec(name);

      if (value === undefined) {
        return {};
      }

      var previousValue = get(v, name);
      if(previousValue === value){
        return {};
      }

      if (!match) {
        console.log(`failed to match classification value ${name}`);
        return {};
      }

      const columnName = match[1];
      const fieldName = match[2];

      const dropDownIndex = classificationDropDownStructure.findIndex(f => {
        return f.fieldName === fieldName;
      });

      const fieldsToClear = classificationDropDownStructure.slice(
        dropDownIndex + 1,
        classificationDropDownStructure.length
      );
      const fieldsForRequest = classificationDropDownStructure.slice(0, dropDownIndex + 1);
      const route = classificationDropDownStructure[dropDownIndex].route;

      const result = {};

      const requestParams = {};
      for (let i = 0; i < fieldsForRequest.length; i++) {
        const field = fieldsForRequest[i];
        const fieldNameForRequest = columnName + "." + field.fieldName;
        const paramName = field.paramName;
        requestParams[paramName] = get(allValues, fieldNameForRequest);
      }
      requestParams["productTypeID"] = get(allValues, "productTypeID");

      if (route === "renewabilities") {
        this.props.fetchProductOptions(route, requestParams);
      } else {
        this.props.fetchProductOptionsButWithAGet(route, requestParams);
      }

      set(v, name, value);

      result[`${columnName}.analyzer`] = defaultAnalyzer;

      return result;
    }
  });

  render() {
    const { proposal, isSubmitting, isSavingNext, isSavingPrevious, isClassificationLoaded } = this.props;

    if(!proposal.classifications){
      proposal.classifications = [];
    }

    if (proposal.classifications.length === 0) {
      proposal.classifications.push(defaultClassification);
    }

    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={proposal}
        decorators={[this.classificationFieldDecorator]}
        mutators={{ ...arrayMutators }}
        >
        {formProps => {
          const formValues = formProps.values;
          const productTypeID = get(formValues, "productTypeID");

          return (
            <form
              className="classifications__form-wrap"
              onSubmit={formProps.handleSubmit}
              id="proposal-builder-form-2">
              {/* <Prompt
                  when={formProps.dirty && !formProps.submitting}
                  message={S.MOD_DIALOG_LEAVING}
                /> */}
              <Container>
                <Row>
                  <Col>
                    <h1 className={"heading3"}>{S.PBC_TITLE}</h1>
                  </Col>
                  <Col>
                    <ToggleField
                      key={"isFiveCarrier"}
                      name={"isFiveCarrier"}
                      labelBefore={S.PBC_FIVE_CARRIERS_CHECKBOX_DIS}
                      labelAfter={S.PBC_FIVE_CARRIERS_CHECKBOX}
                    />
                  </Col>
                </Row>
                <FieldArray name={"classifications"}>
                  {({ fields }) => (
                    <React.Fragment>
                      <Row>
                        {fields.map((name, index) => (
                          <Classification
                            key={name}
                            name={name}
                            index={index}
                            value={fields.value[index]}
                            productTypeID={productTypeID}
                            carrierTypeID={get(formValues, `${name}.carrierTypeID`)}
                            occupationClassTypeID={get(formValues, `${name}.occupationClassTypeID`)}
                            designTypeID={get(formValues, `${name}.designTypeID`)}
                            remove={fields.remove}
                          />
                        ))}
                      </Row>
                      <Row>
                        <Col>
                          <button
                            className="button__white"
                            onClick={e => {
                              e.preventDefault();
                              if (formValues.isFiveCarrier && fields.length >= 5) {
                                alert(S.PBC_MAX_CARRIER_ACHIEVED);
                                return;
                              }

                              fields.push(defaultClassification);
                            }}>
                            {S.PBC_ADD_COLUMN_BUTTON}
                          </button>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </FieldArray>
              </Container>
              <div className="buttons buttons__wrap">
                <button
                  className="button__orange buttons__display buttons__mr-20"
                  type="submit"
                  disabled={isSubmitting || !isClassificationLoaded}
                  onClick={e => {
                    formProps.form.change("formAction", "previous");
                  }}>
                  {isSavingPrevious || !isClassificationLoaded ? <Spinner color="white" /> : S.PC_PREV_BUTTON}
                </button>
                <div className="overview__next-button buttons__display">
                  <button
                    className="button__orange"
                    type="submit"
                    disabled={isSubmitting || !isClassificationLoaded}
                    onClick={e => {
                      formProps.form.change("formAction", "next");
                    }}>
                    {isSavingNext || !isClassificationLoaded ? <Spinner color="white" /> : S.PC_NEXT_BUTTON}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </FinalForm>
    );
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({
  analyzerTree: state.analyzerTree,
  isSubmitting: state.proposalOptions.isSubmitting,
  isSavingNext: state.proposalOptions.isSavingNext,
  isSavingPrevious: state.proposalOptions.isSavingPrevious,
  isClassificationLoaded: state.proposalOptions.isClassificationLoaded
});

const mapDispatchToProps = {
  fetchProductOptions,
  fetchProductOptionsButWithAGet
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalDetailsClassifications);
