import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, FormGroup, Input, Label, Spinner } from "reactstrap";
import { Form as FinalForm, Field } from "react-final-form";
import { get } from "lodash";

import arrayMutators from "final-form-arrays";
import TextField from "@material-ui/core/TextField";
import "./Mail.scss";
import { OnBlur, OnChange } from "react-final-form-listeners";
import { IRootState } from "../../store/reducers";
import { fetchDocuments } from "../../store/reducers/documentation";
import * as S from "../../constants/StringConstants";
import ChipArray from "../../components/ChipArray/ChipArray";
import ToggleField from "../../components/Form/ToggleField/ToggleField";
import CheckboxField from "../Form/CheckboxField/CheckboxField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

/** Clears a form field value */
const clear = ([name], state, { changeValue }) => {
  changeValue(state, name, () => undefined);
};

interface IMailProps extends StateProps, DispatchProps {
  inProcess: boolean;
  linkToDoc: { desktop?: string | null, mobile?: string | null };
  defaultValues: {
    defaultRecipients: Array<string>;
    ccRecipients: Array<string>;
    subject: string;
    bodyText: string;
    smsMessage?: string;
  };
  sendMail: (
    defaultRecipients: string[],
    otherRecipients: string[],
    subject: string,
    bodyText: string,
    smsMessage: string,
    sendWithoutRevision: boolean
  ) => void;
}

interface IMailState {}

class Mail extends React.Component<IMailProps, IMailState> {
  handleValidate = (values) => {
    return {};
  };

  handleSubmit = (values) => {
    const { defaultRecipients, ccRecipients, subject, bodyText, smsMessage, sendWithoutRevision } =
      values;
    this.props.sendMail(
      defaultRecipients,
      ccRecipients,
      subject,
      bodyText,
      smsMessage,
      sendWithoutRevision
    );
  };

  isEmailAddress(email: string) {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(email); // returns a boolean
  }

  render() {
    const { linkToDoc } = this.props;
    console.log(linkToDoc)
    const bodyTextReplace = (bodyText) => {
      if (linkToDoc && (linkToDoc.desktop || linkToDoc.mobile)) {
        const regex = /information you provided. /;
        console.log(linkToDoc.desktop && linkToDoc.mobile)
        const links = (linkToDoc.desktop && linkToDoc.mobile) ?
          `<a style="display: inline" href="${linkToDoc.desktop}">(desktop)</a> or <a style="display: inline" href="${linkToDoc.mobile}">(mobile)</a>`
          : !linkToDoc.mobile ? `<a style="display: inline" href="${linkToDoc.desktop}">(desktop)</a>`
          : `<a style="display: inline" href="${linkToDoc.mobile}">(mobile)</a>`

        const replaceText = `information you provided.<br />Please click on the link to view your custom DI Analyzer on
          ${links}.<br/>`;


        return bodyText.replace(regex, replaceText);
      }

      return bodyText;
    };
    const { defaultValues, inProcess } = this.props;
    const initialValues = {
      ...defaultValues,
      bodyText: bodyTextReplace(defaultValues.bodyText),
      sendSMSNotification: false,
    };

    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
          clear,
        }}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <Container className="bootsrap-container-override mail__wrap">
                <Row className="mail__default-recipients mail__fields">
                  <Col sm="2">
                    <div className="mail__title">
                      <Label for="otherToEmailString">{S.DOC_DEFAULT_RECIPIENTS}</Label>
                    </div>
                  </Col>
                  <Col>
                    <FormGroup>
                      <ChipArray
                        chipData={defaultValues.defaultRecipients}
                        name="defaultRecipients"
                      />
                      <Field
                        type="text"
                        name="otherToEmailString"
                        component="input"
                        id="otherToEmailString"
                        autoComplete="disabled"
                      />
                      <OnChange name="otherToEmailString" key="otherToEmailString">
                        {(value) => {
                          if (value) {
                            let emailApplied = false;
                            var emails = [];

                            emails = value.split(" ");
                            if (emails.length > 1) {
                              emails.forEach((x) => {
                                if (x && x.length > 0 && this.isEmailAddress(x)) {
                                  formProps.form.mutators.push("defaultRecipients", x);
                                  emailApplied = true;
                                }
                              });
                              if (emails && emails.length > 0 && emailApplied) {
                                formProps.form.mutators.clear("otherToEmailString");
                              }
                            }
                          }
                        }}
                      </OnChange>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mail__fields">
                  <Col sm="2">
                    <div className="mail__title">
                      <Label for="otherCCEmailString">{S.DOC_OTHER_EMAIL}</Label>
                    </div>
                  </Col>
                  <Col>
                    <FormGroup>
                      <ChipArray chipData={defaultValues.ccRecipients} name="ccRecipients" />
                      <Field
                        type="text"
                        name="otherCCEmailString"
                        component="input"
                        id="otherCCEmailString"
                        autoComplete="disabled"
                      />
                      <OnChange name="otherCCEmailString" key="otherCCEmailString">
                        {(value) => {
                          if (value) {
                            let emailApplied = false;
                            var emails = [];
                            emails = value.split(" ");
                            if (emails.length > 1) {
                              emails.forEach((x) => {
                                if (x && x.length > 0) {
                                  formProps.form.mutators.push("ccRecipients", x);
                                  emailApplied = true;
                                }
                              });
                              if (emails && emails.length > 0 && emailApplied) {
                                formProps.form.mutators.clear("otherCCEmailString");
                              }
                            }
                          }
                        }}
                      </OnChange>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mail__fields">
                      <Container className="bootsrap-container-override">
                        <Row>
                          <Col sm="1">
                            <div className="mail__title">
                              <Label for="subject">{S.DOC_SUBJECT}</Label>
                            </div>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Field type="text" component="input" name="subject" id="subject" />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <div className="mail__text-title">
                          <Label>{S.DOC_BODY_TITLE}</Label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mail__textarea">
                          <Field
                            type="hidden"
                            component="input"
                            name="bodyText"
                            id="email-body-field">
                            {({ input: { value, ...input } }) => (
                              <ReactQuill
                                theme="bubble"
                                value={value}
                                onChange={(newValue, delta, source) => {
                                  if (source === "user") {
                                    input.onChange(newValue);
                                  }
                                }}
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {defaultValues.smsMessage && (
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <div className="mail__text-title">
                            <Label>{S.DOC_SMS_TITLE}</Label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup className="mail__textarea">
                            <Field
                              type="textarea"
                              component="textarea"
                              name="smsMessage"
                              id="sms-body-field"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col sm="4" className="mail__checkbox">
                    <CheckboxField
                      label={S.DOC_SEND_WITHOUT_REVISION}
                      name={`sendWithoutRevision`}
                    />
                  </Col>
                  <Col className="mail__buttons" sm="8">
                    <button type="submit" className="button__white">
                      {inProcess ? <Spinner color="black" /> : S.DOC_SEND_BUTTON}
                    </button>
                  </Col>
                </Row>
              </Container>
            </form>
          );
        }}
      </FinalForm>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  inProcess: state.documentation.inProcess,
});

const mapDispatchToProps = { fetchDocuments };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Mail);
