import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { get } from "lodash";

import * as S from "../../constants/StringConstants";
import { IRootState } from "../../store/reducers";
import { IAffiliate } from "../../store/models/affiliate.model";
import { fetchAffiliatesList } from "../../store/reducers/affiliates";

import DisTable from "../../components/Table/Table";
import SearchFieldTool from "../../components/Table/SearchField";
import { renderAddress, renderPrimaryContact } from "../../components/Table/columnRenders";

interface IAffiliatesProps extends StateProps, DispatchProps, RouteComponentProps {}

class Affiliates extends React.Component<IAffiliatesProps> {
  render() {
    const {
      fetchAffiliatesList,
      affiliates: { map, projection, total, listOptions }
    } = this.props;

    return (
      <div className="table-view__content">
        <h1 className="heading1 grey--light">{S.AFF_PAGE_TITLE}</h1>
        <DisTable<IAffiliate>
          columns={columns}
          onRowClick={(data: IAffiliate) => {
            this.props.history.push(`/affiliates/${data.affiliateID}`)
          }}
          initialState={{ rows: 25, map, projection, total, ...listOptions }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchAffiliatesList({ page, rows, sort, query });
            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as IAffiliate[]
            };
          }}
        >
           <SearchFieldTool />
        </DisTable>
      </div>
    );
  }
}

const columns = [
  {
    key: "name", title: S.AFF_COL_NAME,
    style: { minWidth: '12rem' }
  },
  {
    key: "address",
    title: S.AFF_COL_ADDRESS,
    render: item => renderAddress(item.address),
    style: { minWidth: '12rem' }
  },
  { 
    key: "branch", title: S.AFF_COL_BRANCH,
    style: { minWidth: '12rem' }
  },
  { 
    key: "phoneNumber", title: S.AFF_COL_PHONE,
    style: { minWidth: '12rem' }
  },
  {
    key: "primaryContact",
    title: S.AFF_COL_PRIMARY_CONTACT,
    render: item => renderPrimaryContact(item.primaryContact || (item.contacts && item.contacts[0])),
    style: { minWidth: '12rem' }
  },
  {
    key: "affiliateID", title: S.AFF_COL_AFFILIATE_ID,
    style: { minWidth: '12rem' }
  },
  { 
    key: "externalID", title: S.AFF_COL_EXTERNAL_ID,
    style: { minWidth: '12rem' }
  }
];

const mapStateToProps = ({ affiliates }: IRootState) => ({ affiliates });
const mapDispatchToProps = { fetchAffiliatesList };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Affiliates);
