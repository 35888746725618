export interface IUser {
  userID: number;
  firstName: string;
  lastName: string;
  userRole: UserRole;
  branchTypeID: number;
  email: string;
  password: string;
  isDeleted: boolean;
  createdDate: string;
  modifiedDate: string;
}

export const defaultValue: Readonly<IUser> = {
  userID: null,
  firstName: "",
  lastName: "",
  userRole: null,
  branchTypeID: null,
  email: "",
  password: "",
  isDeleted: null,
  createdDate: "",
  modifiedDate: ""
};

export enum UserRole {
  Admin = 1,
  Specialist = 2,
  RegionalBranchManager = 3,
  BranchManager = 4
}
